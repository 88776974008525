/**======================================================================
=========================================================================
Template Name: Nextro - Most Complete Bootstrap Admin Template
Author: Phoenixcoded
Support: support@phoenixcoded.net
File: style.css
=========================================================================
=================================================================================== */


// theme font

// main framework
@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "settings/custom-variables";
@import "settings/theme-variables";
@import "node_modules/bootstrap/scss/bootstrap";

// main framework
@import "themes/generic";
@import "themes/general";

// Layouts
@import "themes/layouts/pc-sidebar";
@import "themes/layouts/pc-header";
@import "themes/layouts/pc-common";
// @import "themes/layouts/pc-horizontal";
// @import "themes/layouts/pc-chat";
// @import "themes/layouts/pc-modern";
// @import "themes/layouts/pc-advance";
// @import "themes/layouts/pc-tab";
// @import "themes/layouts/pc-creative";

// widget
@import "themes/components/widget";

// Componants
@import "themes/components/avtar";
@import "themes/components/badge";
@import "themes/components/button";
@import "themes/components/dropdown";
@import "themes/components/card";
@import "themes/components/progress";
@import "themes/components/table";
@import "themes/components/tabs";
@import "themes/components/form";

// pages
@import "themes/pages/authentication";
@import "themes/pages/pages";
@import "themes/pages/icon-lauouts";

// custom pluginss
// @import "themes/plugins/sweatalert";
// @import "themes/plugins/lightbox";
// @import "themes/plugins/notification";
// @import "themes/plugins/pnotify";
// @import "themes/plugins/rangeslider";
// @import "themes/plugins/bar-rating";
// @import "themes/plugins/daterange";
// @import "themes/plugins/tags-input";
// @import "themes/plugins/maxlength";
// @import "themes/plugins/wizard";
// @import "themes/plugins/select2";
@import "themes/plugins/data-tables";
// @import "themes/plugins/peitychart";

.fixed-button {
    position: fixed;
    bottom: -50px;
    right: 30px;
    box-shadow: 0 15px 50px rgba(0, 0, 0, 0.35);
    opacity: 0;
    z-index: 9;
    transition: all 0.5s ease;
}

.fixed-button.active {
    bottom: 50px;
    opacity: 1;
}
