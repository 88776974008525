/**======================================================================
=========================================================================
Template Name: Nextro - Most Complete Bootstrap Admin Template
Author: Phoenixcoded
Support: support@phoenixcoded.net
File: style.css
=========================================================================
=================================================================================== */
/*!
 * Bootstrap v4.5.2 (https://getbootstrap.com/)
 * Copyright 2011-2020 The Bootstrap Authors
 * Copyright 2011-2020 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root {
  --blue: #7267EF;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #EA4D4D;
  --orange: #fd7e14;
  --yellow: #ffa21d;
  --green: #17C666;
  --teal: #20c997;
  --cyan: #3ec9d6;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #7267EF;
  --secondary: #6c757d;
  --success: #17C666;
  --info: #3ec9d6;
  --warning: #ffa21d;
  --danger: #EA4D4D;
  --light: #f8f9fa;
  --dark: #1c232f;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: "Inter", sans-serif;
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: "Inter", sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #293240;
  text-align: left;
  background-color: #f0f2f8; }

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #7267EF;
  text-decoration: none;
  background-color: transparent; }
  a:hover {
    color: #7267EF;
    text-decoration: underline; }

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([class]):hover {
    color: inherit;
    text-decoration: none; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.9rem 0.75rem;
  padding-bottom: 0.9rem 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

[role="button"] {
  cursor: pointer; }

select {
  word-wrap: normal; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
  color: #060606; }

h1, .h1 {
  font-size: 2.25rem; }

h2, .h2 {
  font-size: 1.75rem; }

h3, .h3 {
  font-size: 1.5rem; }

h4, .h4 {
  font-size: 1.25rem; }

h5, .h5 {
  font-size: 1rem; }

h6, .h6 {
  font-size: 0.9375rem; }

.lead {
  font-size: 1.09375rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2; }

.display-2 {
  font-size: 5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-3 {
  font-size: 4rem;
  font-weight: 300;
  line-height: 1.2; }

.display-4 {
  font-size: 3rem;
  font-weight: 300;
  line-height: 1.2; }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

small,
.small {
  font-size: 80%;
  font-weight: 400; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.09375rem; }

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d; }
  .blockquote-footer::before {
    content: "\2014\00A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #f0f2f8;
  border: 1px solid #dee2e6;
  border-radius: 4px;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #6c757d; }

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-wrap: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 2px; }
  kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 700; }

pre {
  display: block;
  font-size: 87.5%;
  color: #212529; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll; }

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px; } }

@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 720px; } }

@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 960px; } }

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1140px; } }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%; }

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%; }

.row-cols-3 > * {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%; }

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%; }

.row-cols-6 > * {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-sm-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-sm-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-md-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-md-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-lg-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-lg-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-xl-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-xl-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #293240; }
  .table th,
  .table td {
    padding: 0.9rem 0.75rem;
    vertical-align: top;
    border-top: 1px solid #f1f1f1; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #f1f1f1; }
  .table tbody + tbody {
    border-top: 2px solid #f1f1f1; }

.table-sm th,
.table-sm td {
  padding: 0.3rem; }

.table-bordered {
  border: 1px solid #f1f1f1; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #f1f1f1; }
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px; }

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05); }

.table-hover tbody tr:hover {
  color: #293240;
  background-color: rgba(0, 0, 0, 0.075); }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #d8d4fb; }

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #b6b0f7; }

.table-hover .table-primary:hover {
  background-color: #c3bdf9; }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #c3bdf9; }

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #d6d8db; }

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #b3b7bb; }

.table-hover .table-secondary:hover {
  background-color: #c8cbcf; }
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #c8cbcf; }

.table-success,
.table-success > th,
.table-success > td {
  background-color: #beefd4; }

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #86e1af; }

.table-hover .table-success:hover {
  background-color: #aaeac6; }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #aaeac6; }

.table-info,
.table-info > th,
.table-info > td {
  background-color: #c9f0f4; }

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #9be3ea; }

.table-hover .table-info:hover {
  background-color: #b4eaf0; }
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #b4eaf0; }

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffe5c0; }

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #ffcf89; }

.table-hover .table-warning:hover {
  background-color: #ffdaa7; }
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #ffdaa7; }

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f9cdcd; }

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #f4a2a2; }

.table-hover .table-danger:hover {
  background-color: #f6b6b6; }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #f6b6b6; }

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe; }

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fbfcfc; }

.table-hover .table-light:hover {
  background-color: #ececf6; }
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #ececf6; }

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #bfc1c5; }

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #898d93; }

.table-hover .table-dark:hover {
  background-color: #b2b4b9; }
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #b2b4b9; }

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075); }

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075); }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.075); }

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55; }

.table .thead-light th {
  color: #293240;
  background-color: #e9ecef;
  border-color: #f1f1f1; }

.table-dark {
  color: #fff;
  background-color: #343a40; }
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: #454d55; }
  .table-dark.table-bordered {
    border: 0; }
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
  .table-dark.table-hover tbody tr:hover {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-sm > .table-bordered {
      border: 0; } }

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-md > .table-bordered {
      border: 0; } }

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-lg > .table-bordered {
      border: 0; } }

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-xl > .table-bordered {
      border: 0; } }

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; }
  .table-responsive > .table-bordered {
    border: 0; }

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 1.15rem + 3px);
  padding: 0.575rem 1rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1.5px solid #ced4da;
  border-radius: 4px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .form-control {
      transition: none; } }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #495057; }
  .form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #7267EF;
    outline: 0;
    box-shadow: none; }
  .form-control::placeholder {
    color: #adb5bd;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1; }

input[type="date"].form-control,
input[type="time"].form-control,
input[type="datetime-local"].form-control,
input[type="month"].form-control {
  appearance: none; }

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(0.575rem + 1.5px);
  padding-bottom: calc(0.575rem + 1.5px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 1.5px);
  padding-bottom: calc(0.5rem + 1.5px);
  font-size: 1.09375rem;
  line-height: 1.5; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1.5px);
  padding-bottom: calc(0.25rem + 1.5px);
  font-size: 0.76563rem;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.575rem 0;
  margin-bottom: 0;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #293240;
  background-color: transparent;
  border: solid transparent;
  border-width: 1.5px 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  height: calc(1.5em + 0.5rem + 3px);
  padding: 0.25rem 0.5rem;
  font-size: 0.76563rem;
  line-height: 1.5;
  border-radius: 2px; }

.form-control-lg {
  height: calc(1.5em + 1rem + 3px);
  padding: 0.5rem 1rem;
  font-size: 1.09375rem;
  line-height: 1.5;
  border-radius: 6px; }

select.form-control[size], select.form-control[multiple] {
  height: auto; }

textarea.form-control {
  height: auto; }

.form-group {
  margin-bottom: 1.3rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  .form-check-input[disabled] ~ .form-check-label,
  .form-check-input:disabled ~ .form-check-label {
    color: #6c757d; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #17C666; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.76563rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(23, 198, 102, 0.9);
  border-radius: 4px; }

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #17C666;
  padding-right: calc(1.5em + 1.15rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2317C666' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.2875rem) center;
  background-size: calc(0.75em + 0.575rem) calc(0.75em + 0.575rem); }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus {
    border-color: #17C666;
    box-shadow: 0 0 0 0.2rem rgba(23, 198, 102, 0.25); }

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 1.15rem);
  background-position: top calc(0.375em + 0.2875rem) right calc(0.375em + 0.2875rem); }

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #17C666;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 1rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2317C666' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") #fff no-repeat center right 2rem/calc(0.75em + 0.575rem) calc(0.75em + 0.575rem); }
  .was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
    border-color: #17C666;
    box-shadow: 0 0 0 0.2rem rgba(23, 198, 102, 0.25); }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #17C666; }

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #17C666; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    border-color: #17C666; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #2ae67f;
  background-color: #2ae67f; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(23, 198, 102, 0.25); }

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #17C666; }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #17C666; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #17C666;
  box-shadow: 0 0 0 0.2rem rgba(23, 198, 102, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #EA4D4D; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.76563rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(234, 77, 77, 0.9);
  border-radius: 4px; }

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #EA4D4D;
  padding-right: calc(1.5em + 1.15rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23EA4D4D' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23EA4D4D' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.2875rem) center;
  background-size: calc(0.75em + 0.575rem) calc(0.75em + 0.575rem); }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
    border-color: #EA4D4D;
    box-shadow: 0 0 0 0.2rem rgba(234, 77, 77, 0.25); }

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 1.15rem);
  background-position: top calc(0.375em + 0.2875rem) right calc(0.375em + 0.2875rem); }

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #EA4D4D;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 1rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23EA4D4D' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23EA4D4D' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E") #fff no-repeat center right 2rem/calc(0.75em + 0.575rem) calc(0.75em + 0.575rem); }
  .was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
    border-color: #EA4D4D;
    box-shadow: 0 0 0 0.2rem rgba(234, 77, 77, 0.25); }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #EA4D4D; }

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #EA4D4D; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    border-color: #EA4D4D; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #ef7b7b;
  background-color: #ef7b7b; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(234, 77, 77, 0.25); }

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #EA4D4D; }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #EA4D4D; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #EA4D4D;
  box-shadow: 0 0 0 0.2rem rgba(234, 77, 77, 0.25); }

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 576px) {
    .form-inline label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group,
    .form-inline .custom-select {
      width: auto; }
    .form-inline .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      flex-shrink: 0;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      align-items: center;
      justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

.btn {
  display: inline-block;
  font-weight: 500;
  color: #293240;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1.5px solid transparent;
  padding: 0.575rem 1.3rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 4px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .btn {
      transition: none; } }
  .btn:hover {
    color: #293240;
    text-decoration: none; }
  .btn:focus, .btn.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(114, 103, 239, 0.25); }
  .btn.disabled, .btn:disabled {
    opacity: 0.65; }
  .btn:not(:disabled):not(.disabled) {
    cursor: pointer; }

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none; }

.btn-primary {
  color: #fff;
  background-color: #7267EF;
  border-color: #7267EF; }
  .btn-primary:hover {
    color: #fff;
    background-color: #5244eb;
    border-color: #4739ea; }
  .btn-primary:focus, .btn-primary.focus {
    color: #fff;
    background-color: #5244eb;
    border-color: #4739ea;
    box-shadow: 0 0 0 0.2rem rgba(135, 126, 241, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    background-color: #7267EF;
    border-color: #7267EF; }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #4739ea;
    border-color: #3c2de9; }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(135, 126, 241, 0.5); }

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d; }
  .btn-secondary:hover {
    color: #fff;
    background-color: #5a6268;
    border-color: #545b62; }
  .btn-secondary:focus, .btn-secondary.focus {
    color: #fff;
    background-color: #5a6268;
    border-color: #545b62;
    box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #545b62;
    border-color: #4e555b; }
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5); }

.btn-success {
  color: #fff;
  background-color: #17C666;
  border-color: #17C666; }
  .btn-success:hover {
    color: #fff;
    background-color: #13a454;
    border-color: #12984e; }
  .btn-success:focus, .btn-success.focus {
    color: #fff;
    background-color: #13a454;
    border-color: #12984e;
    box-shadow: 0 0 0 0.2rem rgba(58, 207, 125, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    color: #fff;
    background-color: #17C666;
    border-color: #17C666; }
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #12984e;
    border-color: #108d49; }
    .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(58, 207, 125, 0.5); }

.btn-info {
  color: #fff;
  background-color: #3ec9d6;
  border-color: #3ec9d6; }
  .btn-info:hover {
    color: #fff;
    background-color: #2ab7c4;
    border-color: #27adba; }
  .btn-info:focus, .btn-info.focus {
    color: #fff;
    background-color: #2ab7c4;
    border-color: #27adba;
    box-shadow: 0 0 0 0.2rem rgba(91, 209, 220, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    color: #fff;
    background-color: #3ec9d6;
    border-color: #3ec9d6; }
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #27adba;
    border-color: #25a3af; }
    .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(91, 209, 220, 0.5); }

.btn-warning {
  color: #fff;
  background-color: #ffa21d;
  border-color: #ffa21d; }
  .btn-warning:hover {
    color: #fff;
    background-color: #f69100;
    border-color: #e98900; }
  .btn-warning:focus, .btn-warning.focus {
    color: #fff;
    background-color: #f69100;
    border-color: #e98900;
    box-shadow: 0 0 0 0.2rem rgba(255, 176, 63, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    color: #fff;
    background-color: #ffa21d;
    border-color: #ffa21d; }
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #fff;
    background-color: #e98900;
    border-color: #dc8200; }
    .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 176, 63, 0.5); }

.btn-danger {
  color: #fff;
  background-color: #EA4D4D;
  border-color: #EA4D4D; }
  .btn-danger:hover {
    color: #fff;
    background-color: #e62b2b;
    border-color: #e51f1f; }
  .btn-danger:focus, .btn-danger.focus {
    color: #fff;
    background-color: #e62b2b;
    border-color: #e51f1f;
    box-shadow: 0 0 0 0.2rem rgba(237, 104, 104, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    color: #fff;
    background-color: #EA4D4D;
    border-color: #EA4D4D; }
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #e51f1f;
    border-color: #dd1a1a; }
    .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(237, 104, 104, 0.5); }

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-light:hover {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5; }
  .btn-light:focus, .btn-light.focus {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5;
    box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #212529;
    background-color: #dae0e5;
    border-color: #d3d9df; }
    .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }

.btn-dark {
  color: #fff;
  background-color: #1c232f;
  border-color: #1c232f; }
  .btn-dark:hover {
    color: #fff;
    background-color: #0e1117;
    border-color: #090b0f; }
  .btn-dark:focus, .btn-dark.focus {
    color: #fff;
    background-color: #0e1117;
    border-color: #090b0f;
    box-shadow: 0 0 0 0.2rem rgba(62, 68, 78, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    color: #fff;
    background-color: #1c232f;
    border-color: #1c232f; }
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #090b0f;
    border-color: #040507; }
    .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(62, 68, 78, 0.5); }

.btn-outline-primary {
  color: #7267EF;
  border-color: #7267EF; }
  .btn-outline-primary:hover {
    color: #fff;
    background-color: #7267EF;
    border-color: #7267EF; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(114, 103, 239, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #7267EF;
    background-color: transparent; }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #7267EF;
    border-color: #7267EF; }
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(114, 103, 239, 0.5); }

.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d; }
  .btn-outline-secondary:hover {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #6c757d;
    background-color: transparent; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }

.btn-outline-success {
  color: #17C666;
  border-color: #17C666; }
  .btn-outline-success:hover {
    color: #fff;
    background-color: #17C666;
    border-color: #17C666; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(23, 198, 102, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #17C666;
    background-color: transparent; }
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #17C666;
    border-color: #17C666; }
    .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(23, 198, 102, 0.5); }

.btn-outline-info {
  color: #3ec9d6;
  border-color: #3ec9d6; }
  .btn-outline-info:hover {
    color: #fff;
    background-color: #3ec9d6;
    border-color: #3ec9d6; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(62, 201, 214, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #3ec9d6;
    background-color: transparent; }
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #3ec9d6;
    border-color: #3ec9d6; }
    .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(62, 201, 214, 0.5); }

.btn-outline-warning {
  color: #ffa21d;
  border-color: #ffa21d; }
  .btn-outline-warning:hover {
    color: #fff;
    background-color: #ffa21d;
    border-color: #ffa21d; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 162, 29, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #ffa21d;
    background-color: transparent; }
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #fff;
    background-color: #ffa21d;
    border-color: #ffa21d; }
    .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 162, 29, 0.5); }

.btn-outline-danger {
  color: #EA4D4D;
  border-color: #EA4D4D; }
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #EA4D4D;
    border-color: #EA4D4D; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(234, 77, 77, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #EA4D4D;
    background-color: transparent; }
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #EA4D4D;
    border-color: #EA4D4D; }
    .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(234, 77, 77, 0.5); }

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-outline-light:hover {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #f8f9fa;
    background-color: transparent; }
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
    .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.btn-outline-dark {
  color: #1c232f;
  border-color: #1c232f; }
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #1c232f;
    border-color: #1c232f; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(28, 35, 47, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #1c232f;
    background-color: transparent; }
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #1c232f;
    border-color: #1c232f; }
    .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(28, 35, 47, 0.5); }

.btn-link {
  font-weight: 400;
  color: #7267EF;
  text-decoration: none; }
  .btn-link:hover {
    color: #7267EF;
    text-decoration: underline; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: underline; }
  .btn-link:disabled, .btn-link.disabled {
    color: #6c757d;
    pointer-events: none; }

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.09375rem;
  line-height: 1.5;
  border-radius: 6px; }

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.76563rem;
  line-height: 1.5;
  border-radius: 2px; }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.fade {
  transition: opacity 0.15s linear; }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative; }

.dropdown-toggle {
  white-space: nowrap; }
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent; }
  .dropdown-toggle:empty::after {
    margin-left: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 12rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 0.875rem;
  color: #293240;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 0 solid rgba(0, 0, 0, 0.15);
  border-radius: 4px; }

.dropdown-menu-left {
  right: auto;
  left: 0; }

.dropdown-menu-right {
  right: 0;
  left: auto; }

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0; }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto; } }

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0; }
  .dropdown-menu-md-right {
    right: 0;
    left: auto; } }

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0; }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto; } }

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0; }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto; } }

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem; }

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem; }

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropleft .dropdown-toggle::after {
  display: none; }

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto; }

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.65rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #293240;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #7267EF;
    text-decoration: none;
    background-color: #f4f3fe; }
  .dropdown-item.active, .dropdown-item:active {
    color: #7267EF;
    text-decoration: none;
    background-color: #f4f3fe; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.76563rem;
  color: #6c757d;
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: 0.65rem 1.5rem;
  color: #293240; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    position: relative;
    flex: 1 1 auto; }
    .btn-group > .btn:hover,
    .btn-group-vertical > .btn:hover {
      z-index: 1; }
    .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
    .btn-group-vertical > .btn:focus,
    .btn-group-vertical > .btn:active,
    .btn-group-vertical > .btn.active {
      z-index: 1; }

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1.5px; }

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.dropdown-toggle-split {
  padding-right: 0.975rem;
  padding-left: 0.975rem; }
  .dropdown-toggle-split::after,
  .dropup .dropdown-toggle-split::after,
  .dropright .dropdown-toggle-split::after {
    margin-left: 0; }
  .dropleft .dropdown-toggle-split::before {
    margin-right: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }
  .btn-group-vertical > .btn,
  .btn-group-vertical > .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) {
    margin-top: -1.5px; }
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group-vertical > .btn-group:not(:last-child) > .btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0; }
  .btn-group-toggle > .btn input[type="radio"],
  .btn-group-toggle > .btn input[type="checkbox"],
  .btn-group-toggle > .btn-group > .btn input[type="radio"],
  .btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none; }

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%; }
  .input-group > .form-control,
  .input-group > .form-control-plaintext,
  .input-group > .custom-select,
  .input-group > .custom-file {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
    margin-bottom: 0; }
    .input-group > .form-control + .form-control,
    .input-group > .form-control + .custom-select,
    .input-group > .form-control + .custom-file,
    .input-group > .form-control-plaintext + .form-control,
    .input-group > .form-control-plaintext + .custom-select,
    .input-group > .form-control-plaintext + .custom-file,
    .input-group > .custom-select + .form-control,
    .input-group > .custom-select + .custom-select,
    .input-group > .custom-select + .custom-file,
    .input-group > .custom-file + .form-control,
    .input-group > .custom-file + .custom-select,
    .input-group > .custom-file + .custom-file {
      margin-left: -1.5px; }
  .input-group > .form-control:focus,
  .input-group > .custom-select:focus,
  .input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
    z-index: 3; }
  .input-group > .custom-file .custom-file-input:focus {
    z-index: 4; }
  .input-group > .form-control:not(:last-child),
  .input-group > .custom-select:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .input-group > .form-control:not(:first-child),
  .input-group > .custom-select:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .input-group > .custom-file {
    display: flex;
    align-items: center; }
    .input-group > .custom-file:not(:last-child) .custom-file-label,
    .input-group > .custom-file:not(:last-child) .custom-file-label::after {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0; }
    .input-group > .custom-file:not(:first-child) .custom-file-label {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }

.input-group-prepend,
.input-group-append {
  display: flex; }
  .input-group-prepend .btn,
  .input-group-append .btn {
    position: relative;
    z-index: 2; }
    .input-group-prepend .btn:focus,
    .input-group-append .btn:focus {
      z-index: 3; }
  .input-group-prepend .btn + .btn,
  .input-group-prepend .btn + .input-group-text,
  .input-group-prepend .input-group-text + .input-group-text,
  .input-group-prepend .input-group-text + .btn,
  .input-group-append .btn + .btn,
  .input-group-append .btn + .input-group-text,
  .input-group-append .input-group-text + .input-group-text,
  .input-group-append .input-group-text + .btn {
    margin-left: -1.5px; }

.input-group-prepend {
  margin-right: -1.5px; }

.input-group-append {
  margin-left: -1.5px; }

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.575rem 1rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #f0f2f8;
  border: 1.5px solid #ced4da;
  border-radius: 4px; }
  .input-group-text input[type="radio"],
  .input-group-text input[type="checkbox"] {
    margin-top: 0; }

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 3px); }

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.09375rem;
  line-height: 1.5;
  border-radius: 6px; }

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 3px); }

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.76563rem;
  line-height: 1.5;
  border-radius: 2px; }

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 2rem; }

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.custom-control {
  position: relative;
  z-index: 1;
  display: block;
  min-height: 1.3125rem;
  padding-left: 1.5rem; }

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem; }

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.15625rem;
  opacity: 0; }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #7267EF;
    background-color: #7267EF; }
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: none; }
  .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #7267EF; }
  .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
    color: #fff;
    background-color: white;
    border-color: white; }
  .custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
    color: #6c757d; }
    .custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
      background-color: #e9ecef; }

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top; }
  .custom-control-label::before {
    position: absolute;
    top: 0.15625rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    background-color: #fff;
    border: #adb5bd solid 1.5px; }
  .custom-control-label::after {
    position: absolute;
    top: 0.15625rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background: no-repeat 50% / 50% 50%; }

.custom-checkbox .custom-control-label::before {
  border-radius: 4px; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #7267EF;
  background-color: #7267EF; }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(114, 103, 239, 0.5); }

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(114, 103, 239, 0.5); }

.custom-radio .custom-control-label::before {
  border-radius: 50%; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e"); }

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(114, 103, 239, 0.5); }

.custom-switch {
  padding-left: 2.25rem; }
  .custom-switch .custom-control-label::before {
    left: -2.25rem;
    width: 1.75rem;
    pointer-events: all;
    border-radius: 0.5rem; }
  .custom-switch .custom-control-label::after {
    top: calc(0.15625rem + 3px);
    left: calc(-2.25rem + 3px);
    width: calc(1rem - 6px);
    height: calc(1rem - 6px);
    background-color: #adb5bd;
    border-radius: 0.5rem;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .custom-switch .custom-control-label::after {
        transition: none; } }
  .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    background-color: #fff;
    transform: translateX(0.75rem); }
  .custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(114, 103, 239, 0.5); }

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 1.15rem + 3px);
  padding: 0.575rem 2rem 0.575rem 1rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 1rem center/8px 10px;
  border: 1.5px solid #ced4da;
  border-radius: 4px;
  appearance: none; }
  .custom-select:focus {
    border-color: #7267EF;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(114, 103, 239, 0.25); }
    .custom-select:focus::-ms-value {
      color: #495057;
      background-color: #fff; }
  .custom-select[multiple], .custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 1rem;
    background-image: none; }
  .custom-select:disabled {
    color: #6c757d;
    background-color: #e9ecef; }
  .custom-select::-ms-expand {
    display: none; }
  .custom-select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #495057; }

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 3px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.76563rem; }

.custom-select-lg {
  height: calc(1.5em + 1rem + 3px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.09375rem; }

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 1.15rem + 3px);
  margin-bottom: 0; }

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 1.15rem + 3px);
  margin: 0;
  opacity: 0; }
  .custom-file-input:focus ~ .custom-file-label {
    border-color: #7267EF;
    box-shadow: none; }
  .custom-file-input[disabled] ~ .custom-file-label,
  .custom-file-input:disabled ~ .custom-file-label {
    background-color: #e9ecef; }
  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse"; }
  .custom-file-input ~ .custom-file-label[data-browse]::after {
    content: attr(data-browse); }

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 1.15rem + 3px);
  padding: 0.575rem 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1.5px solid #ced4da;
  border-radius: 4px; }
  .custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(1.5em + 1.15rem);
    padding: 0.575rem 1rem;
    line-height: 1.5;
    color: #495057;
    content: "Browse";
    background-color: #f0f2f8;
    border-left: inherit;
    border-radius: 0 4px 4px 0; }

.custom-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  appearance: none; }
  .custom-range:focus {
    outline: none; }
    .custom-range:focus::-webkit-slider-thumb {
      box-shadow: 0 0 0 1px #f0f2f8, none; }
    .custom-range:focus::-moz-range-thumb {
      box-shadow: 0 0 0 1px #f0f2f8, none; }
    .custom-range:focus::-ms-thumb {
      box-shadow: 0 0 0 1px #f0f2f8, none; }
  .custom-range::-moz-focus-outer {
    border: 0; }
  .custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #7267EF;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-webkit-slider-thumb {
        transition: none; } }
    .custom-range::-webkit-slider-thumb:active {
      background-color: white; }
  .custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #7267EF;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-moz-range-thumb {
        transition: none; } }
    .custom-range::-moz-range-thumb:active {
      background-color: white; }
  .custom-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    margin-right: 0.2rem;
    margin-left: 0.2rem;
    background-color: #7267EF;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-ms-thumb {
        transition: none; } }
    .custom-range::-ms-thumb:active {
      background-color: white; }
  .custom-range::-ms-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: 0.5rem; }
  .custom-range::-ms-fill-lower {
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range:disabled::-webkit-slider-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-webkit-slider-runnable-track {
    cursor: default; }
  .custom-range:disabled::-moz-range-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-moz-range-track {
    cursor: default; }
  .custom-range:disabled::-ms-thumb {
    background-color: #adb5bd; }

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .custom-control-label::before,
    .custom-file-label,
    .custom-select {
      transition: none; } }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem; }
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  .nav-link.disabled {
    color: #6c757d;
    pointer-events: none;
    cursor: default; }

.nav-tabs {
  border-bottom: 1px solid #dee2e6; }
  .nav-tabs .nav-item {
    margin-bottom: -1px; }
  .nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      border-color: #e9ecef #e9ecef #dee2e6; }
    .nav-tabs .nav-link.disabled {
      color: #6c757d;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  border-radius: 4px; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #7267EF; }

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem; }
  .navbar .container,
  .navbar .container-fluid, .navbar .container-sm, .navbar .container-md, .navbar .container-lg, .navbar .container-xl {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; }

.navbar-brand {
  display: inline-block;
  padding-top: 0.33594rem;
  padding-bottom: 0.33594rem;
  margin-right: 1rem;
  font-size: 1.09375rem;
  line-height: inherit;
  white-space: nowrap; }
  .navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none; }

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static;
    float: none; }

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.09375rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 4px; }
  .navbar-toggler:hover, .navbar-toggler:focus {
    text-decoration: none; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%; }

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; } }

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; } }

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; } }

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; } }

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
    padding-right: 0;
    padding-left: 0; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
    flex-wrap: nowrap; }
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9); }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7); }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-text a {
    color: rgba(0, 0, 0, 0.9); }
    .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
      color: rgba(0, 0, 0, 0.9); }

.navbar-dark .navbar-brand {
  color: #fff; }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #fff; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75); }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-text a {
    color: #fff; }
    .navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
      color: #fff; }

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 0px solid rgba(0, 0, 0, 0.125);
  border-radius: 4px; }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group {
    border-top: inherit;
    border-bottom: inherit; }
    .card > .list-group:first-child {
      border-top-width: 0;
      border-top-left-radius: calc(4px - 0px);
      border-top-right-radius: calc(4px - 0px); }
    .card > .list-group:last-child {
      border-bottom-width: 0;
      border-bottom-right-radius: calc(4px - 0px);
      border-bottom-left-radius: calc(4px - 0px); }
  .card > .card-header + .list-group,
  .card > .list-group + .card-footer {
    border-top: 0; }

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 25px; }

.card-title {
  margin-bottom: 25px; }

.card-subtitle {
  margin-top: -12.5px;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 25px; }

.card-header {
  padding: 25px 25px;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0);
  border-bottom: 0px solid rgba(0, 0, 0, 0.125); }
  .card-header:first-child {
    border-radius: calc(4px - 0px) calc(4px - 0px) 0 0; }

.card-footer {
  padding: 25px 25px;
  background-color: rgba(0, 0, 0, 0);
  border-top: 0px solid rgba(0, 0, 0, 0.125); }
  .card-footer:last-child {
    border-radius: 0 0 calc(4px - 0px) calc(4px - 0px); }

.card-header-tabs {
  margin-right: -12.5px;
  margin-bottom: -25px;
  margin-left: -12.5px;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -12.5px;
  margin-left: -12.5px; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
  border-radius: calc(4px - 0px); }

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%; }

.card-img,
.card-img-top {
  border-top-left-radius: calc(4px - 0px);
  border-top-right-radius: calc(4px - 0px); }

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(4px - 0px);
  border-bottom-left-radius: calc(4px - 0px); }

.card-deck .card {
  margin-bottom: 15px; }

@media (min-width: 576px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px; }
    .card-deck .card {
      flex: 1 0 0%;
      margin-right: 15px;
      margin-bottom: 0;
      margin-left: 15px; } }

.card-group > .card {
  margin-bottom: 15px; }

@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap; }
    .card-group > .card {
      flex: 1 0 0%;
      margin-bottom: 0; }
      .card-group > .card + .card {
        margin-left: 0;
        border-left: 0; }
      .card-group > .card:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-top,
        .card-group > .card:not(:last-child) .card-header {
          border-top-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-bottom,
        .card-group > .card:not(:last-child) .card-footer {
          border-bottom-right-radius: 0; }
      .card-group > .card:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-top,
        .card-group > .card:not(:first-child) .card-header {
          border-top-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-bottom,
        .card-group > .card:not(:first-child) .card-footer {
          border-bottom-left-radius: 0; } }

.card-columns .card {
  margin-bottom: 25px; }

@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1; }
    .card-columns .card {
      display: inline-block;
      width: 100%; } }

.accordion {
  overflow-anchor: none; }
  .accordion > .card {
    overflow: hidden; }
    .accordion > .card:not(:last-of-type) {
      border-bottom: 0;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0; }
    .accordion > .card:not(:first-of-type) {
      border-top-left-radius: 0;
      border-top-right-radius: 0; }
    .accordion > .card > .card-header {
      border-radius: 0;
      margin-bottom: 0px; }

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #f0f2f8;
  border-radius: 4px; }

.breadcrumb-item {
  display: flex; }
  .breadcrumb-item + .breadcrumb-item {
    padding-left: 0.5rem; }
    .breadcrumb-item + .breadcrumb-item::before {
      display: inline-block;
      padding-right: 0.5rem;
      color: #6c757d;
      content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='14' height='14' stroke='%236c757d' stroke-width='2' fill='none' stroke-linecap='round' stroke-linejoin='round' class='css-i6dzq1'%3E%3Cpolyline points='9 18 15 12 9 6'%3E%3C/polyline%3E%3C/svg%3E"); }
  .breadcrumb-item + .breadcrumb-item:hover::before {
    text-decoration: underline; }
  .breadcrumb-item + .breadcrumb-item:hover::before {
    text-decoration: none; }
  .breadcrumb-item.active {
    color: #6c757d; }

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 4px; }

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #7267EF;
  background-color: #fff;
  border: 1px solid #dee2e6; }
  .page-link:hover {
    z-index: 2;
    color: #7267EF;
    text-decoration: none;
    background-color: #f0f2f8;
    border-color: #dee2e6; }
  .page-link:focus {
    z-index: 3;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(114, 103, 239, 0.25); }

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px; }

.page-item:last-child .page-link {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px; }

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #7267EF;
  border-color: #7267EF; }

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6; }

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.09375rem;
  line-height: 1.5; }

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px; }

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px; }

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.76563rem;
  line-height: 1.5; }

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px; }

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px; }

.badge {
  display: inline-block;
  padding: 0.35em 0.5em;
  font-size: 75%;
  font-weight: 500;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 2px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .badge {
      transition: none; } }
  a.badge:hover, a.badge:focus {
    text-decoration: none; }
  .badge:empty {
    display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem; }

.badge-primary {
  color: #fff;
  background-color: #7267EF; }
  a.badge-primary:hover, a.badge-primary:focus {
    color: #fff;
    background-color: #4739ea; }
  a.badge-primary:focus, a.badge-primary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(114, 103, 239, 0.5); }

.badge-secondary {
  color: #fff;
  background-color: #6c757d; }
  a.badge-secondary:hover, a.badge-secondary:focus {
    color: #fff;
    background-color: #545b62; }
  a.badge-secondary:focus, a.badge-secondary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }

.badge-success {
  color: #fff;
  background-color: #17C666; }
  a.badge-success:hover, a.badge-success:focus {
    color: #fff;
    background-color: #12984e; }
  a.badge-success:focus, a.badge-success.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(23, 198, 102, 0.5); }

.badge-info {
  color: #fff;
  background-color: #3ec9d6; }
  a.badge-info:hover, a.badge-info:focus {
    color: #fff;
    background-color: #27adba; }
  a.badge-info:focus, a.badge-info.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(62, 201, 214, 0.5); }

.badge-warning {
  color: #fff;
  background-color: #ffa21d; }
  a.badge-warning:hover, a.badge-warning:focus {
    color: #fff;
    background-color: #e98900; }
  a.badge-warning:focus, a.badge-warning.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(255, 162, 29, 0.5); }

.badge-danger {
  color: #fff;
  background-color: #EA4D4D; }
  a.badge-danger:hover, a.badge-danger:focus {
    color: #fff;
    background-color: #e51f1f; }
  a.badge-danger:focus, a.badge-danger.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(234, 77, 77, 0.5); }

.badge-light {
  color: #212529;
  background-color: #f8f9fa; }
  a.badge-light:hover, a.badge-light:focus {
    color: #212529;
    background-color: #dae0e5; }
  a.badge-light:focus, a.badge-light.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.badge-dark {
  color: #fff;
  background-color: #1c232f; }
  a.badge-dark:hover, a.badge-dark:focus {
    color: #fff;
    background-color: #090b0f; }
  a.badge-dark:focus, a.badge-dark.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(28, 35, 47, 0.5); }

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 6px; }
  @media (min-width: 576px) {
    .jumbotron {
      padding: 4rem 2rem; } }

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0; }

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 4px; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700; }

.alert-dismissible {
  padding-right: 3.8125rem; }
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.75rem 1.25rem;
    color: inherit; }

.alert-primary {
  color: #3b367c;
  background-color: #e3e1fc;
  border-color: #d8d4fb; }
  .alert-primary hr {
    border-top-color: #c3bdf9; }
  .alert-primary .alert-link {
    color: #2a2758; }

.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db; }
  .alert-secondary hr {
    border-top-color: #c8cbcf; }
  .alert-secondary .alert-link {
    color: #202326; }

.alert-success {
  color: #0c6735;
  background-color: #d1f4e0;
  border-color: #beefd4; }
  .alert-success hr {
    border-top-color: #aaeac6; }
  .alert-success .alert-link {
    color: #07391d; }

.alert-info {
  color: #20696f;
  background-color: #d8f4f7;
  border-color: #c9f0f4; }
  .alert-info hr {
    border-top-color: #b4eaf0; }
  .alert-info .alert-link {
    color: #154447; }

.alert-warning {
  color: #85540f;
  background-color: #ffecd2;
  border-color: #ffe5c0; }
  .alert-warning hr {
    border-top-color: #ffdaa7; }
  .alert-warning .alert-link {
    color: #57370a; }

.alert-danger {
  color: #7a2828;
  background-color: #fbdbdb;
  border-color: #f9cdcd; }
  .alert-danger hr {
    border-top-color: #f6b6b6; }
  .alert-danger .alert-link {
    color: #541b1b; }

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe; }
  .alert-light hr {
    border-top-color: #ececf6; }
  .alert-light .alert-link {
    color: #686868; }

.alert-dark {
  color: #0f1218;
  background-color: #d2d3d5;
  border-color: #bfc1c5; }
  .alert-dark hr {
    border-top-color: #b2b4b9; }
  .alert-dark .alert-link {
    color: black; }

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  line-height: 0;
  font-size: 0.65625rem;
  background-color: #f0f2f8;
  border-radius: 4px; }

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #7267EF;
  transition: width 0.6s ease; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar {
      transition: none; } }

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem; }

.progress-bar-animated {
  animation: progress-bar-stripes 1s linear infinite; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar-animated {
      animation: none; } }

.media {
  display: flex;
  align-items: flex-start; }

.media-body {
  flex: 1; }

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 4px; }

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    z-index: 1;
    color: #495057;
    text-decoration: none;
    background-color: #f8f9fa; }
  .list-group-item-action:active {
    color: #293240;
    background-color: #e9ecef; }

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125); }
  .list-group-item:first-child {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit; }
  .list-group-item:last-child {
    border-bottom-right-radius: inherit;
    border-bottom-left-radius: inherit; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: #fff; }
  .list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #7267EF;
    border-color: #7267EF; }
  .list-group-item + .list-group-item {
    border-top-width: 0; }
    .list-group-item + .list-group-item.active {
      margin-top: -1px;
      border-top-width: 1px; }

.list-group-horizontal {
  flex-direction: row; }
  .list-group-horizontal > .list-group-item:first-child {
    border-bottom-left-radius: 4px;
    border-top-right-radius: 0; }
  .list-group-horizontal > .list-group-item:last-child {
    border-top-right-radius: 4px;
    border-bottom-left-radius: 0; }
  .list-group-horizontal > .list-group-item.active {
    margin-top: 0; }
  .list-group-horizontal > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0; }
    .list-group-horizontal > .list-group-item + .list-group-item.active {
      margin-left: -1px;
      border-left-width: 1px; }

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row; }
    .list-group-horizontal-sm > .list-group-item:first-child {
      border-bottom-left-radius: 4px;
      border-top-right-radius: 0; }
    .list-group-horizontal-sm > .list-group-item:last-child {
      border-top-right-radius: 4px;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-sm > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-sm > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row; }
    .list-group-horizontal-md > .list-group-item:first-child {
      border-bottom-left-radius: 4px;
      border-top-right-radius: 0; }
    .list-group-horizontal-md > .list-group-item:last-child {
      border-top-right-radius: 4px;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-md > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-md > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-md > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row; }
    .list-group-horizontal-lg > .list-group-item:first-child {
      border-bottom-left-radius: 4px;
      border-top-right-radius: 0; }
    .list-group-horizontal-lg > .list-group-item:last-child {
      border-top-right-radius: 4px;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-lg > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-lg > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row; }
    .list-group-horizontal-xl > .list-group-item:first-child {
      border-bottom-left-radius: 4px;
      border-top-right-radius: 0; }
    .list-group-horizontal-xl > .list-group-item:last-child {
      border-top-right-radius: 4px;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-xl > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-xl > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

.list-group-flush {
  border-radius: 0; }
  .list-group-flush > .list-group-item {
    border-width: 0 0 1px; }
    .list-group-flush > .list-group-item:last-child {
      border-bottom-width: 0; }

.list-group-item-primary {
  color: #3b367c;
  background-color: #d8d4fb; }
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #3b367c;
    background-color: #c3bdf9; }
  .list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #3b367c;
    border-color: #3b367c; }

.list-group-item-secondary {
  color: #383d41;
  background-color: #d6d8db; }
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #383d41;
    background-color: #c8cbcf; }
  .list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #383d41;
    border-color: #383d41; }

.list-group-item-success {
  color: #0c6735;
  background-color: #beefd4; }
  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #0c6735;
    background-color: #aaeac6; }
  .list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #0c6735;
    border-color: #0c6735; }

.list-group-item-info {
  color: #20696f;
  background-color: #c9f0f4; }
  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #20696f;
    background-color: #b4eaf0; }
  .list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #20696f;
    border-color: #20696f; }

.list-group-item-warning {
  color: #85540f;
  background-color: #ffe5c0; }
  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #85540f;
    background-color: #ffdaa7; }
  .list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #85540f;
    border-color: #85540f; }

.list-group-item-danger {
  color: #7a2828;
  background-color: #f9cdcd; }
  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #7a2828;
    background-color: #f6b6b6; }
  .list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #7a2828;
    border-color: #7a2828; }

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe; }
  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #818182;
    background-color: #ececf6; }
  .list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #818182;
    border-color: #818182; }

.list-group-item-dark {
  color: #0f1218;
  background-color: #bfc1c5; }
  .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: #0f1218;
    background-color: #b2b4b9; }
  .list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #0f1218;
    border-color: #0f1218; }

.close {
  float: right;
  font-size: 1.3125rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5; }
  .close:hover {
    color: #000;
    text-decoration: none; }
  .close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
    opacity: .75; }

button.close {
  padding: 0;
  background-color: transparent;
  border: 0; }

a.close.disabled {
  pointer-events: none; }

.toast {
  flex-basis: 350px;
  max-width: 350px;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  opacity: 0;
  border-radius: 0.25rem; }
  .toast:not(:last-child) {
    margin-bottom: 0.75rem; }
  .toast.showing {
    opacity: 1; }
  .toast.show {
    display: block;
    opacity: 1; }
  .toast.hide {
    display: none; }

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px); }

.toast-body {
  padding: 0.75rem; }

.modal-open {
  overflow: hidden; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -50px); }
    @media (prefers-reduced-motion: reduce) {
      .modal.fade .modal-dialog {
        transition: none; } }
  .modal.show .modal-dialog {
    transform: none; }
  .modal.modal-static .modal-dialog {
    transform: scale(1.02); }

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem); }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 1rem);
    overflow: hidden; }
  .modal-dialog-scrollable .modal-header,
  .modal-dialog-scrollable .modal-footer {
    flex-shrink: 0; }
  .modal-dialog-scrollable .modal-body {
    overflow-y: auto; }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem); }
  .modal-dialog-centered::before {
    display: block;
    height: calc(100vh - 1rem);
    height: min-content;
    content: ""; }
  .modal-dialog-centered.modal-dialog-scrollable {
    flex-direction: column;
    justify-content: center;
    height: 100%; }
    .modal-dialog-centered.modal-dialog-scrollable .modal-content {
      max-height: none; }
    .modal-dialog-centered.modal-dialog-scrollable::before {
      content: none; }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1.25rem 1.5625rem;
  border-bottom: 1px solid #f1f1f1;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .modal-header .close {
    padding: 1.25rem 1.5625rem;
    margin: -1.25rem -1.5625rem -1.25rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.5; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1.25rem; }

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #f1f1f1;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px); }
  .modal-footer > * {
    margin: 0.25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto; }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem); }
    .modal-dialog-scrollable .modal-content {
      max-height: calc(100vh - 3.5rem); }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem); }
    .modal-dialog-centered::before {
      height: calc(100vh - 3.5rem);
      height: min-content; }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px; } }

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px; } }

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.76563rem;
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: 0.9; }
  .tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem; }
    .tooltip .arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0; }
  .bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
    bottom: 0; }
    .bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
      top: 0;
      border-width: 0.4rem 0.4rem 0;
      border-top-color: #000; }

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem; }
  .bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
      right: 0;
      border-width: 0.4rem 0.4rem 0.4rem 0;
      border-right-color: #000; }

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0; }
  .bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
    top: 0; }
    .bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
      bottom: 0;
      border-width: 0 0.4rem 0.4rem;
      border-bottom-color: #000; }

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem; }
  .bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
      left: 0;
      border-width: 0.4rem 0 0.4rem 0.4rem;
      border-left-color: #000; }

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 4px; }

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.76563rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px; }
  .popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 6px; }
    .popover .arrow::before, .popover .arrow::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem; }
  .bs-popover-top > .arrow, .bs-popover-auto[x-placement^="top"] > .arrow {
    bottom: calc(-0.5rem - 1px); }
    .bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^="top"] > .arrow::before {
      bottom: 0;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^="top"] > .arrow::after {
      bottom: 1px;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: #fff; }

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem; }
  .bs-popover-right > .arrow, .bs-popover-auto[x-placement^="right"] > .arrow {
    left: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 6px 0; }
    .bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^="right"] > .arrow::before {
      left: 0;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^="right"] > .arrow::after {
      left: 1px;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: #fff; }

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem; }
  .bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^="bottom"] > .arrow {
    top: calc(-0.5rem - 1px); }
    .bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^="bottom"] > .arrow::before {
      top: 0;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^="bottom"] > .arrow::after {
      top: 1px;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: #fff; }
  .bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: "";
    border-bottom: 1px solid #f7f7f7; }

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem; }
  .bs-popover-left > .arrow, .bs-popover-auto[x-placement^="left"] > .arrow {
    right: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 6px 0; }
    .bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^="left"] > .arrow::before {
      right: 0;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^="left"] > .arrow::after {
      right: 1px;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: #fff; }

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #060606;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .popover-header:empty {
    display: none; }

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #293240; }

.carousel {
  position: relative; }

.carousel.pointer-event {
  touch-action: pan-y; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }
  .carousel-inner::after {
    display: block;
    clear: both;
    content: ""; }

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-item {
      transition: none; } }

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block; }

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%); }

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%); }

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none; }

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1; }

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-fade .active.carousel-item-left,
    .carousel-fade .active.carousel-item-right {
      transition: none; } }

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-control-prev,
    .carousel-control-next {
      transition: none; } }
  .carousel-control-prev:hover, .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: 0.9; }

.carousel-control-prev {
  left: 0; }

.carousel-control-next {
  right: 0; }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: no-repeat 50% / 100% 100%; }

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3e%3c/svg%3e"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3e%3c/svg%3e"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none; }
  .carousel-indicators li {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: .5;
    transition: opacity 0.6s ease; }
    @media (prefers-reduced-motion: reduce) {
      .carousel-indicators li {
        transition: none; } }
  .carousel-indicators .active {
    opacity: 1; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center; }

@keyframes spinner-border {
  to {
    transform: rotate(360deg); } }

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: spinner-border .75s linear infinite; }

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em; }

@keyframes spinner-grow {
  0% {
    transform: scale(0); }
  50% {
    opacity: 1;
    transform: none; } }

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: spinner-grow .75s linear infinite; }

.spinner-grow-sm {
  width: 1rem;
  height: 1rem; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #7267EF !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #4739ea !important; }

.bg-secondary {
  background-color: #6c757d !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important; }

.bg-success {
  background-color: #17C666 !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #12984e !important; }

.bg-info {
  background-color: #3ec9d6 !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #27adba !important; }

.bg-warning {
  background-color: #ffa21d !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #e98900 !important; }

.bg-danger {
  background-color: #EA4D4D !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #e51f1f !important; }

.bg-light {
  background-color: #f8f9fa !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important; }

.bg-dark {
  background-color: #1c232f !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #090b0f !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #f1f1f1 !important; }

.border-top {
  border-top: 1px solid #f1f1f1 !important; }

.border-right {
  border-right: 1px solid #f1f1f1 !important; }

.border-bottom {
  border-bottom: 1px solid #f1f1f1 !important; }

.border-left {
  border-left: 1px solid #f1f1f1 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #7267EF !important; }

.border-secondary {
  border-color: #6c757d !important; }

.border-success {
  border-color: #17C666 !important; }

.border-info {
  border-color: #3ec9d6 !important; }

.border-warning {
  border-color: #ffa21d !important; }

.border-danger {
  border-color: #EA4D4D !important; }

.border-light {
  border-color: #f8f9fa !important; }

.border-dark {
  border-color: #1c232f !important; }

.border-white {
  border-color: #fff !important; }

.rounded-sm {
  border-radius: 2px !important; }

.rounded {
  border-radius: 4px !important; }

.rounded-top {
  border-top-left-radius: 4px !important;
  border-top-right-radius: 4px !important; }

.rounded-right {
  border-top-right-radius: 4px !important;
  border-bottom-right-radius: 4px !important; }

.rounded-bottom {
  border-bottom-right-radius: 4px !important;
  border-bottom-left-radius: 4px !important; }

.rounded-left {
  border-top-left-radius: 4px !important;
  border-bottom-left-radius: 4px !important; }

.rounded-lg {
  border-radius: 6px !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.user-select-all {
  user-select: all !important; }

.user-select-auto {
  user-select: auto !important; }

.user-select-none {
  user-select: none !important; }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.vw-100 {
  width: 100vw !important; }

.vh-100 {
  height: 100vh !important; }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.m-n1 {
  margin: -0.25rem !important; }

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important; }

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important; }

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important; }

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important; }

.m-n2 {
  margin: -0.5rem !important; }

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important; }

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important; }

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important; }

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important; }

.m-n3 {
  margin: -1rem !important; }

.mt-n3,
.my-n3 {
  margin-top: -1rem !important; }

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important; }

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important; }

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important; }

.m-n4 {
  margin: -1.5rem !important; }

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important; }

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important; }

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important; }

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important; }

.m-n5 {
  margin: -3rem !important; }

.mt-n5,
.my-n5 {
  margin-top: -3rem !important; }

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important; }

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important; }

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .m-sm-n1 {
    margin: -0.25rem !important; }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important; }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important; }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important; }
  .m-sm-n2 {
    margin: -0.5rem !important; }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important; }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important; }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important; }
  .m-sm-n3 {
    margin: -1rem !important; }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important; }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important; }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important; }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important; }
  .m-sm-n4 {
    margin: -1.5rem !important; }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important; }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important; }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important; }
  .m-sm-n5 {
    margin: -3rem !important; }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important; }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important; }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important; }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .m-md-n1 {
    margin: -0.25rem !important; }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important; }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important; }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important; }
  .m-md-n2 {
    margin: -0.5rem !important; }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important; }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important; }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important; }
  .m-md-n3 {
    margin: -1rem !important; }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important; }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important; }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important; }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important; }
  .m-md-n4 {
    margin: -1.5rem !important; }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important; }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important; }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important; }
  .m-md-n5 {
    margin: -3rem !important; }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important; }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important; }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important; }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .m-lg-n1 {
    margin: -0.25rem !important; }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important; }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important; }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important; }
  .m-lg-n2 {
    margin: -0.5rem !important; }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important; }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important; }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important; }
  .m-lg-n3 {
    margin: -1rem !important; }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important; }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important; }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important; }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important; }
  .m-lg-n4 {
    margin: -1.5rem !important; }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important; }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important; }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important; }
  .m-lg-n5 {
    margin: -3rem !important; }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important; }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important; }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important; }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .m-xl-n1 {
    margin: -0.25rem !important; }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important; }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important; }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important; }
  .m-xl-n2 {
    margin: -0.5rem !important; }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important; }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important; }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important; }
  .m-xl-n3 {
    margin: -1rem !important; }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important; }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important; }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important; }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important; }
  .m-xl-n4 {
    margin: -1.5rem !important; }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important; }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important; }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important; }
  .m-xl-n5 {
    margin: -3rem !important; }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important; }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important; }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important; }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0); }

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important; }

.text-justify {
  text-align: justify !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-lighter {
  font-weight: lighter !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-weight-bolder {
  font-weight: bolder !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #7267EF !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #3222e8 !important; }

.text-secondary {
  color: #6c757d !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #494f54 !important; }

.text-success {
  color: #17C666 !important; }

a.text-success:hover, a.text-success:focus {
  color: #0f8143 !important; }

.text-info {
  color: #3ec9d6 !important; }

a.text-info:hover, a.text-info:focus {
  color: #2399a5 !important; }

.text-warning {
  color: #ffa21d !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #d07a00 !important; }

.text-danger {
  color: #EA4D4D !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #d21919 !important; }

.text-light {
  color: #f8f9fa !important; }

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important; }

.text-dark {
  color: #1c232f !important; }

a.text-dark:hover, a.text-dark:focus {
  color: black !important; }

.text-body {
  color: #293240 !important; }

.text-muted {
  color: #6c757d !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.text-decoration-none {
  text-decoration: none !important; }

.text-break {
  word-break: break-word !important;
  overflow-wrap: break-word !important; }

.text-reset {
  color: inherit !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important; }
  a:not(.btn) {
    text-decoration: underline; }
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  @page {
    size: a3; }
  body {
    min-width: 992px !important; }
  .container {
    min-width: 992px !important; }
  .navbar {
    display: none; }
  .badge {
    border: 1px solid #000; }
  .table {
    border-collapse: collapse !important; }
    .table td,
    .table th {
      background-color: #fff !important; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important; }
  .table-dark {
    color: inherit; }
    .table-dark th,
    .table-dark td,
    .table-dark thead th,
    .table-dark tbody + tbody {
      border-color: #f1f1f1; }
  .table .thead-dark th {
    color: inherit;
    border-color: #f1f1f1; } }

/**  =====================
      Generic-class css start
========================== **/
/*====== Padding , Margin css starts ======*/
.p-0 {
  padding: 0px; }

.p-t-0 {
  padding-top: 0px; }

.p-b-0 {
  padding-bottom: 0px; }

.p-l-0 {
  padding-left: 0px; }

.p-r-0 {
  padding-right: 0px; }

.m-0 {
  margin: 0px; }

.m-t-0 {
  margin-top: 0px; }

.m-b-0 {
  margin-bottom: 0px; }

.m-l-0 {
  margin-left: 0px; }

.m-r-0 {
  margin-right: 0px; }

.p-5 {
  padding: 5px; }

.p-t-5 {
  padding-top: 5px; }

.p-b-5 {
  padding-bottom: 5px; }

.p-l-5 {
  padding-left: 5px; }

.p-r-5 {
  padding-right: 5px; }

.m-5 {
  margin: 5px; }

.m-t-5 {
  margin-top: 5px; }

.m-b-5 {
  margin-bottom: 5px; }

.m-l-5 {
  margin-left: 5px; }

.m-r-5 {
  margin-right: 5px; }

.p-10 {
  padding: 10px; }

.p-t-10 {
  padding-top: 10px; }

.p-b-10 {
  padding-bottom: 10px; }

.p-l-10 {
  padding-left: 10px; }

.p-r-10 {
  padding-right: 10px; }

.m-10 {
  margin: 10px; }

.m-t-10 {
  margin-top: 10px; }

.m-b-10 {
  margin-bottom: 10px; }

.m-l-10 {
  margin-left: 10px; }

.m-r-10 {
  margin-right: 10px; }

.p-15 {
  padding: 15px; }

.p-t-15 {
  padding-top: 15px; }

.p-b-15 {
  padding-bottom: 15px; }

.p-l-15 {
  padding-left: 15px; }

.p-r-15 {
  padding-right: 15px; }

.m-15 {
  margin: 15px; }

.m-t-15 {
  margin-top: 15px; }

.m-b-15 {
  margin-bottom: 15px; }

.m-l-15 {
  margin-left: 15px; }

.m-r-15 {
  margin-right: 15px; }

.p-20 {
  padding: 20px; }

.p-t-20 {
  padding-top: 20px; }

.p-b-20 {
  padding-bottom: 20px; }

.p-l-20 {
  padding-left: 20px; }

.p-r-20 {
  padding-right: 20px; }

.m-20 {
  margin: 20px; }

.m-t-20 {
  margin-top: 20px; }

.m-b-20 {
  margin-bottom: 20px; }

.m-l-20 {
  margin-left: 20px; }

.m-r-20 {
  margin-right: 20px; }

.p-25 {
  padding: 25px; }

.p-t-25 {
  padding-top: 25px; }

.p-b-25 {
  padding-bottom: 25px; }

.p-l-25 {
  padding-left: 25px; }

.p-r-25 {
  padding-right: 25px; }

.m-25 {
  margin: 25px; }

.m-t-25 {
  margin-top: 25px; }

.m-b-25 {
  margin-bottom: 25px; }

.m-l-25 {
  margin-left: 25px; }

.m-r-25 {
  margin-right: 25px; }

.p-30 {
  padding: 30px; }

.p-t-30 {
  padding-top: 30px; }

.p-b-30 {
  padding-bottom: 30px; }

.p-l-30 {
  padding-left: 30px; }

.p-r-30 {
  padding-right: 30px; }

.m-30 {
  margin: 30px; }

.m-t-30 {
  margin-top: 30px; }

.m-b-30 {
  margin-bottom: 30px; }

.m-l-30 {
  margin-left: 30px; }

.m-r-30 {
  margin-right: 30px; }

.p-35 {
  padding: 35px; }

.p-t-35 {
  padding-top: 35px; }

.p-b-35 {
  padding-bottom: 35px; }

.p-l-35 {
  padding-left: 35px; }

.p-r-35 {
  padding-right: 35px; }

.m-35 {
  margin: 35px; }

.m-t-35 {
  margin-top: 35px; }

.m-b-35 {
  margin-bottom: 35px; }

.m-l-35 {
  margin-left: 35px; }

.m-r-35 {
  margin-right: 35px; }

.p-40 {
  padding: 40px; }

.p-t-40 {
  padding-top: 40px; }

.p-b-40 {
  padding-bottom: 40px; }

.p-l-40 {
  padding-left: 40px; }

.p-r-40 {
  padding-right: 40px; }

.m-40 {
  margin: 40px; }

.m-t-40 {
  margin-top: 40px; }

.m-b-40 {
  margin-bottom: 40px; }

.m-l-40 {
  margin-left: 40px; }

.m-r-40 {
  margin-right: 40px; }

.p-45 {
  padding: 45px; }

.p-t-45 {
  padding-top: 45px; }

.p-b-45 {
  padding-bottom: 45px; }

.p-l-45 {
  padding-left: 45px; }

.p-r-45 {
  padding-right: 45px; }

.m-45 {
  margin: 45px; }

.m-t-45 {
  margin-top: 45px; }

.m-b-45 {
  margin-bottom: 45px; }

.m-l-45 {
  margin-left: 45px; }

.m-r-45 {
  margin-right: 45px; }

.p-50 {
  padding: 50px; }

.p-t-50 {
  padding-top: 50px; }

.p-b-50 {
  padding-bottom: 50px; }

.p-l-50 {
  padding-left: 50px; }

.p-r-50 {
  padding-right: 50px; }

.m-50 {
  margin: 50px; }

.m-t-50 {
  margin-top: 50px; }

.m-b-50 {
  margin-bottom: 50px; }

.m-l-50 {
  margin-left: 50px; }

.m-r-50 {
  margin-right: 50px; }

/*====== Padding , Margin css ends ======*/
/*====== Font-size css starts ======*/
.f-10 {
  font-size: 10px; }

.f-12 {
  font-size: 12px; }

.f-14 {
  font-size: 14px; }

.f-16 {
  font-size: 16px; }

.f-18 {
  font-size: 18px; }

.f-20 {
  font-size: 20px; }

.f-22 {
  font-size: 22px; }

.f-24 {
  font-size: 24px; }

.f-26 {
  font-size: 26px; }

.f-28 {
  font-size: 28px; }

.f-30 {
  font-size: 30px; }

.f-32 {
  font-size: 32px; }

.f-34 {
  font-size: 34px; }

.f-36 {
  font-size: 36px; }

.f-38 {
  font-size: 38px; }

.f-40 {
  font-size: 40px; }

.f-42 {
  font-size: 42px; }

.f-44 {
  font-size: 44px; }

.f-46 {
  font-size: 46px; }

.f-48 {
  font-size: 48px; }

.f-50 {
  font-size: 50px; }

.f-52 {
  font-size: 52px; }

.f-54 {
  font-size: 54px; }

.f-56 {
  font-size: 56px; }

.f-58 {
  font-size: 58px; }

.f-60 {
  font-size: 60px; }

.f-62 {
  font-size: 62px; }

.f-64 {
  font-size: 64px; }

.f-66 {
  font-size: 66px; }

.f-68 {
  font-size: 68px; }

.f-70 {
  font-size: 70px; }

.f-72 {
  font-size: 72px; }

.f-74 {
  font-size: 74px; }

.f-76 {
  font-size: 76px; }

.f-78 {
  font-size: 78px; }

.f-80 {
  font-size: 80px; }

/*====== Font-size css ends ======*/
/*====== Font-weight css starts ======*/
.f-w-100 {
  font-weight: 100; }

.f-w-200 {
  font-weight: 200; }

.f-w-300 {
  font-weight: 300; }

.f-w-400 {
  font-weight: 400; }

.f-w-500 {
  font-weight: 500; }

.f-w-600 {
  font-weight: 600; }

.f-w-700 {
  font-weight: 700; }

.f-w-800 {
  font-weight: 800; }

.f-w-900 {
  font-weight: 900; }

/*====== Font-weight css ends ======*/
/*====== width, Height css starts ======*/
.wid-10 {
  width: 10px; }

.hei-10 {
  height: 10px; }

.wid-15 {
  width: 15px; }

.hei-15 {
  height: 15px; }

.wid-20 {
  width: 20px; }

.hei-20 {
  height: 20px; }

.wid-25 {
  width: 25px; }

.hei-25 {
  height: 25px; }

.wid-30 {
  width: 30px; }

.hei-30 {
  height: 30px; }

.wid-35 {
  width: 35px; }

.hei-35 {
  height: 35px; }

.wid-40 {
  width: 40px; }

.hei-40 {
  height: 40px; }

.wid-45 {
  width: 45px; }

.hei-45 {
  height: 45px; }

.wid-50 {
  width: 50px; }

.hei-50 {
  height: 50px; }

.wid-55 {
  width: 55px; }

.hei-55 {
  height: 55px; }

.wid-60 {
  width: 60px; }

.hei-60 {
  height: 60px; }

.wid-65 {
  width: 65px; }

.hei-65 {
  height: 65px; }

.wid-70 {
  width: 70px; }

.hei-70 {
  height: 70px; }

.wid-75 {
  width: 75px; }

.hei-75 {
  height: 75px; }

.wid-80 {
  width: 80px; }

.hei-80 {
  height: 80px; }

.wid-85 {
  width: 85px; }

.hei-85 {
  height: 85px; }

.wid-90 {
  width: 90px; }

.hei-90 {
  height: 90px; }

.wid-95 {
  width: 95px; }

.hei-95 {
  height: 95px; }

.wid-100 {
  width: 100px; }

.hei-100 {
  height: 100px; }

.wid-105 {
  width: 105px; }

.hei-105 {
  height: 105px; }

.wid-110 {
  width: 110px; }

.hei-110 {
  height: 110px; }

.wid-115 {
  width: 115px; }

.hei-115 {
  height: 115px; }

.wid-120 {
  width: 120px; }

.hei-120 {
  height: 120px; }

.wid-125 {
  width: 125px; }

.hei-125 {
  height: 125px; }

.wid-130 {
  width: 130px; }

.hei-130 {
  height: 130px; }

.wid-135 {
  width: 135px; }

.hei-135 {
  height: 135px; }

.wid-140 {
  width: 140px; }

.hei-140 {
  height: 140px; }

.wid-145 {
  width: 145px; }

.hei-145 {
  height: 145px; }

.wid-150 {
  width: 150px; }

.hei-150 {
  height: 150px; }

/*====== width, Height css ends ======*/
/*====== border-width css starts ======*/
.b-wid-1 {
  border-width: 1px; }

.b-wid-2 {
  border-width: 2px; }

.b-wid-3 {
  border-width: 3px; }

.b-wid-4 {
  border-width: 4px; }

.b-wid-5 {
  border-width: 5px; }

.b-wid-6 {
  border-width: 6px; }

.b-wid-7 {
  border-width: 7px; }

.b-wid-8 {
  border-width: 8px; }

/*====== border-width css ends ======*/
/*====== background starts ======*/
.bg-body {
  background: #f0f2f8; }

.bg-light-primary {
  background: #e8e7fc; }

.icon-svg-primary {
  fill: #e8e7fc;
  stroke: #7267EF; }

.bg-light-secondary {
  background: #e7e9ea; }

.icon-svg-secondary {
  fill: #e7e9ea;
  stroke: #6c757d; }

.bg-light-success {
  background: #daf6e7; }

.icon-svg-success {
  fill: #daf6e7;
  stroke: #17C666; }

.bg-light-info {
  background: #e0f6f8; }

.icon-svg-info {
  fill: #e0f6f8;
  stroke: #3ec9d6; }

.bg-light-warning {
  background: #fff0db; }

.icon-svg-warning {
  fill: #fff0db;
  stroke: #ffa21d; }

.bg-light-danger {
  background: #fce3e3; }

.icon-svg-danger {
  fill: #fce3e3;
  stroke: #EA4D4D; }

.bg-light-light {
  background: #fefefe; }

.icon-svg-light {
  fill: #fefefe;
  stroke: #f8f9fa; }

.bg-light-dark {
  background: #dbdcde; }

.icon-svg-dark {
  fill: #dbdcde;
  stroke: #1c232f; }

/*====== background ends ======*/
/*====== border color css starts ======*/
.b-primary {
  border: 1px solid #7267EF; }

.border-bottom-primary td {
  border-bottom: 1px solid #7267EF; }

.border-bottom-primary th {
  border-bottom: 1px solid #7267EF !important; }

.fill-primary {
  fill: #7267EF; }

.b-secondary {
  border: 1px solid #6c757d; }

.border-bottom-secondary td {
  border-bottom: 1px solid #6c757d; }

.border-bottom-secondary th {
  border-bottom: 1px solid #6c757d !important; }

.fill-secondary {
  fill: #6c757d; }

.b-success {
  border: 1px solid #17C666; }

.border-bottom-success td {
  border-bottom: 1px solid #17C666; }

.border-bottom-success th {
  border-bottom: 1px solid #17C666 !important; }

.fill-success {
  fill: #17C666; }

.b-info {
  border: 1px solid #3ec9d6; }

.border-bottom-info td {
  border-bottom: 1px solid #3ec9d6; }

.border-bottom-info th {
  border-bottom: 1px solid #3ec9d6 !important; }

.fill-info {
  fill: #3ec9d6; }

.b-warning {
  border: 1px solid #ffa21d; }

.border-bottom-warning td {
  border-bottom: 1px solid #ffa21d; }

.border-bottom-warning th {
  border-bottom: 1px solid #ffa21d !important; }

.fill-warning {
  fill: #ffa21d; }

.b-danger {
  border: 1px solid #EA4D4D; }

.border-bottom-danger td {
  border-bottom: 1px solid #EA4D4D; }

.border-bottom-danger th {
  border-bottom: 1px solid #EA4D4D !important; }

.fill-danger {
  fill: #EA4D4D; }

.b-light {
  border: 1px solid #f8f9fa; }

.border-bottom-light td {
  border-bottom: 1px solid #f8f9fa; }

.border-bottom-light th {
  border-bottom: 1px solid #f8f9fa !important; }

.fill-light {
  fill: #f8f9fa; }

.b-dark {
  border: 1px solid #1c232f; }

.border-bottom-dark td {
  border-bottom: 1px solid #1c232f; }

.border-bottom-dark th {
  border-bottom: 1px solid #1c232f !important; }

.fill-dark {
  fill: #1c232f; }

/*====== border color css ends ======*/
/*====== text-color, background color css starts ======*/
.bg-facebook {
  background: #3C5A99; }

.text-facebook {
  color: #3C5A99; }

.bg-twitter {
  background: #42C0FB; }

.text-twitter {
  color: #42C0FB; }

.bg-dribbble {
  background: #EC4A89; }

.text-dribbble {
  color: #EC4A89; }

.bg-pinterest {
  background: #BF2131; }

.text-pinterest {
  color: #BF2131; }

.bg-youtube {
  background: #E0291D; }

.text-youtube {
  color: #E0291D; }

.bg-googleplus {
  background: #C73E2E; }

.text-googleplus {
  color: #C73E2E; }

.bg-instagram {
  background: #AA7C62; }

.text-instagram {
  color: #AA7C62; }

.bg-viber {
  background: #7B519D; }

.text-viber {
  color: #7B519D; }

.bg-behance {
  background: #0057ff; }

.text-behance {
  color: #0057ff; }

.bg-dropbox {
  background: #3380FF; }

.text-dropbox {
  color: #3380FF; }

.bg-linkedin {
  background: #0077B5; }

.text-linkedin {
  color: #0077B5; }

.bg-amazon {
  background: #000; }

.text-amazon {
  color: #000; }

/*====== text-color css ends ======*/
.font-hairline {
  font-weight: 100 !important; }

.font-thin {
  font-weight: 200 !important; }

.font-light {
  font-weight: 300 !important; }

.font-normal {
  font-weight: 400 !important; }

.font-medium {
  font-weight: 500 !important; }

.font-semibold {
  font-weight: 600 !important; }

.font-bold {
  font-weight: 700 !important; }

.font-extrabold {
  font-weight: 800 !important; }

.font-black {
  font-weight: 900 !important; }

.text-xs {
  font-size: 0.625rem !important; }

.text-sm {
  font-size: 0.75rem !important; }

.text-md {
  font-size: 0.8125rem !important; }

.text-base {
  font-size: 0.875rem !important; }

.text-lg {
  font-size: 1rem !important; }

.text-xl {
  font-size: 1.125rem !important; }

.text-2xl {
  font-size: 1.25rem !important; }

.text-3xl {
  font-size: 1.5rem !important; }

.text-4xl {
  font-size: 2rem !important; }

.text-5xl {
  font-size: 2.25rem !important; }

.text-6xl {
  font-size: 2.5rem !important; }

.text-7xl {
  font-size: 3rem !important; }

.text-8xl {
  font-size: 4rem !important; }

.text-9xl {
  font-size: 6rem !important; }

.text-10xl {
  font-size: 8rem !important; }

.uppercase {
  text-transform: uppercase !important; }

.lowercase {
  text-transform: lowercase !important; }

.capitalize {
  text-transform: capitalize !important; }

.normal-case {
  text-transform: none !important; }

/**  =====================
      Custom css start
==========================  **/
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("../fonts/inter/Inter-Regular.woff2?v=3.13") format("woff2"), url("../fonts/inter/Inter-Regular.woff?v=3.13") format("woff"); }

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("../fonts/inter/Inter-Medium.woff2?v=3.13") format("woff2"), url("../fonts/inter/Inter-Medium.woff?v=3.13") format("woff"); }

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("../fonts/inter/Inter-SemiBold.woff2?v=3.13") format("woff2"), url("../fonts/inter/Inter-SemiBold.woff?v=3.13") format("woff"); }

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("../fonts/inter/Inter-Bold.woff2?v=3.13") format("woff2"), url("../fonts/inter/Inter-Bold.woff?v=3.13") format("woff"); }

*:focus {
  outline: none; }

a:hover {
  outline: none;
  text-decoration: none; }

p {
  font-size: 14px; }

b,
strong {
  font-weight: 600; }

.ie-warning {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999999;
  background: #000000;
  width: 100%;
  height: 100%;
  text-align: center;
  color: #fff;
  font-family: "Courier New", Courier, monospace;
  padding: 50px 0; }
  .ie-warning p {
    font-size: 17px; }
  .ie-warning h1 {
    color: #fff; }
  .ie-warning .iew-container {
    min-width: 1024px;
    width: 100%;
    height: 200px;
    background: #fff;
    margin: 50px 0; }
  .ie-warning .iew-download {
    list-style: none;
    padding: 30px 0;
    margin: 0 auto;
    width: 720px; }
    .ie-warning .iew-download > li {
      float: left;
      vertical-align: top; }
      .ie-warning .iew-download > li > a {
        display: block;
        color: #000;
        width: 140px;
        font-size: 15px;
        padding: 15px 0; }
        .ie-warning .iew-download > li > a > div {
          margin-top: 10px; }
        .ie-warning .iew-download > li > a:hover {
          background-color: #eee; }

.btn-page .btn {
  margin-right: 5px;
  margin-bottom: 5px; }

.hljs {
  display: block;
  overflow-x: auto;
  padding: 0.5em;
  color: #abb2bf;
  background: #282c34; }

.hljs-comment,
.hljs-quote {
  color: #5c6370;
  font-style: italic; }

.hljs-doctag,
.hljs-formula,
.hljs-keyword {
  color: #c678dd; }

.hljs-deletion,
.hljs-name,
.hljs-section,
.hljs-selector-tag,
.hljs-subst {
  color: #e06c75; }

.hljs-literal {
  color: #56b6c2; }

.hljs-addition,
.hljs-attribute,
.hljs-meta-string,
.hljs-regexp,
.hljs-string {
  color: #98c379; }

.hljs-built_in,
.hljs-class .hljs-title {
  color: #e6c07b; }

.hljs-attr,
.hljs-number,
.hljs-selector-attr,
.hljs-selector-class,
.hljs-selector-pseudo,
.hljs-template-variable,
.hljs-type,
.hljs-variable {
  color: #d19a66; }

.hljs-bullet,
.hljs-link,
.hljs-meta,
.hljs-selector-id,
.hljs-symbol,
.hljs-title {
  color: #61aeee; }

.hljs-emphasis {
  font-style: italic; }

.hljs-strong {
  font-weight: bold; }

.hljs-link {
  text-decoration: underline; }

.pc-example {
  position: relative; }
  .pc-example .pc-example-btns {
    position: absolute;
    right: 0;
    top: -10px;
    opacity: 0;
    transform: rotateX(-90deg);
    transition: all 0.3s ease-in-out; }
    .pc-example .pc-example-btns .pc-example-btn {
      display: inline-block;
      line-height: 1;
      font-weight: 600;
      background: #7267EF;
      color: #fff;
      padding: 0.1875rem 0.3125rem;
      border-radius: 2px;
      white-space: nowrap;
      font-size: 11px;
      margin: 0; }
      .pc-example .pc-example-btns .pc-example-btn.copy::before {
        content: "COPY"; }
      .pc-example .pc-example-btns .pc-example-btn.copied {
        background: #17C666 !important;
        color: #fff !important; }
        .pc-example .pc-example-btns .pc-example-btn.copied::before {
          content: "COPIED!"; }
  .pc-example:hover .pc-example-btns {
    top: 0;
    transform: rotateX(0deg);
    opacity: 1; }

/* Modal */
.pc-example-modal {
  position: fixed;
  z-index: 1099;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: #282c34;
  transform-origin: 50% 0;
  transform: scale(0);
  transition: all 0.3s ease-in-out; }

.pc-example-modal-opened {
  overflow: hidden !important; }
  .pc-example-modal-opened .pc-example-modal {
    transform: scale(1); }

.pc-example-modal-content {
  max-width: 100vw;
  margin: auto;
  padding: 50px;
  height: 100vh;
  overflow: auto; }
  .pc-example-modal-content > pre {
    overflow: hidden;
    width: fit-content; }
    .pc-example-modal-content > pre > code {
      padding: 0;
      background: none;
      font-size: 16px; }

.md-pc-example-modal-copy {
  display: block;
  position: fixed;
  top: 90px;
  right: 30px;
  margin-right: 8px;
  border-radius: 50%;
  padding: 15px;
  background: #7267EF;
  color: #fff;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.2);
  z-index: 1;
  white-space: nowrap; }
  .md-pc-example-modal-copy:hover {
    box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.2);
    color: #fff; }
  .md-pc-example-modal-copy.copied::before {
    content: "Copied to Clipboard Successfully ! . . .";
    position: absolute;
    display: block;
    right: 100%;
    margin-right: 10px;
    font-size: 14px;
    background: #7267EF;
    line-height: 24px;
    height: 24px;
    border-radius: 3px;
    padding: 0 6px;
    top: 50%;
    margin-top: -12px; }

.pc-example-modal-close {
  display: block;
  position: fixed;
  top: 40px;
  right: 52px;
  color: #fff;
  opacity: 0.2;
  transition: all 0.3s ease-in-out;
  z-index: 1; }
  .pc-example-modal-close > svg {
    width: 35px;
    height: 35px; }
  .pc-example-modal-close:hover {
    color: #EA4D4D;
    opacity: 0.9; }

/* ========================================================
 ===============     document      ======================
 ========================================================

 Grid examples
*/
.bd-example-row {
  background: #f0f2f8;
  padding: 15px;
  margin-bottom: 15px; }
  .bd-example-row .row > [class^="col-"],
  .bd-example-row .row > .col {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    background-color: rgba(86, 61, 124, 0.15);
    border: 1px solid rgba(86, 61, 124, 0.2); }
  .bd-example-row .row + .row {
    margin-top: 1rem; }
  .bd-example-row .flex-items-bottom,
  .bd-example-row .flex-items-middle,
  .bd-example-row .flex-items-top {
    min-height: 6rem;
    background-color: rgba(255, 0, 0, 0.1); }

.bd-example-row-flex-cols .row {
  min-height: 10rem;
  background-color: rgba(255, 0, 0, 0.1); }

/* Docs examples */
.bd-example {
  position: relative;
  padding: 1rem;
  margin: 1rem;
  border: solid #ddd;
  border-width: 0.2rem 0 0; }
  @media only screen and (max-height: 575px) {
    .bd-example {
      padding: 1.5rem;
      margin-right: 0;
      margin-left: 0;
      border-width: 0.2rem; } }

/* Example modals */
.modal {
  z-index: 1072; }
  .modal .popover,
  .modal .tooltip {
    z-index: 1073; }

.modal-backdrop {
  z-index: 1071; }

.bd-example-modal {
  background-color: #fafafa; }
  .bd-example-modal .modal {
    position: relative;
    top: auto;
    right: auto;
    bottom: auto;
    left: auto;
    z-index: 1;
    display: block; }
  .bd-example-modal .modal-dialog {
    left: auto;
    margin-right: auto;
    margin-left: auto; }

/* Example tabbable tabs */
.bd-example-tabs .nav-tabs {
  margin-bottom: 1rem; }

/* Code snippets */
.highlight {
  padding: 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  background-color: #f3f2fb;
  -ms-overflow-style: -ms-autohiding-scrollbar; }
  @media only screen and (max-height: 575px) {
    .highlight {
      padding: 1.5rem; } }

.bd-content .highlight {
  margin-right: 15px;
  margin-left: 15px; }
  @media only screen and (max-height: 575px) {
    .bd-content .highlight {
      margin-right: 0;
      margin-left: 0; } }

.highlight pre {
  padding: 0;
  margin-top: 0;
  margin-bottom: 0;
  background-color: transparent;
  border: 0; }

.highlight pre code {
  font-size: inherit;
  color: #333; }

:not(pre) > code[class*="language-"],
pre[class*="language-"] {
  background: transparent; }

/* ================================    Blockquote Start  ===================== */
.blockquote {
  border-left: 0.25rem solid #f1f1f1;
  padding: 0.5rem 1rem; }
  .blockquote.text-right {
    border-left: none;
    border-right: 0.25rem solid #f1f1f1; }

/* ================================    Blockquote End  ===================== */
.pc-sidebar {
  background: #1c232f;
  box-shadow: 0 1px 20px 0 rgba(69, 90, 100, 0.08);
  width: 280px;
  position: fixed;
  top: 0;
  bottom: 0;
  z-index: 1026; }
  .pc-sidebar ul {
    list-style: none;
    padding-left: 0;
    margin-bottom: 0; }
  .pc-sidebar .pc-caption {
    color: #7267EF;
    display: block;
    padding: 25px 30px 10px;
    letter-spacing: 0.07em;
    text-transform: uppercase;
    font-size: 11px;
    font-weight: 600; }
    .pc-sidebar .pc-caption label {
      margin-bottom: 0; }
    .pc-sidebar .pc-caption span:not(.badge) {
      display: block;
      color: #67758A;
      font-size: 10px;
      font-weight: 500;
      text-transform: capitalize; }
  .pc-sidebar .pc-micon {
    margin-right: 15px; }
    .pc-sidebar .pc-micon i {
      font-size: 18px;
      color: #b3aee4; }
    .pc-sidebar .pc-micon > svg {
      width: 18px;
      height: 18px;
      color: #b3aee4;
      fill: rgba(114, 103, 239, 0.2); }
  .pc-sidebar .pc-link {
    display: block;
    padding: 14px 30px;
    color: #ced4dc;
    font-size: 13px;
    font-weight: 500; }
    .pc-sidebar .pc-link:active, .pc-sidebar .pc-link:focus, .pc-sidebar .pc-link:hover {
      text-decoration: none;
      color: #fff; }
      .pc-sidebar .pc-link:active .pc-micon i,
      .pc-sidebar .pc-link:active .pc-micon svg, .pc-sidebar .pc-link:focus .pc-micon i,
      .pc-sidebar .pc-link:focus .pc-micon svg, .pc-sidebar .pc-link:hover .pc-micon i,
      .pc-sidebar .pc-link:hover .pc-micon svg {
        color: #fff; }
  .pc-sidebar .pc-arrow {
    float: right;
    display: inline-block;
    transition: all 0.2s ease-in-out; }
    .pc-sidebar .pc-arrow > svg {
      width: 14px;
      height: 14px; }
  .pc-sidebar .pc-badge {
    margin-left: 8px; }
  .pc-sidebar .m-header {
    height: 70px;
    display: flex;
    align-items: center;
    padding: 15px 30px;
    background: #161c25; }
    .pc-sidebar .m-header .logo-sm {
      display: none; }
  .pc-sidebar .navbar-content {
    position: relative;
    height: calc(100vh - 70px); }
  .pc-sidebar .pc-submenu {
    display: none; }
    .pc-sidebar .pc-submenu .pc-link {
      padding: 10px 30px 10px 65px; }
    .pc-sidebar .pc-submenu .pc-submenu .pc-link {
      padding: 10px 30px 10px 85px; }
  .pc-sidebar .pc-item.active > .pc-link, .pc-sidebar .pc-item:focus > .pc-link, .pc-sidebar .pc-item:hover > .pc-link {
    color: #fff; }
    .pc-sidebar .pc-item.active > .pc-link .pc-micon i,
    .pc-sidebar .pc-item.active > .pc-link .pc-micon svg, .pc-sidebar .pc-item:focus > .pc-link .pc-micon i,
    .pc-sidebar .pc-item:focus > .pc-link .pc-micon svg, .pc-sidebar .pc-item:hover > .pc-link .pc-micon i,
    .pc-sidebar .pc-item:hover > .pc-link .pc-micon svg {
      color: #fff; }
  .pc-sidebar .pc-navbar > .pc-item.active > .pc-link, .pc-sidebar .pc-navbar > .pc-item:focus > .pc-link, .pc-sidebar .pc-navbar > .pc-item:hover > .pc-link {
    background: #161c25; }
  .pc-sidebar .pc-hasmenu.pc-trigger > .pc-submenu {
    display: block; }
  .pc-sidebar .pc-hasmenu.pc-trigger > .pc-link > .pc-arrow {
    transform: rotate(90deg); }

body:not(.minimenu) .pc-sidebar .pc-submenu {
  position: relative; }
  body:not(.minimenu) .pc-sidebar .pc-submenu .pc-item {
    position: relative; }
    body:not(.minimenu) .pc-sidebar .pc-submenu .pc-item:before {
      content: "";
      width: 6px;
      height: 6px;
      position: absolute;
      left: 50px;
      top: 16px;
      border-radius: 50%;
      z-index: 1;
      opacity: 0.7; }
    body:not(.minimenu) .pc-sidebar .pc-submenu .pc-item.active:before {
      background: #7267EF; }
    body:not(.minimenu) .pc-sidebar .pc-submenu .pc-item:hover:before {
      background: #ced4dc; }
  body:not(.minimenu) .pc-sidebar .pc-submenu .pc-submenu > .pc-item:before {
    left: 70px; }

.pc-menu-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1;
  background: rgba(0, 0, 0, 0.2); }

.minimenu .pc-sidebar {
  width: 70px; }
  .minimenu .pc-sidebar .m-header {
    padding: 15px 17px; }
    .minimenu .pc-sidebar .m-header .logo-sm {
      display: block; }
    .minimenu .pc-sidebar .m-header .logo-lg {
      display: none; }
  .minimenu .pc-sidebar .pc-badge,
  .minimenu .pc-sidebar .pc-caption,
  .minimenu .pc-sidebar .pc-mtext,
  .minimenu .pc-sidebar .pc-navbar > li > a > .pc-arrow {
    display: none; }
  .minimenu .pc-sidebar .ps__rail-X,
  .minimenu .pc-sidebar .ps__rail-y {
    display: none; }
  .minimenu .pc-sidebar .ps {
    overflow: visible !important; }
  .minimenu .pc-sidebar .pc-link {
    padding: 20px 25px; }
  .minimenu .pc-sidebar .pc-submenu {
    background: #1c232f;
    position: absolute;
    left: 100%;
    padding: 8px 0; }
    .minimenu .pc-sidebar .pc-submenu .pc-link {
      padding: 8px 30px; }
  .minimenu .pc-sidebar .pc-item {
    position: relative; }
    .minimenu .pc-sidebar .pc-item:hover > .pc-link {
      width: calc(200px + 70px);
      background: #161c25;
      color: #fff; }
      .minimenu .pc-sidebar .pc-item:hover > .pc-link .pc-micon {
        margin-right: 40px; }
        .minimenu .pc-sidebar .pc-item:hover > .pc-link .pc-micon i {
          color: #fff; }
      .minimenu .pc-sidebar .pc-item:hover > .pc-link .pc-mtext {
        display: inline-block; }
    .minimenu .pc-sidebar .pc-item:hover > .pc-submenu {
      display: block;
      width: 200px; }
    .minimenu .pc-sidebar .pc-item .pc-item:hover > .pc-link {
      width: 200px;
      background: #1c232f; }
    .minimenu .pc-sidebar .pc-item .pc-item:hover > .pc-submenu {
      top: 0; }

@media (min-width: 1025px) {
  .navbar-overlay .pc-sidebar.pc-over-menu-active .navbar-wrapper {
    position: relative;
    z-index: 5; }
  .navbar-overlay .pc-sidebar:not(.pc-over-menu-active) {
    left: -280px;
    box-shadow: none; } }

@media (max-width: 1024px) {
  .pc-sidebar {
    left: -280px;
    box-shadow: none;
    transition: all 0.15s ease-in-out; }
    .pc-sidebar.mob-sidebar-active {
      left: 0;
      box-shadow: 0 1px 20px 0 rgba(69, 90, 100, 0.08); }
      .pc-sidebar.mob-sidebar-active .navbar-wrapper {
        position: relative;
        z-index: 5;
        background: inherit; }
  .minimenu .pc-container {
    margin-left: 0; } }

.pc-header {
  background: #fff;
  box-shadow: 0 1px 0 0 #f1f1f1;
  color: #1c232f;
  min-height: 70px;
  position: fixed;
  left: 280px;
  right: 0;
  z-index: 1025; }
  .pc-header ul {
    margin-bottom: 0;
    display: inline-flex; }
  .pc-header .header-wrapper {
    display: flex;
    padding: 0 30px; }
  .pc-header .pc-h-item {
    min-height: 70px;
    display: flex;
    align-items: center;
    position: relative; }
  .pc-header .pc-head-link {
    color: #1c232f;
    display: inline-flex;
    align-items: center;
    padding: 0.60rem 0.9rem;
    margin: 0 5px;
    border-radius: 4px;
    position: relative;
    font-weight: 500; }
    .pc-header .pc-head-link > i {
      font-size: 20px;
      color: #7267EF; }
    .pc-header .pc-head-link > svg {
      width: 20px;
      height: 20px; }
    .pc-header .pc-head-link.active, .pc-header .pc-head-link:active, .pc-header .pc-head-link:focus, .pc-header .pc-head-link:hover {
      text-decoration: none;
      color: #7267EF;
      background: #e8e7fc; }
    .pc-header .pc-head-link .pc-h-badge {
      position: absolute;
      top: 3px;
      right: 10px;
      border-radius: 50%;
      font-size: 9px; }
      .pc-header .pc-head-link .pc-h-badge.dots {
        width: 9px;
        height: 9px;
        top: 7px;
        right: 16px;
        padding: 0; }
    .pc-header .pc-head-link .user-desc,
    .pc-header .pc-head-link .user-name {
      display: block;
      line-height: 1; }
    .pc-header .pc-head-link .user-name {
      font-size: 15px;
      font-weight: 600;
      margin-bottom: 5px; }
    .pc-header .pc-head-link .user-desc {
      font-size: 12px;
      font-weight: 400;
      color: rgba(28, 35, 47, 0.7); }
  .pc-header .pc-h-dropdown {
    transform: none !important;
    top: 100% !important; }
    .pc-header .pc-h-dropdown .fa-circle {
      font-size: 5px;
      vertical-align: middle; }
    .pc-header .pc-h-dropdown.dropdown-menu-right {
      right: 0 !important;
      left: auto !important; }
  .pc-header .pc-level-menu {
    position: relative; }
    .pc-header .pc-level-menu:hover .dropdown-menu {
      display: block;
      left: 100%;
      top: -18px !important; }
  .pc-header .pc-mega-menu {
    position: static; }
    .pc-header .pc-mega-menu .pc-mega-dmenu {
      left: 0 !important;
      right: 0;
      transform: none !important;
      top: 100% !important;
      padding: 0; }
      .pc-header .pc-mega-menu .pc-mega-dmenu .row.no-gutters .col {
        padding: 15px 0;
        border-right: 1px dashed #f1f1f1; }
      .pc-header .pc-mega-menu .pc-mega-dmenu .mega-title {
        margin: 10px 25px;
        position: relative; }
      .pc-header .pc-mega-menu .pc-mega-dmenu .pc-mega-list {
        display: block;
        list-style: none;
        padding-left: 0; }
  .pc-header .pc-act-menu .pc-act-dmenu {
    padding: 0;
    min-width: 20rem; }
    .pc-header .pc-act-menu .pc-act-dmenu .act-head {
      border-radius: 2px 2px 0 0;
      padding: 24px 0;
      text-align: center; }
    .pc-header .pc-act-menu .pc-act-dmenu .act-block {
      text-align: center;
      padding: 25px 0;
      border-right: 1px dashed #f1f1f1;
      border-bottom: 1px dashed #f1f1f1;
      display: block;
      color: #293240; }
      .pc-header .pc-act-menu .pc-act-dmenu .act-block:active, .pc-header .pc-act-menu .pc-act-dmenu .act-block:focus, .pc-header .pc-act-menu .pc-act-dmenu .act-block:hover {
        text-decoration: none;
        background: rgba(240, 242, 248, 0.5); }
      .pc-header .pc-act-menu .pc-act-dmenu .act-block i {
        font-size: 35px; }
      .pc-header .pc-act-menu .pc-act-dmenu .act-block svg {
        width: 35px;
        height: 35px;
        color: #000;
        margin-bottom: 10px; }
  .pc-header .drp-search {
    min-width: 20rem; }
  .pc-header .pc-cart-menu .drp-cart {
    min-width: 20rem;
    padding: 0; }
    .pc-header .pc-cart-menu .drp-cart .cart-head {
      border-radius: 2px 2px 0 0;
      padding: 20px 20px;
      background: rgba(232, 231, 252, 0.2);
      border-bottom: 1px solid #f1f1f1; }
    .pc-header .pc-cart-menu .drp-cart .cart-item {
      display: flex;
      padding: 10px 20px; }
      .pc-header .pc-cart-menu .drp-cart .cart-item + .cart-item {
        border-top: 1px dashed #f1f1f1; }
      .pc-header .pc-cart-menu .drp-cart .cart-item img {
        width: 60px; }
      .pc-header .pc-cart-menu .drp-cart .cart-item .cart-desc {
        flex: 1; }
      .pc-header .pc-cart-menu .drp-cart .cart-item .p-star {
        fill: currentColor; }
      .pc-header .pc-cart-menu .drp-cart .cart-item.table-responsive {
        border-top: 1px solid #f1f1f1;
        background: rgba(232, 231, 252, 0.2); }
      .pc-header .pc-cart-menu .drp-cart .cart-item svg {
        width: 14px;
        height: 14px; }
  .pc-header .user-avtar {
    width: 40px;
    margin-right: 10px;
    border-radius: 50%; }

.minimenu .pc-header {
  left: 70px; }

.pc-mob-header {
  display: none;
  background: #161c25;
  height: 70px;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  z-index: 1026;
  left: 0;
  right: 0;
  padding: 0 10px 0 30px; }
  .pc-mob-header .hamburger-inner,
  .pc-mob-header .hamburger-inner::after,
  .pc-mob-header .hamburger-inner::before {
    background: #fff; }
  .pc-mob-header .pc-head-link,
  .pc-mob-header a {
    color: #fff; }

.notification-modal.modal.fade .modal-dialog {
  transform: translate(100%, 0); }

.notification-modal.modal.show .modal-dialog {
  transform: none; }

.notification-modal {
  padding-right: 0 !important; }
  .notification-modal .modal-dialog {
    margin: 0 0 0 auto; }
  .notification-modal .modal-content {
    min-height: 100vh;
    border: none;
    border-radius: 0;
    box-shadow: -1px 0 9px -3px rgba(0, 0, 0, 0.6); }

@media (max-width: 1024px) {
  .pc-mob-header {
    display: flex; }
  .pc-header {
    top: 0;
    left: 0;
    transition: all 0.15s ease-in-out; }
    .pc-header .user-avtar {
      margin-right: 0; }
    .pc-header .pc-head-link .user-desc,
    .pc-header .pc-head-link .user-name {
      display: none; }
    .pc-header .pc-head-link#mobile-collapse {
      position: absolute;
      left: 10px; }
    .pc-header .pcm-logo {
      margin-left: 30px; }
      .pc-header .pcm-logo img {
        max-width: 160px; }
    .pc-header.mob-header-active {
      top: 70px; }
      .pc-header.mob-header-active .header-wrapper {
        background: #fff;
        position: relative;
        z-index: 5; }
    .pc-header .pc-md-overlay {
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      z-index: 1;
      background: rgba(0, 0, 0, 0.2); }
    .pc-header .pc-level-menu .dropdown-menu {
      left: 0;
      display: block;
      padding-left: 30px; }
    .pc-header .pc-mega-menu .pc-mega-dmenu .row.no-gutters > .col {
      flex: 100%; }
    .pc-header .pc-mob-drp {
      transition: left 0.15s ease-in-out;
      left: -280px;
      position: fixed;
      top: 70px;
      bottom: 0; }
      .pc-header .pc-mob-drp.mob-drp-active {
        left: 0;
        z-index: 1026;
        background: #fff;
        box-shadow: 0 1px 20px 0 rgba(69, 90, 100, 0.08);
        width: 280px;
        overflow-y: auto;
        padding: 30px 0; }
        .pc-header .pc-mob-drp.mob-drp-active .pc-h-item {
          display: block;
          min-height: auto;
          position: relative; }
          .pc-header .pc-mob-drp.mob-drp-active .pc-h-item .pc-head-link {
            display: block;
            margin: 5px 10px !important; }
          .pc-header .pc-mob-drp.mob-drp-active .pc-h-item .dropdown-menu {
            position: relative !important;
            width: 100%;
            float: none;
            box-shadow: none; }
        .pc-header .pc-mob-drp.mob-drp-active ul {
          display: block; }
  .minimenu .pc-header {
    left: 0; } }

#pc-noti-home > .media:hover {
  margin: -15px;
  padding: 15px;
  background: rgba(114, 103, 239, 0.1); }

@media (max-width: 767.98px) {
  .pc-header .pc-head-link {
    padding: 0.65rem;
    margin: 0; }
  .pc-header .pc-h-item {
    position: static; }
    .pc-header .pc-h-item .pc-h-dropdown {
      left: 0 !important;
      right: 0 !important; } }

.pc-container {
  position: relative;
  top: 70px;
  margin-left: 280px;
  min-height: calc(100vh - 70px); }
  .pc-container .pcoded-content {
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 30px; }
  .pc-container .page-header + .row {
    padding-top: 50px; }
  .pc-container .page-header + .pcoded-content {
    padding-top: calc(30px + 55px); }

.minimenu .pc-container {
  margin-left: 70px; }

.minimenu .page-header {
  left: 70px; }

.page-header {
  background: #fff;
  box-shadow: 0 1px 20px 0 rgba(69, 90, 100, 0.08);
  position: fixed;
  display: flex;
  align-items: center;
  top: 70px;
  left: 280px;
  right: 0;
  z-index: 1023;
  min-height: 55px;
  padding: 13px 30px; }
  .page-header .page-block {
    width: 100%; }
  .page-header .page-header-title {
    display: inline-block; }
  .page-header h5 {
    margin-bottom: 0;
    margin-right: 8px;
    padding-right: 8px;
    font-weight: 500;
    border-right: 1px solid #cdd4e8; }
  .page-header .breadcrumb {
    padding: 0;
    display: inline-flex;
    margin-bottom: 0;
    background: transparent;
    font-size: 13px; }
    .page-header .breadcrumb a {
      color: #293240; }
    .page-header .breadcrumb .breadcrumb-item a:hover {
      color: #7267EF; }
    .page-header .breadcrumb .breadcrumb-item + .breadcrumb-item::before {
      position: relative;
      top: 2px; }
    .page-header .breadcrumb .breadcrumb-item:last-child {
      opacity: 0.75; }
    .page-header .breadcrumb svg {
      width: 14px;
      height: 14px;
      vertical-align: baseline; }

.upgrade-card .card-body {
  padding-bottom: 100px;
  z-index: 1;
  position: relative; }

.upgrade-card > .container {
  z-index: 5;
  position: relative;
  margin-top: -60px; }

.upgrade-card .up-price-table td {
  border-color: #f0f2f8; }

.upgrade-card .up-price-table th {
  border: none; }
  .upgrade-card .up-price-table th + th {
    border-radius: 5px 5px 0 0;
    padding: 20px 0; }

.upgrade-card .up-price-table tbody tr:first-child td {
  border-top: none; }

.upgrade-card .up-price-table td,
.upgrade-card .up-price-table th {
  text-align: right; }
  .upgrade-card .up-price-table td + td,
  .upgrade-card .up-price-table td + th,
  .upgrade-card .up-price-table th + td,
  .upgrade-card .up-price-table th + th {
    text-align: center; }
  .upgrade-card .up-price-table td + td,
  .upgrade-card .up-price-table th + td {
    border-left: 1px solid #f0f2f8; }

.hamburger {
  display: inline-block;
  cursor: pointer;
  transition: filter 0.15s linear;
  width: 20px;
  height: 20px; }
  .hamburger.is-active .hamburger-inner {
    background-color: #000; }
    .hamburger.is-active .hamburger-inner::after, .hamburger.is-active .hamburger-inner::before {
      background-color: #000; }

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -2px;
  width: 18px;
  height: 2px;
  background-color: #1c232f;
  border-radius: 4px;
  position: absolute;
  transition: transform 0.15s ease; }
  .hamburger-inner::after, .hamburger-inner::before {
    width: 18px;
    height: 2px;
    background-color: #1c232f;
    border-radius: 4px;
    position: absolute;
    transition: transform 0.15s ease; }
  .hamburger-inner::after, .hamburger-inner::before {
    content: "";
    display: block; }
  .hamburger-inner::before {
    top: -6px; }
  .hamburger-inner::after {
    bottom: -6px; }

.hamburger--arrowturn.is-active .hamburger-inner {
  transform: rotate(-180deg); }

.hamburger--arrowturn.is-active .hamburger-inner::before {
  transform: translate3d(4px, 2px, 0) rotate(45deg) scale(0.7, 1); }

.hamburger--arrowturn.is-active .hamburger-inner::after {
  transform: translate3d(4px, -2px, 0) rotate(-45deg) scale(0.7, 1); }

@media (max-width: 1024px) {
  .pc-container {
    margin-left: 0; }
    .pc-container .page-header + .row,
    .pc-container .page-header + .pcoded-content,
    .pc-container .pcoded-content {
      padding-top: 30px; }
  .page-header {
    position: relative;
    left: 0;
    top: 0; }
  .minimenu .page-header {
    left: 0; }
  .minimenu .pc-container {
    margin-left: 0; } }

@media (max-width: 767.98px) {
  .pc-container .pcoded-content {
    padding: 20px; }
  .pc-container .container {
    padding-left: 0;
    padding-right: 0; }
  .page-header {
    padding: 13px 20px; }
    .page-header h5 {
      padding-bottom: 5px;
      margin-bottom: 0;
      border-right: none;
      font-size: 14px; }
    .page-header .breadcrumb {
      display: flex;
      margin-bottom: 0; }
    .page-header .text-md-right {
      margin-top: 5px; } }

.ps {
  overflow: hidden !important;
  overflow-anchor: none;
  touch-action: auto; }

.ps__rail-x {
  display: none;
  opacity: 0;
  transition: background-color 0.2s linear, opacity 0.2s linear;
  height: 15px;
  bottom: 0;
  position: absolute; }

.ps__rail-y {
  display: none;
  opacity: 0;
  transition: background-color 0.2s linear, opacity 0.2s linear;
  width: 15px;
  right: 0;
  position: absolute; }

.ps--active-x > .ps__rail-x,
.ps--active-y > .ps__rail-y {
  display: block;
  background-color: transparent; }

.ps:hover > .ps__rail-x,
.ps:hover > .ps__rail-y {
  opacity: 0.6; }

.ps--focus > .ps__rail-x,
.ps--focus > .ps__rail-y {
  opacity: 0.6; }

.ps--scrolling-x > .ps__rail-x,
.ps--scrolling-y > .ps__rail-y {
  opacity: 0.6; }

.ps .ps__rail-x.ps--clicking,
.ps .ps__rail-x:focus,
.ps .ps__rail-x:hover,
.ps .ps__rail-y.ps--clicking,
.ps .ps__rail-y:focus,
.ps .ps__rail-y:hover {
  background-color: #eee;
  opacity: 0.9; }

.ps__thumb-x {
  background-color: #aaa;
  border-radius: 6px;
  transition: background-color 0.2s linear, height 0.2s ease-in-out;
  height: 6px;
  bottom: 2px;
  position: absolute; }

.ps__thumb-y {
  background-color: #aaa;
  border-radius: 6px;
  transition: background-color 0.2s linear, width 0.2s ease-in-out;
  width: 6px;
  right: 2px;
  position: absolute; }

.ps__rail-x.ps--clicking .ps__thumb-x,
.ps__rail-x:focus > .ps__thumb-x,
.ps__rail-x:hover > .ps__thumb-x {
  background-color: #999;
  height: 11px; }

.ps__rail-y.ps--clicking .ps__thumb-y,
.ps__rail-y:focus > .ps__thumb-y,
.ps__rail-y:hover > .ps__thumb-y {
  background-color: #999;
  width: 11px; }

@supports (-ms-overflow-style: none) {
  .ps {
    overflow: auto !important; } }

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .ps {
    overflow: auto !important; } }

.navbar-content {
  position: relative; }

.ps__rail-y {
  z-index: 5; }

.ps .ps__rail-x.ps--clicking,
.ps .ps__rail-x:focus,
.ps .ps__rail-x:hover,
.ps .ps__rail-y.ps--clicking,
.ps .ps__rail-y:focus,
.ps .ps__rail-y:hover {
  background: transparent; }

.ps__thumb-y {
  background: #99a7cf; }

.ps__rail-y.ps--clicking .ps__thumb-y,
.ps__rail-y:focus > .ps__thumb-y,
.ps__rail-y:hover > .ps__thumb-y {
  background: #aab6d8; }

.mrr-card {
  position: relative; }
  .mrr-card .bottom-chart {
    bottom: 0; }

.widget-last-task .img-radius {
  margin-right: 10px;
  margin-bottom: 5px; }

.widget-last-task span.float-right {
  font-size: 11px; }

.client-map img {
  vertical-align: top;
  width: 90px; }

.client-map .client-detail {
  margin-bottom: 20px; }
  .client-map .client-detail img {
    border-radius: 5px; }

.client-map .client-profile {
  float: left; }

.client-map .client-contain {
  display: inline-block;
  margin-left: 20px; }
  .client-map .client-contain h5 {
    display: block;
    text-transform: capitalize;
    font-weight: 600;
    font-size: 18px;
    padding-bottom: 10px; }

.client-map .client-card-box {
  border: 1px solid #f1f1f1;
  border-radius: 5px; }
  .client-map .client-card-box .client-border {
    border-right: 1px solid #f1f1f1; }
  .client-map .client-card-box .client-border-card {
    border-top: 1px solid #f1f1f1; }

.marketing-card .table thead th {
  border-bottom: none;
  border-top: none; }

.marketing-card .table tbody tr:first-child td {
  border-top: none; }

.marketing-card .table tbody .marketing-header {
  background-color: rgba(240, 242, 248, 0.5); }

.marketing-card .table tbody td {
  vertical-align: middle; }
  .marketing-card .table tbody td p {
    margin-bottom: 0;
    display: inline-block; }
  .marketing-card .table tbody td .table-contain {
    display: inline-block;
    margin-left: 10px; }
  .marketing-card .table tbody td span {
    font-weight: 600; }
  .marketing-card .table tbody td h6 {
    margin-bottom: 0;
    font-size: 0.9rem;
    font-weight: 600;
    line-height: 1.5; }
  .marketing-card .table tbody td .dropdown-toggle {
    background-color: transparent;
    border: none;
    box-shadow: none;
    padding: 0; }
    .marketing-card .table tbody td .dropdown-toggle i {
      margin-right: 0; }
    .marketing-card .table tbody td .dropdown-toggle:after {
      display: none; }
  .marketing-card .table tbody td .social-icon {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    color: #fff;
    font-size: 20px;
    top: -10px;
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center; }
  .marketing-card .table tbody td .btn-action {
    position: relative;
    padding: 6px 18px 6px 32px; }
    .marketing-card .table tbody td .btn-action:after {
      content: "";
      position: absolute;
      border-radius: 50%;
      width: 10px;
      height: 10px;
      left: 12px;
      top: 10px; }
    .marketing-card .table tbody td .btn-action.btn-bg-primary:after {
      background-color: #7267EF; }
    .marketing-card .table tbody td .btn-action.btn-bg-danger:after {
      background-color: #EA4D4D; }
    .marketing-card .table tbody td .btn-action.btn-bg-success:after {
      background-color: #17C666; }
    .marketing-card .table tbody td .btn-action.btn-bg-warning:after {
      background-color: #ffa21d; }
    .marketing-card .table tbody td .btn-action.btn-bg-info:after {
      background-color: #3ec9d6; }
    .marketing-card .table tbody td .btn-action.btn-bg-info:after {
      background-color: #3ec9d6; }

.marketing-card .marketing-card-footer .tab-cont > p {
  display: inline-block;
  vertical-align: middle;
  margin-left: 10px;
  font-weight: 600;
  margin-top: -8px; }
  .marketing-card .marketing-card-footer .tab-cont > p i {
    font-size: 20px;
    margin: 0 5px; }

.testimonial-card .progress {
  height: 5px; }

.testimonial-card .review-block .cust-img {
  width: 40px;
  height: 40px; }

.testimonial-card .review-block > div {
  padding-top: 15px;
  padding-bottom: 9px;
  margin-left: 0;
  margin-right: 0;
  padding-left: 5px;
  padding-right: 5px; }
  .testimonial-card .review-block > div:hover {
    background-color: rgba(114, 103, 239, 0.1); }

.new-cust-card img {
  width: 40px; }

.new-cust-card h6 {
  margin-bottom: 0; }

.new-cust-card .align-middle {
  position: relative; }
  .new-cust-card .align-middle .status {
    position: absolute;
    right: 0;
    top: 19px;
    font-size: 13px; }
    .new-cust-card .align-middle .status.active {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background: #17C666; }

.review-card .review-block .cust-img {
  width: 50px;
  height: 50px; }

.review-card .review-block > div {
  padding-top: 25px;
  padding-bottom: 25px;
  margin-left: 0;
  margin-right: 0;
  padding-left: 5px;
  padding-right: 5px; }
  .review-card .review-block > div:hover {
    background-color: rgba(114, 103, 239, 0.1); }

.latest-activity-card .card-body {
  padding-top: 0; }
  .latest-activity-card .card-body .latest-update-box {
    position: relative; }
    .latest-activity-card .card-body .latest-update-box .update-meta {
      z-index: 2;
      min-width: 170px; }
      .latest-activity-card .card-body .latest-update-box .update-meta .update-icon {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        text-align: center;
        padding: 12px 13px;
        font-size: 16px;
        color: #fff;
        margin-left: 10px; }
      @media only screen and (max-width: 575px) {
        .latest-activity-card .card-body .latest-update-box .update-meta {
          z-index: 2;
          min-width: 100%;
          text-align: left !important;
          margin-bottom: 15px;
          border-top: 1px solid #f1f1f1;
          padding-top: 15px; } }

.task-card .task-list {
  position: relative; }
  .task-card .task-list:after {
    content: "";
    position: absolute;
    background: #f1f1f1;
    height: 100%;
    width: 2px;
    top: 0;
    left: 10px;
    z-index: 1; }
  .task-card .task-list:before {
    content: "";
    position: absolute;
    background: #f1f1f1;
    height: 15px;
    width: 15px;
    bottom: -14px;
    left: 3px;
    z-index: 2;
    border-radius: 50%; }
  .task-card .task-list li {
    margin-bottom: 30px;
    padding-left: 30px;
    position: relative; }
    .task-card .task-list li .task-icon {
      position: absolute;
      left: 3px;
      top: 1px;
      border-radius: 50%;
      padding: 2px;
      color: #fff;
      min-width: 15px;
      min-height: 15px;
      z-index: 2; }

.latest-posts-card a {
  transition: all .3s ease-in-out; }
  .latest-posts-card a:hover h6 {
    color: #7267EF; }
  .latest-posts-card a ~ p {
    font-size: 11px; }

.latest-posts-card .latest-posts-img {
  width: 90px;
  height: 80px; }

.latest-update-card .card-body {
  padding-top: 0; }
  .latest-update-card .card-body .latest-update-box {
    position: relative; }
    .latest-update-card .card-body .latest-update-box:after {
      content: "";
      position: absolute;
      background: #ebebeb;
      height: 100%;
      width: 3px;
      top: 0;
      left: 82px;
      z-index: 1; }
    .latest-update-card .card-body .latest-update-box .update-meta {
      z-index: 2; }
      .latest-update-card .card-body .latest-update-box .update-meta .update-icon {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        text-align: center;
        padding: 7px 8px;
        font-size: 16px;
        color: #fff; }
        .latest-update-card .card-body .latest-update-box .update-meta .update-icon.ring {
          border-width: 3px;
          width: 15px;
          height: 15px;
          padding: 0;
          display: block;
          background: #fff; }
      .latest-update-card .card-body .latest-update-box .update-meta i.update-icon {
        margin-left: 4px;
        margin-right: 4px; }
      .latest-update-card .card-body .latest-update-box .update-meta img.update-icon {
        padding: 0;
        width: 40px;
        height: 40px; }

.chat-card .msg {
  margin-bottom: 5px;
  display: inline-block;
  padding: 10px;
  position: relative; }
  .chat-card .msg img {
    width: 60px;
    border-radius: 5px;
    margin-bottom: 5px;
    margin-top: 5px;
    margin-right: 10px; }

.chat-card .received-chat .msg {
  background: #f2f1fe;
  border-radius: 0 5px 5px 5px; }
  .chat-card .received-chat .msg:after {
    content: "";
    position: absolute;
    left: -7px;
    top: -7px;
    transform: rotate(45deg);
    border: 7px solid transparent;
    border-bottom-color: #f2f1fe; }

.chat-card .send-chat {
  text-align: right; }
  .chat-card .send-chat .msg {
    background: #7267EF;
    color: #fff;
    border-radius: 5px 0 5px 5px; }
    .chat-card .send-chat .msg:after {
      content: "";
      position: absolute;
      right: -7px;
      top: -7px;
      transform: rotate(45deg);
      border: 7px solid transparent;
      border-right-color: #7267EF; }

.user-Messages-card .text-time {
  font-size: 11px; }

.user-Messages-card .u-img {
  position: relative; }
  .user-Messages-card .u-img .profile-img {
    width: 40px;
    height: 40px; }
  .user-Messages-card .u-img .tot-msg {
    width: 15px;
    height: 15px;
    position: absolute;
    bottom: 0;
    right: -5px;
    background-color: #EA4D4D;
    color: #fff;
    font-size: 10px;
    border-radius: 50%;
    text-align: center; }

.user-activity-card .u-img {
  position: relative; }
  .user-activity-card .u-img .cover-img {
    width: 40px;
    height: 40px; }
  .user-activity-card .u-img .profile-img {
    width: 20px;
    height: 20px;
    position: absolute;
    bottom: 0;
    right: -5px; }

.product-progress-card .progress {
  height: 6px; }

.product-progress-card .pp-cont {
  padding-left: 15px;
  padding-right: 15px;
  position: relative; }
  .product-progress-card .pp-cont:after {
    content: "";
    background: #d2d2d2;
    width: 1px;
    height: 100%;
    position: absolute;
    top: 0;
    left: -15px; }
  @media only screen and (max-width: 991px) {
    .product-progress-card .pp-cont {
      padding: 15px 0; }
      .product-progress-card .pp-cont:after {
        display: none; } }

.product-progress-card .pp-main > div:first-child > .pp-cont:after {
  display: none; }

.social-res-card .progress {
  height: 6px;
  border-radius: 4px; }
  .social-res-card .progress .progress-bar {
    border-radius: 4px; }

.analytic-card .analytic-icon {
  width: 50px;
  height: 50px;
  text-align: center;
  padding: 17px 0;
  border-radius: 50%;
  background-color: #fff; }

.ticket-card .lbl-card {
  border-radius: 50px;
  padding: 5px 15px;
  display: inline-block; }

.proj-t-card {
  position: relative;
  overflow: hidden; }
  .proj-t-card .pt-badge {
    margin-bottom: 0;
    display: inline-block;
    padding: 60px 50px 20px 20px;
    border-radius: 50%;
    position: absolute;
    top: -45px;
    right: -35px;
    transition: all 0.3s ease-in-out; }

.proj-progress-card .progress {
  height: 6px;
  overflow: visible;
  margin-bottom: 10px; }

@media only screen and (max-width: 992px) {
  .proj-progress-card h6 {
    margin-top: 15px; }
  .proj-progress-card .progress {
    margin-bottom: 20px; } }

.prod-p-card i {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  text-align: center;
  padding: 11px 0;
  font-size: 24px; }

.comp-card i {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  text-align: center;
  padding: 17px 0;
  font-size: 18px; }

.social-widget-card {
  border-top: none;
  color: #fff; }
  .social-widget-card:hover i {
    opacity: 1;
    transform: scale(1.1); }
  .social-widget-card i {
    position: absolute;
    right: 10px;
    bottom: 10px;
    font-size: 48px;
    opacity: 0.4;
    transition: all ease-in 0.3s; }

.widget-primary-card.flat-card, .flat-card.widget-purple-card {
  border-top: none;
  background-color: #7267EF;
  color: #fff; }
  .widget-primary-card.flat-card h4, .flat-card.widget-purple-card h4,
  .widget-primary-card.flat-card h6,
  .flat-card.widget-purple-card h6 {
    color: #fff; }
  .widget-primary-card.flat-card .row-table:first-child, .flat-card.widget-purple-card .row-table:first-child {
    border-bottom: none; }
  .widget-primary-card.flat-card .row-table > [class*=col-], .flat-card.widget-purple-card .row-table > [class*=col-] {
    display: inline-block;
    vertical-align: middle; }
    .widget-primary-card.flat-card .row-table > [class*=col-]:first-child, .flat-card.widget-purple-card .row-table > [class*=col-]:first-child {
      background-color: #5d50ed;
      text-align: center; }
    .widget-primary-card.flat-card .row-table > [class*=col-] .row, .flat-card.widget-purple-card .row-table > [class*=col-] .row {
      display: flex;
      align-items: center; }

.widget-purple-card.flat-card {
  background-color: #17C666; }
  .widget-purple-card.flat-card .row-table:first-child {
    border-bottom: none; }
  .widget-purple-card.flat-card .row-table > [class*=col-]:first-child {
    background-color: #14af5a; }

.flat-card .row-table {
  display: table;
  align-items: center;
  table-layout: fixed;
  height: 100%;
  width: 100%;
  margin: 0; }
  .flat-card .row-table:first-child {
    border-bottom: 1px solid #f1f1f1; }
  .flat-card .row-table .br {
    border-right: 1px solid #f1f1f1; }
  .flat-card .row-table i {
    font-size: 28px; }
  .flat-card .row-table span {
    text-transform: uppercase;
    font-size: 12px; }
  .flat-card .row-table h5 {
    display: block;
    margin-bottom: 0.3em;
    margin-right: 0; }
  .flat-card .row-table > [class*=col-] {
    display: table-cell;
    float: none;
    table-layout: fixed;
    vertical-align: middle; }
    .flat-card .row-table > [class*=col-] .row {
      display: flex;
      align-items: center; }

.flat-card[class*="widget-"] .row-table {
  display: flex; }

.balance-card .progress {
  width: 100%;
  height: 8px;
  background-color: #f0f2f8;
  border-radius: 30px; }
  .balance-card .progress .progress-bar {
    border-radius: 30px; }

.card-progress .progress {
  height: 8px;
  width: 100%;
  position: relative;
  top: 8px;
  left: 0;
  border-radius: 0;
  overflow: inherit; }

.feed-card h6 {
  margin-top: 7px;
  font-size: 14px;
  transition: all 0.3s ease-in-out; }
  .feed-card h6 > span {
    font-size: 11px; }
  .feed-card h6:hover {
    color: #7267EF; }

.feed-card .feed-icon {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  text-align: center;
  padding: 8px 9px; }

.feed-card .card-body {
  position: relative;
  padding-left: 15px; }
  .feed-card .card-body .border-feed {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    transition: all 0.3s ease-in-out; }
    .feed-card .card-body .border-feed i {
      position: absolute;
      top: calc(50% - 20px);
      left: 0;
      right: 0;
      text-align: center;
      color: #fff;
      transition: all 0.3s ease-in-out; }

.feed-card:hover .border-feed.bg-primary {
  background-color: rgba(114, 103, 239, 0.3) !important; }
  .feed-card:hover .border-feed.bg-primary i {
    color: #7267EF; }

.feed-card:hover .border-feed.bg-danger {
  background-color: rgba(234, 77, 77, 0.3) !important; }
  .feed-card:hover .border-feed.bg-danger i {
    color: #EA4D4D; }

.feed-card:hover .border-feed.bg-success {
  background-color: rgba(23, 198, 102, 0.3) !important; }
  .feed-card:hover .border-feed.bg-success i {
    color: #17C666; }

.feed-card:hover .border-feed.bg-warning {
  background-color: rgba(255, 162, 29, 0.3) !important; }
  .feed-card:hover .border-feed.bg-warning i {
    color: #ffa21d; }

.total-request-card .progress {
  width: 85%;
  float: left;
  height: 5px;
  text-align: center;
  margin: 0 auto;
  background-color: #f0f2f8;
  border-radius: 30px; }
  .total-request-card .progress .progress-bar {
    border-radius: 30px; }

.total-request-card hr {
  width: 50px;
  border-top: 1px solid #f1f1f1;
  margin-top: 20px;
  margin-bottom: 20px; }

.total-request-card i {
  position: relative;
  top: 5px; }

.table-card .card-body {
  padding-left: 0;
  padding-right: 0;
  padding-top: 0; }
  .table-card .card-body .table > thead > tr > th {
    border-top: 0; }
  .table-card .card-body .table tr td:first-child,
  .table-card .card-body .table tr th:first-child {
    padding-left: 25px; }
  .table-card .card-body .table tr td:last-child,
  .table-card .card-body .table tr th:last-child {
    padding-right: 25px; }
  .table-card .card-body .table.without-header tr:first-child td {
    border-top: none; }

.widget-statstic-card {
  position: relative;
  overflow: hidden; }
  .widget-statstic-card .st-icon {
    color: #fff;
    padding: 40px 40px 20px 20px;
    border-radius: 50%;
    position: absolute;
    top: -30px;
    right: -30px;
    box-shadow: 0 2px 6px -1px rgba(0, 0, 0, 0.1); }
    .widget-statstic-card .st-icon > svg {
      transition: all 0.3s ease-in-out;
      width: 23px;
      height: 23px; }
  .widget-statstic-card h2 {
    font-weight: 600;
    display: inline-block; }
  .widget-statstic-card span {
    border-radius: 30px;
    padding: 5px 20px;
    color: #fff;
    font-weight: 600; }
  .widget-statstic-card:hover .st-icon > svg {
    width: 50px;
    height: 50px; }

.order-card {
  color: #fff;
  overflow: hidden; }
  .order-card .card-icon {
    position: absolute;
    right: -17px;
    top: 20px;
    font-size: 100px;
    opacity: 0.5; }
    .order-card .card-icon > svg {
      width: 100px;
      height: 100px; }

.statustic-card .card-body {
  position: relative; }
  .statustic-card .card-body .progress {
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;
    height: 5px;
    overflow: visible; }
    .statustic-card .card-body .progress .progress-bar {
      position: relative;
      overflow: visible;
      box-shadow: none; }
      .statustic-card .card-body .progress .progress-bar:before {
        content: "";
        height: 5px;
        width: 5px;
        border-radius: 50%;
        position: absolute;
        right: 0;
        background: inherit; }
      .statustic-card .card-body .progress .progress-bar.bg-primary:before {
        animation: blue-blink-bar 0.5s linear infinite; }
      .statustic-card .card-body .progress .progress-bar.bg-success:before {
        animation: green-blink-bar 0.5s linear infinite; }
      .statustic-card .card-body .progress .progress-bar.bg-danger:before {
        animation: red-blink-bar 0.5s linear infinite; }
      .statustic-card .card-body .progress .progress-bar.bg-warning:before {
        animation: yellow-blink-bar 0.5s linear infinite; }

@keyframes blue-blink-bar {
  0% {
    box-shadow: 0 0 0 0 rgba(114, 103, 239, 0.1); }
  50% {
    box-shadow: 0 0 0 6px rgba(114, 103, 239, 0.3); } }

@keyframes green-blink-bar {
  0% {
    box-shadow: 0 0 0 0 rgba(23, 198, 102, 0.1); }
  50% {
    box-shadow: 0 0 0 6px rgba(23, 198, 102, 0.3); } }

@keyframes red-blink-bar {
  0% {
    box-shadow: 0 0 0 0 rgba(234, 77, 77, 0.1); }
  50% {
    box-shadow: 0 0 0 6px rgba(234, 77, 77, 0.3); } }

@keyframes yellow-blink-bar {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 162, 29, 0.1); }
  50% {
    box-shadow: 0 0 0 6px rgba(255, 162, 29, 0.3); } }

.social-card {
  color: #fff;
  overflow: hidden; }
  .social-card .social-icon {
    background-color: #fff;
    border-radius: 5px;
    text-align: center;
    padding: 22px 23px; }
  .social-card .download-icon {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.5);
    position: absolute;
    height: 125px;
    width: 125px;
    right: -125px;
    padding: 47px 22px;
    border-radius: 50%;
    transition: all 0.3s ease-in-out; }
    .social-card .download-icon > svg {
      width: 35px;
      height: 35px; }
  .social-card:hover .download-icon {
    right: -60px; }

.order-visitor-card {
  cursor: pointer; }
  .order-visitor-card:hover {
    background-color: #7267EF; }
    .order-visitor-card:hover h4,
    .order-visitor-card:hover h6,
    .order-visitor-card:hover p {
      color: #fff; }

.widget-visitor-card {
  overflow: hidden;
  padding: 10px 0; }
  .widget-visitor-card i,
  .widget-visitor-card svg {
    font-size: 80px;
    color: #fff;
    width: 80px;
    height: 80px;
    position: absolute;
    bottom: -10px;
    opacity: 0.3;
    left: -10px;
    transform: rotate(15deg);
    transition: all 0.3s ease-in-out; }
  .widget-visitor-card:hover svg {
    transform: rotate(0deg) scale(1.4);
    opacity: 0.5; }

.app-design .btn {
  padding: 5px 8px;
  font-size: 12px;
  font-weight: 600;
  border-radius: 5px; }

.app-design .team-section img {
  width: 35px;
  border-radius: 5px; }

.app-design .progress-box p {
  margin-bottom: 0; }

.app-design .progress-box .progress {
  width: calc(100% - 80px);
  height: 8px;
  text-align: center;
  margin: 0 auto;
  background-color: #E5E5E5;
  border-radius: 30px;
  position: relative;
  overflow: inherit; }
  .app-design .progress-box .progress .progress-bar {
    border-radius: 30px; }
    .app-design .progress-box .progress .progress-bar label {
      position: absolute;
      top: -24px;
      right: 0;
      font-weight: 600;
      font-size: 13px; }

.user-card-full {
  overflow: hidden; }
  .user-card-full .user-profile-side {
    border-radius: 5px 0 0 5px; }
  .user-card-full .social-link li {
    display: inline-block; }
    .user-card-full .social-link li a {
      color: #293240;
      margin: 0 10px 0 0;
      transition: all 0.3s ease-in-out; }
      .user-card-full .social-link li a svg {
        width: 20px;
        height: 20px; }

.img-radius {
  border-radius: 50%; }

.quater-card .progress {
  height: 4px; }

.statustic-progress-card .progress {
  height: 5px; }

.trafic-card .progress {
  height: 7px; }
  .trafic-card .progress.blue {
    background-color: rgba(114, 103, 239, 0.5); }
  .trafic-card .progress.green {
    background-color: rgba(23, 198, 102, 0.5); }
  .trafic-card .progress.red {
    background-color: rgba(234, 77, 77, 0.5); }
  .trafic-card .progress.purple {
    background-color: rgba(62, 201, 214, 0.5); }
  .trafic-card .progress.yellow {
    background-color: rgba(255, 162, 29, 0.5); }

.total-card {
  overflow: hidden; }
  .total-card .text-left h4 {
    color: #fff;
    font-weight: 400;
    margin-bottom: 5px; }
  .total-card .text-left p {
    color: #fff;
    font-size: 15px; }

.table-card .row-table {
  display: flex;
  align-items: center;
  table-layout: fixed;
  height: 100%;
  width: 100%;
  margin: 0; }
  .table-card .row-table svg {
    margin: 0 20px; }
  .table-card .row-table > [class*=col-] {
    display: table-cell;
    float: none;
    table-layout: fixed;
    vertical-align: middle; }
    .table-card .row-table > [class*=col-] .row {
      display: flex;
      align-items: center; }

.rides-bar .rides-icon {
  background: #7267EF;
  border-radius: 50px;
  padding: 15px; }

.visitor .img-female {
  position: absolute;
  left: 10px;
  bottom: 0; }

.visitor .img-men {
  position: absolute;
  right: 10px;
  bottom: 0; }

.profit-bar .mon-icon {
  padding: 20px;
  border-radius: 50px;
  display: inline-block;
  float: right; }
  .profit-bar .mon-icon svg {
    width: 25px;
    height: 25px; }

.user-card .label {
  border-radius: 15px;
  padding: 5px 10px;
  margin-right: 10px;
  margin-bottom: 0;
  box-shadow: 0 2px 6px -1px rgba(0, 0, 0, 0.1); }

.leads-progress .progress,
.progress-gender .progress {
  justify-content: center;
  height: 10px;
  border-radius: 0; }

.Active-visitor .progress {
  height: 7px; }

.Active-visitor .card-active > div + div {
  border-left: 1px solid #f1f1f1; }

@media screen and (max-width: 767px) {
  .Active-visitor .card-active [class*=col-]:last-child {
    border-left: 0;
    margin-top: 20px; } }

.card-Revenue .rev-icon {
  background: rgba(255, 255, 255, 0.2);
  width: 70px;
  height: 70px;
  border-radius: 50%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease-in-out; }

.Invoice-bar .invoice-lable label {
  background: #fff;
  border-radius: 15px;
  padding: 4px 20px; }

.Invoice-bar .inv-icon {
  background: rgba(255, 255, 255, 0.2);
  width: 70px;
  height: 70px;
  border-radius: 50%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease-in-out; }

.Invoice-bar .progress {
  background: rgba(255, 255, 255, 0.22);
  border-radius: 0;
  height: 7px;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1); }

.location-sale .card-icon {
  width: 70px;
  height: 70px;
  background: #fff;
  border-radius: 50%;
  position: absolute;
  top: -20px;
  text-align: center;
  right: 0;
  left: 0;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center; }
  @media screen and (max-width: 768px) {
    .location-sale .card-icon {
      display: none; } }

.card-Impression .imp-icon {
  width: 52px;
  height: 52px;
  border-radius: 5px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 2px 6px -1px rgba(0, 0, 0, 0.1); }

.card-customer .hct-icon {
  width: 70px;
  height: 70px;
  justify-content: center;
  align-items: center;
  display: flex;
  border-radius: 5px;
  box-shadow: 0 2px 6px -1px rgba(0, 0, 0, 0.1); }

@media screen and (max-width: 375px) {
  .card-customer .hct-icon {
    width: 80px;
    height: 80px;
    font-size: 50px; } }

.ticket-customer .tick-icon {
  position: absolute;
  opacity: 0.2;
  right: 32px;
  bottom: 30px; }

.customer-visitor .vis-icon {
  opacity: 0.2;
  width: 120px;
  height: 120px;
  position: absolute;
  bottom: 0;
  left: 0; }

.card-social:hover i {
  font-size: 40px;
  transition: all 0.3s ease-in-out; }

.card-social .progress {
  height: 6px; }

.card-social .card-active > div + div {
  border-left: 1px solid #f1f1f1; }

.Online-Order .progress,
.statistial-visit .progress {
  height: 6px; }

.affilate-offers .card-icon {
  opacity: 0.5; }

.affilate-offers .label {
  border-radius: 15px;
  margin: 0;
  padding: 8px 18px; }

.team-leader .slide li {
  width: 10px;
  height: 10px;
  background-color: #e8e7fc;
  margin: 0 6px;
  border-radius: 50px; }

.team-leader .slide .carousel-indicators {
  margin-bottom: -10px;
  margin-top: 10px; }
  .team-leader .slide .carousel-indicators .active {
    background-color: #7267EF; }

.earning-date .bd-example {
  padding: 0;
  margin: 0;
  border: 0; }
  .earning-date .bd-example .nav-pills {
    background: transparent;
    padding: 0;
    box-shadow: none; }
    .earning-date .bd-example .nav-pills .nav-link {
      min-width: auto;
      padding: 6px 20px;
      color: #fff; }
    .earning-date .bd-example .nav-pills .nav-link.active {
      position: relative;
      background: #fff;
      box-shadow: none;
      color: #7267EF; }
      .earning-date .bd-example .nav-pills .nav-link.active:after {
        content: "";
        position: absolute;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-bottom: 5px solid #fff;
        top: -5px;
        left: 0;
        right: 0;
        margin: 0 auto;
        width: 11px; }
  .earning-date .bd-example .tab-content {
    background: transparent;
    padding: 0;
    box-shadow: none; }

.sale-view .sale-icon {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2); }

.project-task svg {
  opacity: 0.5; }

.project-task .label {
  border-radius: 15px;
  margin: 0;
  padding: 6px 16px; }

.project-task .progress {
  height: 6px; }

.summary-box .card-icon {
  opacity: 0.4; }

.card-event .label {
  border-radius: 15px;
  margin: 0;
  padding: 5px 16px; }

.card-event svg {
  position: absolute;
  bottom: 20px;
  right: 25px; }

.statistial-visit .label {
  padding: 4px 10px;
  border-radius: 40px; }

.Design-sprint .design-image,
.dashboard-kit .design-image {
  padding: 0;
  margin: 30px 0 0;
  list-style: none; }

.Design-sprint li,
.dashboard-kit li {
  display: inline-block;
  text-align: center;
  margin-right: 3px; }

.Design-sprint .btn,
.dashboard-kit .btn {
  border-radius: 50px;
  border: 0;
  width: 40px;
  height: 40px;
  padding: 0;
  margin: 0; }

.Design-sprint i,
.dashboard-kit i {
  opacity: 0.3; }

.post-emoticon ul {
  padding: 0;
  margin: 25px 0 0;
  list-style: none; }

.post-emoticon li {
  display: inline-block; }

.ux-designer {
  position: relative;
  padding: 35px 30px; }
  .ux-designer .btn {
    border-radius: 50px;
    border: 0;
    width: 50px;
    height: 50px;
    position: absolute;
    right: 20px;
    top: -20px;
    padding: 0;
    margin: 0; }

.visa-top .visa img {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0; }

.loction-user .row {
  padding: 35px 30px; }

.loction-user svg {
  opacity: 0.5; }

.loction-user .loction-progress {
  padding: 35px 30px; }
  .loction-user .loction-progress .progress {
    justify-content: center;
    height: 10px;
    border-radius: 0; }

.task-list {
  list-style: none;
  position: relative;
  margin: 0;
  padding: 30px 0 0; }
  .task-list:after {
    content: "";
    position: absolute;
    background: #ecedef;
    height: 100%;
    width: 2px;
    top: 0;
    left: 30px;
    z-index: 1; }
  .task-list li {
    margin-bottom: 30px;
    padding-left: 55px;
    position: relative; }
    .task-list li:last-child {
      margin-bottom: 0; }
    .task-list li .task-icon {
      position: absolute;
      left: 22px;
      top: 13px;
      border-radius: 50%;
      padding: 2px;
      width: 17px;
      height: 17px;
      z-index: 2;
      box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2); }

.project-main svg {
  opacity: 0.4; }

.Application-list .table td,
.code-table .table td {
  vertical-align: middle; }

.Application-list .table .label,
.code-table .table .label {
  padding: 4px 10px;
  border-radius: 15px; }

.Application-list thead th,
.code-table thead th {
  border: 0; }

.chat-sanders .card-header {
  background: linear-gradient(-135deg, #3f4d67 0%, #3f4d67 100%); }

.chat-sanders .msg {
  margin-bottom: 5px;
  display: inline-block;
  padding: 15px;
  position: relative; }

.chat-sanders .scroll-wrapper {
  height: 305px; }

.chat-sanders .received-chat h5 {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  text-align: center; }

.chat-sanders .received-chat .msg {
  background: #fff;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1); }
  .chat-sanders .received-chat .msg:after {
    content: "";
    position: absolute;
    left: -12px;
    bottom: 18px;
    transform: rotate(270deg);
    border: 6px solid transparent;
    border-bottom-color: #fff; }

.chat-sanders .send-chat h5 {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  text-align: center; }

.chat-sanders .send-chat .msg {
  background: #7267EF;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1); }
  .chat-sanders .send-chat .msg:after {
    content: "";
    position: absolute;
    right: -11px;
    bottom: 18px;
    transform: rotate(450deg);
    border: 6px solid transparent;
    border-bottom-color: #7267EF; }

.note-bar .friendlist-box {
  padding: 25px 35px;
  border-top: 1px solid #f1f1f1; }
  .note-bar .friendlist-box:first-child {
    border: 0; }
  .note-bar .friendlist-box h6 {
    display: inline-block; }
  .note-bar .friendlist-box svg {
    opacity: 0.2;
    color: #293240; }

.widget-content .widget-lorem .media h5 {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  text-align: center; }

.social-media .progress {
  background: transparent;
  border-radius: 0;
  justify-content: center; }
  .social-media .progress h5 {
    position: relative;
    top: -2px; }

.avtar {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-size: 18px;
  font-weight: 600;
  width: 48px;
  height: 48px; }
  .avtar.avtar-xs {
    width: 32px;
    height: 32px;
    font-size: 12px; }
  .avtar.avtar-s {
    width: 40px;
    height: 40px;
    font-size: 14px; }
  .avtar.avtar-l {
    width: 64px;
    height: 64px;
    font-size: 20px; }
  .avtar.avtar-xl {
    width: 80px;
    height: 80px;
    font-size: 24px; }
  .avtar.avtar-square {
    border-radius: 4px;
    border: 2px solid; }
  .avtar.avtar-icon {
    border: 2px solid #f1f1f1; }
    .avtar.avtar-icon > img {
      width: 60%; }

.badge-light-primary {
  background: #e8e7fc;
  color: #7267EF;
  border-color: #e8e7fc; }

.badge-light-secondary {
  background: #e7e9ea;
  color: #6c757d;
  border-color: #e7e9ea; }

.badge-light-success {
  background: #daf6e7;
  color: #17C666;
  border-color: #daf6e7; }

.badge-light-info {
  background: #e0f6f8;
  color: #3ec9d6;
  border-color: #e0f6f8; }

.badge-light-warning {
  background: #fff0db;
  color: #ffa21d;
  border-color: #fff0db; }

.badge-light-danger {
  background: #fce3e3;
  color: #EA4D4D;
  border-color: #fce3e3; }

.badge-light-light {
  background: #fefefe;
  color: #f8f9fa;
  border-color: #fefefe; }

.badge-light-dark {
  background: #dbdcde;
  color: #1c232f;
  border-color: #dbdcde; }

.btn i {
  font-size: 18px; }

.btn svg {
  width: 18px;
  height: 18px; }

.btn[class*="btn-light-"] {
  box-shadow: none; }

.btn[class*="btn-outline-"]:not(:hover) {
  box-shadow: none; }

.btn.btn-shadow {
  box-shadow: 0 6px 7px -1px rgba(80, 86, 175, 0.3); }

.btn.btn-sm i, .btn-group-sm > .btn i {
  font-size: 14px; }

.btn-light-primary {
  background: #e8e7fc;
  color: #7267EF;
  border-color: #e8e7fc; }
  .btn-light-primary:hover {
    background: #7267EF;
    color: #fff;
    border-color: #7267EF; }
  .btn-light-primary.focus, .btn-light-primary:focus {
    background: #7267EF;
    color: #fff;
    border-color: #7267EF; }
  .btn-light-primary:not(:disabled):not(.disabled).active, .btn-light-primary:not(:disabled):not(.disabled):active,
  .show > .btn-light-primary.dropdown-toggle {
    background: #7267EF;
    color: #fff;
    border-color: #7267EF; }

.btn-light-secondary {
  background: #e7e9ea;
  color: #6c757d;
  border-color: #e7e9ea; }
  .btn-light-secondary:hover {
    background: #6c757d;
    color: #fff;
    border-color: #6c757d; }
  .btn-light-secondary.focus, .btn-light-secondary:focus {
    background: #6c757d;
    color: #fff;
    border-color: #6c757d; }
  .btn-light-secondary:not(:disabled):not(.disabled).active, .btn-light-secondary:not(:disabled):not(.disabled):active,
  .show > .btn-light-secondary.dropdown-toggle {
    background: #6c757d;
    color: #fff;
    border-color: #6c757d; }

.btn-light-success {
  background: #daf6e7;
  color: #17C666;
  border-color: #daf6e7; }
  .btn-light-success:hover {
    background: #17C666;
    color: #fff;
    border-color: #17C666; }
  .btn-light-success.focus, .btn-light-success:focus {
    background: #17C666;
    color: #fff;
    border-color: #17C666; }
  .btn-light-success:not(:disabled):not(.disabled).active, .btn-light-success:not(:disabled):not(.disabled):active,
  .show > .btn-light-success.dropdown-toggle {
    background: #17C666;
    color: #fff;
    border-color: #17C666; }

.btn-light-info {
  background: #e0f6f8;
  color: #3ec9d6;
  border-color: #e0f6f8; }
  .btn-light-info:hover {
    background: #3ec9d6;
    color: #fff;
    border-color: #3ec9d6; }
  .btn-light-info.focus, .btn-light-info:focus {
    background: #3ec9d6;
    color: #fff;
    border-color: #3ec9d6; }
  .btn-light-info:not(:disabled):not(.disabled).active, .btn-light-info:not(:disabled):not(.disabled):active,
  .show > .btn-light-info.dropdown-toggle {
    background: #3ec9d6;
    color: #fff;
    border-color: #3ec9d6; }

.btn-light-warning {
  background: #fff0db;
  color: #ffa21d;
  border-color: #fff0db; }
  .btn-light-warning:hover {
    background: #ffa21d;
    color: #fff;
    border-color: #ffa21d; }
  .btn-light-warning.focus, .btn-light-warning:focus {
    background: #ffa21d;
    color: #fff;
    border-color: #ffa21d; }
  .btn-light-warning:not(:disabled):not(.disabled).active, .btn-light-warning:not(:disabled):not(.disabled):active,
  .show > .btn-light-warning.dropdown-toggle {
    background: #ffa21d;
    color: #fff;
    border-color: #ffa21d; }

.btn-light-danger {
  background: #fce3e3;
  color: #EA4D4D;
  border-color: #fce3e3; }
  .btn-light-danger:hover {
    background: #EA4D4D;
    color: #fff;
    border-color: #EA4D4D; }
  .btn-light-danger.focus, .btn-light-danger:focus {
    background: #EA4D4D;
    color: #fff;
    border-color: #EA4D4D; }
  .btn-light-danger:not(:disabled):not(.disabled).active, .btn-light-danger:not(:disabled):not(.disabled):active,
  .show > .btn-light-danger.dropdown-toggle {
    background: #EA4D4D;
    color: #fff;
    border-color: #EA4D4D; }

.btn-light-light {
  background: #fefefe;
  color: #f8f9fa;
  border-color: #fefefe; }
  .btn-light-light:hover {
    background: #f8f9fa;
    color: #fff;
    border-color: #f8f9fa; }
  .btn-light-light.focus, .btn-light-light:focus {
    background: #f8f9fa;
    color: #fff;
    border-color: #f8f9fa; }
  .btn-light-light:not(:disabled):not(.disabled).active, .btn-light-light:not(:disabled):not(.disabled):active,
  .show > .btn-light-light.dropdown-toggle {
    background: #f8f9fa;
    color: #fff;
    border-color: #f8f9fa; }

.btn-light-dark {
  background: #dbdcde;
  color: #1c232f;
  border-color: #dbdcde; }
  .btn-light-dark:hover {
    background: #1c232f;
    color: #fff;
    border-color: #1c232f; }
  .btn-light-dark.focus, .btn-light-dark:focus {
    background: #1c232f;
    color: #fff;
    border-color: #1c232f; }
  .btn-light-dark:not(:disabled):not(.disabled).active, .btn-light-dark:not(:disabled):not(.disabled):active,
  .show > .btn-light-dark.dropdown-toggle {
    background: #1c232f;
    color: #fff;
    border-color: #1c232f; }

.dropdown-toggle.arrow-none:after {
  display: none; }

.dropdown-menu {
  padding: 15px 0;
  box-shadow: 0 4px 24px 0 rgba(62, 57, 107, 0.18);
  border: none;
  border-radius: 2px; }

.pc-header .dropdown-menu {
  animation: 0.4s ease-in-out 0s normal forwards 1 fadein; }

@keyframes fadein {
  from {
    transform: translate3d(0, 8px, 0);
    opacity: 0; }
  to {
    transform: translate3d(0, 0, 0);
    opacity: 1; } }

.dropdown .dropdown-item {
  padding: 10px 25px; }
  .dropdown .dropdown-item i {
    font-size: 18px;
    margin-right: 10px; }
  .dropdown .dropdown-item svg {
    width: 18px;
    height: 18px;
    margin-right: 10px;
    fill: #f2f2f2; }

.card {
  box-shadow: 0 2px 6px -1px rgba(0, 0, 0, 0.1);
  margin-bottom: 30px;
  transition: box-shadow 0.2s ease-in-out; }
  .card .card-header {
    border-bottom: 1px solid #f1f1f1; }
    .card .card-header h5 {
      margin-bottom: 0;
      color: #9b9ea2;
      font-size: 14px;
      font-weight: 600; }
      .card .card-header h5 + p,
      .card .card-header h5 + small {
        margin-top: 10px; }
        .card .card-header h5 + p:last-child,
        .card .card-header h5 + small:last-child {
          margin-bottom: 0; }
    .card .card-header .card-header-right {
      right: 10px;
      top: 10px;
      display: inline-block;
      float: right;
      padding: 0;
      position: absolute; }
      @media only screen and (max-width: 575px) {
        .card .card-header .card-header-right {
          display: none; } }
      .card .card-header .card-header-right .dropdown-menu {
        margin-top: 0; }
        .card .card-header .card-header-right .dropdown-menu li {
          cursor: pointer; }
          .card .card-header .card-header-right .dropdown-menu li a {
            font-size: 14px;
            text-transform: capitalize; }
      .card .card-header .card-header-right .btn.dropdown-toggle {
        border: none;
        background: transparent;
        box-shadow: none;
        color: #888; }
        .card .card-header .card-header-right .btn.dropdown-toggle i {
          margin-right: 0; }
        .card .card-header .card-header-right .btn.dropdown-toggle:after {
          display: none; }
        .card .card-header .card-header-right .btn.dropdown-toggle:focus {
          box-shadow: none;
          outline: none; }
      .card .card-header .card-header-right .btn.dropdown-toggle {
        border: none;
        background: transparent;
        box-shadow: none;
        padding: 0;
        width: 20px;
        height: 20px;
        right: 8px;
        top: 8px; }
        .card .card-header .card-header-right .btn.dropdown-toggle.mobile-menu span {
          background-color: #888;
          height: 2px;
          border-radius: 5px; }
          .card .card-header .card-header-right .btn.dropdown-toggle.mobile-menu span:after, .card .card-header .card-header-right .btn.dropdown-toggle.mobile-menu span:before {
            border-radius: 5px;
            height: 2px;
            background-color: #888; }
      .card .card-header .card-header-right .nav-pills {
        padding: 0;
        box-shadow: none;
        background: transparent; }
  .card .card-footer {
    transition: box-shadow 0.2s ease-in-out;
    border-top: 1px solid #f1f1f1; }
  .card:hover .card-footer[class*="bg-"] {
    box-shadow: none; }
  .card.bg-primary {
    box-shadow: 0 9px 9px -1px rgba(114, 103, 239, 0.3); }
  .card.bg-secondary {
    box-shadow: 0 9px 9px -1px rgba(108, 117, 125, 0.3); }
  .card.bg-success {
    box-shadow: 0 9px 9px -1px rgba(23, 198, 102, 0.3); }
  .card.bg-info {
    box-shadow: 0 9px 9px -1px rgba(62, 201, 214, 0.3); }
  .card.bg-warning {
    box-shadow: 0 9px 9px -1px rgba(255, 162, 29, 0.3); }
  .card.bg-danger {
    box-shadow: 0 9px 9px -1px rgba(234, 77, 77, 0.3); }
  .card.bg-light {
    box-shadow: 0 9px 9px -1px rgba(248, 249, 250, 0.3); }
  .card.bg-dark {
    box-shadow: 0 9px 9px -1px rgba(28, 35, 47, 0.3); }

@media (max-width: 767.98px) {
  .card {
    margin-bottom: 20px; } }

.progress {
  overflow: visible; }
  .progress .progress-bar.bg-primary {
    box-shadow: 0 8px 8px -1px rgba(114, 103, 239, 0.3); }
  .progress .progress-bar.bg-secondary {
    box-shadow: 0 8px 8px -1px rgba(108, 117, 125, 0.3); }
  .progress .progress-bar.bg-success {
    box-shadow: 0 8px 8px -1px rgba(23, 198, 102, 0.3); }
  .progress .progress-bar.bg-info {
    box-shadow: 0 8px 8px -1px rgba(62, 201, 214, 0.3); }
  .progress .progress-bar.bg-warning {
    box-shadow: 0 8px 8px -1px rgba(255, 162, 29, 0.3); }
  .progress .progress-bar.bg-danger {
    box-shadow: 0 8px 8px -1px rgba(234, 77, 77, 0.3); }
  .progress .progress-bar.bg-light {
    box-shadow: 0 8px 8px -1px rgba(248, 249, 250, 0.3); }
  .progress .progress-bar.bg-dark {
    box-shadow: 0 8px 8px -1px rgba(28, 35, 47, 0.3); }

.table.table-align-center td,
.table.table-align-center th {
  vertical-align: middle; }

.table thead th {
  padding: 0.9rem 0.75rem; }

.table td,
.table th {
  vertical-align: middle; }

.table-hover tbody tr:hover {
  background-color: rgba(114, 103, 239, 0.03); }

.tabs-border.nav-tabs .nav-item {
  margin-bottom: 0; }

.tabs-border.nav-tabs .nav-link {
  border: none;
  background: no-repeat center bottom, center 100%;
  background-size: 0 100%, 100% 100%;
  transition: background 0.3s ease-out;
  background-image: linear-gradient(to top, #7267EF 2px, rgba(255, 255, 255, 0) 2px); }
  .tabs-border.nav-tabs .nav-link.active {
    background-size: 100% 100%, 100% 100%; }

.tabs-light.nav-pill + .tab-content {
  border-top: 1px solid #f1f1f1; }

.tabs-light.nav-pill .nav-item {
  margin-bottom: 0; }
  .tabs-light.nav-pill .nav-item .nav-link {
    color: #7267EF;
    background: #e8e7fc;
    border-radius: 4px;
    transition: background 0.3s ease-out; }
  .tabs-light.nav-pill .nav-item + .nav-item {
    margin-left: 10px; }

.tabs-light.nav-pill .nav-link {
  border: none; }
  .tabs-light.nav-pill .nav-link.active {
    color: #fff;
    background: #7267EF; }

.form-group label {
  font-weight: 500; }

.input-group-text svg {
  width: 18px;
  height: 18px; }

.form-control[type="file"] {
  padding: 0.42rem 1rem; }

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-right: none; }

.form-v1 .form-label-group {
  position: relative;
  margin-bottom: 1rem; }

.form-v1 .form-label-group > input,
.form-v1 .form-label-group > label {
  height: 3.125rem;
  padding: .75rem; }

.form-v1 .form-label-group > label {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  color: #495057;
  pointer-events: none;
  cursor: text;
  /* Match the input under the label */
  border: 1px solid transparent;
  border-radius: .25rem;
  transition: all .1s ease-in-out; }

.form-v1 .form-label-group input::-webkit-input-placeholder {
  color: transparent; }

.form-v1 .form-label-group input::-ms-input-placeholder {
  color: transparent; }

.form-v1 .form-label-group input::-moz-placeholder {
  color: transparent; }

.form-v1 .form-label-group input::placeholder {
  color: transparent; }

.form-v1 .form-label-group input:not(:-moz-placeholder-shown) {
  padding-top: 1.25rem;
  padding-bottom: .25rem; }

.form-v1 .form-label-group input:not(:placeholder-shown) {
  padding-top: 1.25rem;
  padding-bottom: .25rem; }

.form-v1 .form-label-group input:not(:-moz-placeholder-shown) ~ label {
  padding-top: .25rem;
  padding-bottom: .25rem;
  font-size: 12px;
  color: #777; }

.form-v1 .form-label-group input:not(:placeholder-shown) ~ label {
  padding-top: .25rem;
  padding-bottom: .25rem;
  font-size: 12px;
  color: #777; }

.form-v2 .form-group {
  position: relative; }
  .form-v2 .form-group.fill .floating-label {
    opacity: 0.6;
    font-size: 85%; }

.form-v2 .floating-label {
  position: absolute;
  top: 6px;
  left: 16px;
  transition: all .3s ease-in-out; }

.form-v2 .form-control {
  height: auto;
  padding-top: 30px; }

.datepicker {
  width: 265px;
  padding: 10px; }
  .datepicker.dropdown-menu {
    padding: 10px; }
  .datepicker.datepicker-orient-top {
    margin-top: 8px; }
  .datepicker table {
    width: 100%; }
  .datepicker td,
  .datepicker th {
    font-weight: regular;
    width: 35px;
    height: 35px; }
  .datepicker thead th {
    color: #060606; }
    .datepicker thead th.datepicker-switch, .datepicker thead th.next, .datepicker thead th.prev {
      font-weight: 500;
      color: #293240; }
      .datepicker thead th.datepicker-switch i, .datepicker thead th.next i, .datepicker thead th.prev i {
        font-size: 1.2rem;
        color: #293240; }
        .datepicker thead th.datepicker-switch i:before, .datepicker thead th.next i:before, .datepicker thead th.prev i:before {
          line-height: 0;
          vertical-align: middle; }
      .datepicker thead th.datepicker-switch:hover, .datepicker thead th.next:hover, .datepicker thead th.prev:hover {
        background: #f0f2f8 !important; }
    .datepicker thead th.dow {
      color: #293240;
      font-weight: 500; }
  .datepicker tbody tr > td.day {
    color: #293240; }
    .datepicker tbody tr > td.day:hover {
      background: #f0f2f8;
      color: #293240; }
    .datepicker tbody tr > td.day.old {
      color: #293240; }
    .datepicker tbody tr > td.day.new {
      color: #293240; }
    .datepicker tbody tr > td.day.active, .datepicker tbody tr > td.day.active:hover, .datepicker tbody tr > td.day.selected, .datepicker tbody tr > td.day.selected:hover {
      background: #7267EF;
      color: #fff; }
    .datepicker tbody tr > td.day.today {
      position: relative;
      background: #7267EF !important;
      color: #fff !important; }
      .datepicker tbody tr > td.day.today:before {
        content: '';
        display: inline-block;
        border: solid transparent;
        border-width: 0 0 7px 7px;
        border-bottom-color: #ffffff;
        border-top-color: #293240;
        position: absolute;
        bottom: 4px;
        right: 4px; }
    .datepicker tbody tr > td.day.range {
      background: #060606; }
  .datepicker tbody tr > td span.hour,
  .datepicker tbody tr > td span.minute,
  .datepicker tbody tr > td span.month,
  .datepicker tbody tr > td span.year {
    color: #293240; }
    .datepicker tbody tr > td span.hour:hover,
    .datepicker tbody tr > td span.minute:hover,
    .datepicker tbody tr > td span.month:hover,
    .datepicker tbody tr > td span.year:hover {
      background: #f0f2f8; }
    .datepicker tbody tr > td span.hour.active, .datepicker tbody tr > td span.hour.active.focused:hover, .datepicker tbody tr > td span.hour.active:hover, .datepicker tbody tr > td span.hour.focused, .datepicker tbody tr > td span.hour.focused:hover,
    .datepicker tbody tr > td span.minute.active,
    .datepicker tbody tr > td span.minute.active.focused:hover,
    .datepicker tbody tr > td span.minute.active:hover,
    .datepicker tbody tr > td span.minute.focused,
    .datepicker tbody tr > td span.minute.focused:hover,
    .datepicker tbody tr > td span.month.active,
    .datepicker tbody tr > td span.month.active.focused:hover,
    .datepicker tbody tr > td span.month.active:hover,
    .datepicker tbody tr > td span.month.focused,
    .datepicker tbody tr > td span.month.focused:hover,
    .datepicker tbody tr > td span.year.active,
    .datepicker tbody tr > td span.year.active.focused:hover,
    .datepicker tbody tr > td span.year.active:hover,
    .datepicker tbody tr > td span.year.focused,
    .datepicker tbody tr > td span.year.focused:hover {
      background: #7267EF;
      color: #fff; }
  .datepicker tfoot tr > th.clear, .datepicker tfoot tr > th.today {
    font-weight: 500; }
    .datepicker tfoot tr > th.clear:hover, .datepicker tfoot tr > th.today:hover {
      background: #f0f2f8; }
  .datepicker.datepicker-inline {
    border: 1px solid #f1f1f1; }

.bootstrap-timepicker-widget {
  left: auto;
  width: 175px; }
  .bootstrap-timepicker-widget .bootstrap-timepicker-hour,
  .bootstrap-timepicker-widget .bootstrap-timepicker-meridian,
  .bootstrap-timepicker-widget .bootstrap-timepicker-minute,
  .bootstrap-timepicker-widget .bootstrap-timepicker-second {
    border: none;
    box-shadow: none; }
  .bootstrap-timepicker-widget table td > a {
    border: 0; }
    .bootstrap-timepicker-widget table td > a i {
      font-size: 1.1rem; }

.bootstrap-timepicker-widget .bootstrap-timepicker-hour,
.bootstrap-timepicker-widget .bootstrap-timepicker-meridian,
.bootstrap-timepicker-widget .bootstrap-timepicker-minute,
.bootstrap-timepicker-widget .bootstrap-timepicker-second {
  color: #060606; }

.bootstrap-timepicker-widget table td > a:hover {
  background: #f0f2f8; }

.tagify__tag {
  --tag-bg: #f0f2f8;
  --tag-hover: rgba(114, 103, 239, 0.3);
  --tag-remove-bg: rgba(234, 77, 77, 0.3);
  --tag-remove-btn-bg--hover: #EA4D4D; }
  .tagify__tag.tagify__tag--primary {
    --tag-bg: rgba(114, 103, 239, 0.2); }
    .tagify__tag.tagify__tag--primary div .tagify__tag-text {
      color: #7267EF; }
  .tagify__tag.tagify__tag--secondary {
    --tag-bg: rgba(108, 117, 125, 0.2); }
    .tagify__tag.tagify__tag--secondary div .tagify__tag-text {
      color: #6c757d; }
  .tagify__tag.tagify__tag--success {
    --tag-bg: rgba(23, 198, 102, 0.2); }
    .tagify__tag.tagify__tag--success div .tagify__tag-text {
      color: #17C666; }
  .tagify__tag.tagify__tag--info {
    --tag-bg: rgba(62, 201, 214, 0.2); }
    .tagify__tag.tagify__tag--info div .tagify__tag-text {
      color: #3ec9d6; }
  .tagify__tag.tagify__tag--warning {
    --tag-bg: rgba(255, 162, 29, 0.2); }
    .tagify__tag.tagify__tag--warning div .tagify__tag-text {
      color: #ffa21d; }
  .tagify__tag.tagify__tag--danger {
    --tag-bg: rgba(234, 77, 77, 0.2); }
    .tagify__tag.tagify__tag--danger div .tagify__tag-text {
      color: #EA4D4D; }
  .tagify__tag.tagify__tag--light {
    --tag-bg: rgba(248, 249, 250, 0.2); }
    .tagify__tag.tagify__tag--light div .tagify__tag-text {
      color: #f8f9fa; }
  .tagify__tag.tagify__tag--dark {
    --tag-bg: rgba(28, 35, 47, 0.2); }
    .tagify__tag.tagify__tag--dark div .tagify__tag-text {
      color: #1c232f; }

.bootstrap-switch {
  border-color: #f1f1f1; }
  .bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-default,
  .bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-default {
    background: #f0f2f8; }
  .bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-primary,
  .bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-primary {
    color: #fff;
    background: #7267EF; }
  .bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-secondary,
  .bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-secondary {
    color: #fff;
    background: #6c757d; }
  .bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-success,
  .bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-success {
    color: #fff;
    background: #17C666; }
  .bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-info,
  .bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-info {
    color: #fff;
    background: #3ec9d6; }
  .bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-warning,
  .bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-warning {
    color: #fff;
    background: #ffa21d; }
  .bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-danger,
  .bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-danger {
    color: #fff;
    background: #EA4D4D; }
  .bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-light,
  .bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-light {
    color: #fff;
    background: #f8f9fa; }
  .bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-dark,
  .bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-dark {
    color: #fff;
    background: #1c232f; }

.bootstrap-select > .dropdown-toggle.bs-placeholder.btn[class*="btn-"]:not(.btn-light) {
  color: #fff; }

.typeahead .twitter-typeahead {
  display: block !important; }

.tt-menu {
  padding: 10px 0;
  font-size: 0.875rem;
  background: #fff;
  min-width: 10rem;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px; }
  .tt-menu .tt-suggestion {
    padding: 4px 20px;
    border-radius: 2px; }
    .tt-menu .tt-suggestion.active, .tt-menu .tt-suggestion:active, .tt-menu .tt-suggestion:focus, .tt-menu .tt-suggestion:hover {
      background: rgba(114, 103, 239, 0.03);
      color: #7267EF; }

.sticky-action .card-header {
  background: #fff;
  z-index: 1; }

.sticky-action.sticky .card-header {
  top: calc(60px + 55px);
  position: fixed;
  box-shadow: 0 1px 15px 1px rgba(57, 82, 107, 0.08);
  left: calc(280px + 25px);
  right: 25px; }

.dropzone {
  min-height: auto; }

.dropzone {
  padding: 20px;
  text-align: center;
  cursor: pointer;
  border: 2px dashed #f1f1f1;
  border-radius: 4px; }
  .dropzone .dropzone-msg-title {
    color: #293240;
    margin: 0 0 5px;
    padding: 0;
    font-weight: 500;
    font-size: 1.2rem; }
  .dropzone .dropzone-msg-desc {
    color: #293240;
    font-weight: 400;
    font-size: 1rem; }
  .dropzone .dz-preview .dz-image {
    border-radius: 4px; }
  .dropzone.dropzone-primary {
    border-color: #7267EF; }
  .dropzone.dropzone-secondary {
    border-color: #6c757d; }
  .dropzone.dropzone-success {
    border-color: #17C666; }
  .dropzone.dropzone-info {
    border-color: #3ec9d6; }
  .dropzone.dropzone-warning {
    border-color: #ffa21d; }
  .dropzone.dropzone-danger {
    border-color: #EA4D4D; }
  .dropzone.dropzone-light {
    border-color: #f8f9fa; }
  .dropzone.dropzone-dark {
    border-color: #1c232f; }

.dz-started .dropzone-msg {
  display: none; }

.dropzone-multi {
  border: 0;
  padding: 0; }
  .dropzone-multi .dz-message {
    display: none; }
  .dropzone-multi .dropzone-panel .dropzone-remove-all,
  .dropzone-multi .dropzone-panel .dropzone-upload {
    display: none; }
  .dropzone-multi .dropzone-item {
    background: #f0f2f8;
    border-radius: 4px;
    margin: 8px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 14px; }
    .dropzone-multi .dropzone-item .dropzone-progress {
      width: 20%; }
      .dropzone-multi .dropzone-item .dropzone-progress .progress {
        height: 0.5rem;
        transition: all 0.2s ease-in-out; }
    .dropzone-multi .dropzone-item .dropzone-file .dropzone-filename {
      font-size: 0.9rem;
      font-weight: 500;
      color: #293240;
      text-overflow: ellipsis;
      margin-right: 0.5rem; }
      .dropzone-multi .dropzone-item .dropzone-file .dropzone-filename b {
        font-size: 0.9rem;
        font-weight: 500;
        color: #293240; }
    .dropzone-multi .dropzone-item .dropzone-file .dropzone-error {
      margin-top: 0.25rem;
      font-size: 0.9rem;
      font-weight: 400;
      color: #EA4D4D;
      text-overflow: ellipsis; }
    .dropzone-multi .dropzone-item .dropzone-toolbar {
      margin-left: 1rem;
      display: flex;
      flex-wrap: nowrap; }
      .dropzone-multi .dropzone-item .dropzone-toolbar .dropzone-cancel,
      .dropzone-multi .dropzone-item .dropzone-toolbar .dropzone-delete,
      .dropzone-multi .dropzone-item .dropzone-toolbar .dropzone-start {
        height: 25px;
        width: 25px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        cursor: pointer; }
        .dropzone-multi .dropzone-item .dropzone-toolbar .dropzone-cancel i,
        .dropzone-multi .dropzone-item .dropzone-toolbar .dropzone-delete i,
        .dropzone-multi .dropzone-item .dropzone-toolbar .dropzone-start i {
          font-size: 0.8rem;
          color: #060606; }
        .dropzone-multi .dropzone-item .dropzone-toolbar .dropzone-cancel:hover i,
        .dropzone-multi .dropzone-item .dropzone-toolbar .dropzone-delete:hover i,
        .dropzone-multi .dropzone-item .dropzone-toolbar .dropzone-start:hover i {
          color: #7267EF; }
      .dropzone-multi .dropzone-item .dropzone-toolbar .dropzone-start {
        transition: all 0.2s ease-in-out; }

.uppy-Dashboard--modal {
  z-index: 1030; }

.uppy-DragDrop--is-dragdrop-supported {
  border-color: #f0f2f8; }

.pc-uppy-thumbnail-container .pc-uppy-thumbnail img {
  width: 100px; }

.slider-selection {
  box-shadow: none; }

/**  =====================
      Authentication css start
==========================  **/
.auth-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  min-width: 100%;
  min-height: 100vh;
  background: #7267EF; }
  @media (min-width: 1200px) {
    .auth-wrapper .container {
      max-width: 1140px; } }
  @media (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .auth-wrapper {
      display: block;
      margin: 0 auto; }
      .auth-wrapper > .row {
        min-height: 100vh; }
        .auth-wrapper > .row > .aut-bg-img {
          min-height: 100vh; } }
  .auth-wrapper .saprator {
    position: relative;
    margin: 8px 0; }
    .auth-wrapper .saprator span {
      background: #fff;
      position: relative;
      padding: 0 10px;
      z-index: 5;
      font-size: 20px; }
    .auth-wrapper .saprator:after {
      content: "";
      position: absolute;
      top: 15px;
      left: 0;
      width: 100%;
      height: 1px;
      background: #f1f1f1;
      z-index: 1; }
  .auth-wrapper a,
  .auth-wrapper p > a {
    color: #1c232f;
    font-weight: 600; }
  .auth-wrapper .input-group {
    background: transparent; }
  .auth-wrapper .card {
    margin-bottom: 0;
    padding: 8px; }
    .auth-wrapper .card .card-body {
      padding: 20px 25px 20px 40px; }
    @media (-ms-high-contrast: none), (-ms-high-contrast: active) {
      .auth-wrapper .card {
        display: block; } }
  .auth-wrapper > div {
    z-index: 5; }
  .auth-wrapper .auth-content {
    position: relative;
    padding: 15px;
    z-index: 5; }
    .auth-wrapper .auth-content:not(.container) {
      width: 400px; }
      .auth-wrapper .auth-content:not(.container) .card-body {
        padding: 40px 35px; }
    @media (-ms-high-contrast: none), (-ms-high-contrast: active) {
      .auth-wrapper .auth-content {
        margin: 0 auto; } }
  .auth-wrapper.auth-v3 {
    background: linear-gradient(to right, #7267EF 50%, #fff 0); }
    .auth-wrapper.auth-v3 .auth-content {
      width: 800px; }
      .auth-wrapper.auth-v3 .auth-content .card {
        overflow: hidden;
        padding: 0;
        box-shadow: 0 5px 30px 1px rgba(0, 0, 0, 0.1); }
        .auth-wrapper.auth-v3 .auth-content .card .card-body {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          height: 100%; }
      .auth-wrapper.auth-v3 .auth-content .img-card-side {
        position: relative;
        padding: 0; }
        .auth-wrapper.auth-v3 .auth-content .img-card-side .img-card-side-content {
          position: absolute;
          top: 30px;
          left: 45px; }
    .auth-wrapper.auth-v3.lockscreen .auth-content {
      width: 340px; }
      .auth-wrapper.auth-v3.lockscreen .auth-content .card {
        overflow: visible; }
      .auth-wrapper.auth-v3.lockscreen .auth-content .card-body img {
        margin-top: -100px;
        margin-bottom: 30px;
        border-radius: 10px;
        box-shadow: 0 5px 30px 1px rgba(0, 0, 0, 0.1); }
    @media only screen and (max-width: 767px) {
      .auth-wrapper.auth-v3 .img-card-side {
        display: none; } }
  .auth-wrapper .auth-side-img {
    padding-right: 400px;
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    justify-content: center; }
    @media only screen and (max-width: 991px) {
      .auth-wrapper .auth-side-img {
        display: none; } }
  .auth-wrapper .auth-side-form {
    position: relative;
    min-height: 100%;
    background: #fff;
    display: inline-flex;
    align-items: center;
    left: auto;
    float: right; }
    .auth-wrapper .auth-side-form > * {
      position: relative;
      z-index: 5; }
    @media only screen and (max-width: 991px) {
      .auth-wrapper .auth-side-form {
        width: 100%;
        justify-content: center; }
        .auth-wrapper .auth-side-form .auth-content:not(.container) {
          max-width: 350px; } }
  @media (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .auth-wrapper.aut-bg-img .auth-side-form {
      height: 100vh; } }
  @media only screen and (max-width: 767px) {
    .auth-wrapper .card .card-body {
      padding: 30px 15px; } }
  .auth-wrapper.offline {
    background-image: none; }
    .auth-wrapper.offline h1 {
      font-size: 87px;
      font-weight: 700; }
    .auth-wrapper.offline:before {
      display: none; }
  .auth-wrapper .card-body .carousel-indicators {
    margin: 15px 0 10px;
    bottom: 0; }
    .auth-wrapper .card-body .carousel-indicators li {
      width: 50px;
      background-color: rgba(114, 103, 239, 0.4);
      border-radius: 5px;
      height: 4px; }
      .auth-wrapper .card-body .carousel-indicators li.active {
        background-color: #7267EF; }
  .auth-wrapper .img-logo-overlay {
    position: absolute;
    top: 40px;
    left: 50px; }

/* image varient start */
.aut-bg-img {
  background-image: url("../images/auth/img-auth-big.jpg");
  background-size: cover;
  background-attachment: fixed;
  background-position: center; }
  .aut-bg-img .auth-content {
    padding: 70px 40px; }

/* image varient End */
/**====== Authentication css end ======**/
/* ===================== to do page start ======================================= */
#task-container ul {
  overflow: hidden;
  padding: 0; }

#task-container li {
  float: left;
  width: 49%;
  overflow: auto;
  height: auto;
  min-height: 10px;
  background: #fff;
  display: inline-block;
  padding: 20px;
  border: 1px solid #ccc;
  color: #666;
  border-top: 5px solid #7267EF;
  cursor: pointer;
  margin-bottom: 20px;
  margin-right: 2%;
  transition: all 0.3s;
  position: relative; }
  #task-container li:nth-child(2n) {
    margin-right: 0; }

#task-container li.complete {
  opacity: 1;
  border-top: 9px solid #EA4D4D;
  transition: all ease-in 0.3s; }
  #task-container li.complete:before {
    background: url("../images/complete.png") no-repeat;
    position: absolute;
    top: 5px;
    right: 5px;
    content: "";
    width: 55px;
    height: 55px;
    background-size: 100%; }
  #task-container li.complete p {
    text-decoration: line-through; }

@media screen and (max-width: 580px) {
  #task-container li {
    width: 100%; } }

.new-task .to-do-list .cr {
  float: left;
  top: 0;
  margin-right: 16px; }

.new-task label input[type=checkbox] {
  display: none; }

.check-task .custom-control-label {
  cursor: pointer;
  transition: all 0.3s ease-in-out; }
  .check-task .custom-control-label:hover {
    color: #7267EF; }

.checkbox-fade .cr {
  border-radius: 6px;
  border: 1px solid #e3e3e3;
  cursor: pointer;
  display: inline-block;
  float: left;
  height: 24px;
  margin-right: 0.5em;
  position: relative;
  width: 24px;
  margin-right: 16px;
  top: 0; }

.task-panel .to-do-label {
  margin-bottom: 15px; }
  .task-panel .to-do-label:last-child {
    border-bottom: none;
    margin-bottom: 0;
    padding: 0; }

.done-task .captions,
.done-task span {
  color: #919aa3;
  text-decoration: line-through; }

.to-do-list i {
  color: #ccc;
  font-size: 17px;
  opacity: 0; }

.to-do-list:hover i {
  opacity: 1;
  transition: opacity ease-in 0.3s; }

.to-do-list h6 {
  display: inline-block; }

.to-do-list .done-task {
  opacity: 0.7; }
  .to-do-list .done-task > div {
    filter: grayscale(0.8); }
  .to-do-list .done-task h6,
  .to-do-list .done-task p,
  .to-do-list .done-task span {
    text-decoration: line-through; }

.to-do-list .checkbox-fade .check-task {
  display: block; }

.to-do-list .checkbox-fade .to-content {
  display: inline-block; }

.to-do-list .checkbox-fade .cr {
  border-radius: 6px;
  border: 1px solid #e3e3e3;
  cursor: pointer;
  display: inline-block;
  float: left;
  height: 24px;
  margin-right: 0.5em;
  position: relative;
  width: 24px;
  margin: 0;
  top: 5px; }
  .to-do-list .checkbox-fade .cr .cr-icon {
    color: #fff;
    font-size: 0.8em;
    left: 0;
    line-height: 0;
    position: absolute;
    right: 0;
    text-align: center;
    top: 50%; }

.to-do-list .checkbox-fade label input[type=checkbox] {
  display: none; }

.to-do-list .checkbox-fade label input[type=checkbox] + .cr > .cr-icon {
  transform: scale(3) rotateZ(-20deg);
  opacity: 0;
  transition: all 0.3s ease-in; }

.to-do-list .checkbox-fade label input[type=checkbox]:checked + .cr > .cr-icon {
  transform: scale(1) rotateZ(0deg);
  opacity: 1; }

.to-do-list .checkbox-fade label input[type=checkbox]:checked + .cr {
  background: #7267EF;
  border: 0; }

new-task .to-do-list .cr {
  float: left;
  top: 0;
  margin-right: 16px; }

new-task label input[type=checkbox] {
  display: none; }

/* ===================== to do page end =========================================*/
/* ===================== Gallery-masonry page start ============================= */
.gallery-masonry .card-columns {
  column-count: 4; }

@media screen and (max-width: 1400px) {
  .gallery-masonry .card-columns {
    column-count: 3; } }

@media screen and (max-width: 992px) {
  .gallery-masonry .card-columns {
    column-count: 2; } }

@media screen and (max-width: 575px) {
  .gallery-masonry .card-columns {
    column-count: 1; } }

.job-meta-data i {
  margin-right: 5px;
  color: #7267EF; }

/* ===================== Gallery-masonry page end ============================= */
/* ===================== Task-list page start ============================= */
.task-data img {
  width: 40px;
  box-shadow: none; }

.task-data i {
  color: #1c232f; }

.task-data .dropdown-toggle:after {
  color: #1c232f; }

.task-board-left .task-right-header-revision,
.task-board-left .task-right-header-status,
.task-board-left .task-right-header-users {
  padding-bottom: 10px;
  padding-top: 10px;
  border-bottom: 1px solid #f1f1f1;
  cursor: pointer; }

.task-board-left .taskboard-right-progress,
.task-board-left .taskboard-right-revision,
.task-board-left .taskboard-right-users {
  padding-top: 10px; }

.task-board-left .taskboard-right-progress .progress {
  height: 9px;
  margin-bottom: 25px; }

.task-board-left .user-box .media-object {
  height: 50px;
  width: 50px;
  cursor: pointer; }

.task-board-left .user-box .media-left {
  position: relative; }

.task-board-left .user-box .btn.btn-icon {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  font-size: 15px;
  display: flex;
  justify-content: center;
  align-items: center; }

.task-board-left .live-status {
  height: 8px;
  width: 8px;
  position: absolute;
  bottom: 0;
  right: 0;
  border-radius: 100%;
  top: 5px; }

.filter-bar .navbar {
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 1px 20px 0 rgba(69, 90, 100, 0.08);
  padding: 0.5rem 1rem; }
  @media screen and (max-width: 557px) {
    .filter-bar .navbar .f-text {
      display: block;
      width: 100%; }
    .filter-bar .navbar .f-view {
      padding-left: 24px; }
      .filter-bar .navbar .f-view span {
        display: block;
        width: 100%;
        margin-bottom: 10px; } }

.filter-bar .label {
  border-radius: 4px;
  font-size: 13px;
  padding: 3px 7px; }

.filter-bar .task-detail {
  margin-bottom: 5px; }

.filter-bar .card-task .task-list-table {
  display: inline-block; }
  .filter-bar .card-task .task-list-table img {
    width: 40px;
    box-shadow: none; }
  .filter-bar .card-task .task-list-table i {
    color: #1c232f; }

.filter-bar .card-task .task-board {
  float: right;
  margin-top: 5px; }
  .filter-bar .card-task .task-board .dropdown {
    display: inline-block; }
  .filter-bar .card-task .task-board .btn {
    padding: 4px 10px;
    font-size: 10px;
    margin: 0; }

.assign-user img,
.task-comment img {
  width: 45px;
  box-shadow: none; }

/* ===================== Task-list page end ===================================== */
/* ===================== Invoice page start ===================================== */
.invoice-contact {
  display: flex;
  margin-bottom: 30px;
  padding-top: 30px;
  align-items: center; }
  @media screen and (max-width: 767px) {
    .invoice-contact {
      margin-bottom: 0; } }

.invoice-table.table td {
  border: 0;
  padding: 4px 0; }

.invoive-info {
  display: flex;
  margin-bottom: 30px; }
  .invoive-info h6 {
    margin-bottom: 20px;
    text-transform: uppercase; }
  .invoive-info .invoice-order.table {
    padding-left: 0; }
    .invoive-info .invoice-order.table th {
      border: 0;
      padding: 4px 0; }
      .invoive-info .invoice-order.table th:first-child {
        padding-left: 0;
        width: 80px; }

.invoice-total.table {
  background: #f3f3f3;
  padding: 30px 0; }
  .invoice-total.table th {
    border: 0;
    padding: 4px 0;
    text-align: right; }
  .invoice-total.table td {
    text-align: right; }
  .invoice-total.table tbody {
    padding-right: 20px;
    float: right; }

.invoice-summary .label {
  border-radius: 5px;
  padding: 3px 10px;
  font-size: 12px; }

.invoice-list .btn {
  padding: 5px 10px;
  font-size: 12px; }

.invoice-list .task-list-table {
  display: inline-block; }

/* ===================== Invoice page end ======================================= */
/* ==============================================================
                     email-page  Start
====================================================== */
.email-card .tab-content {
  box-shadow: none; }
  .email-card .tab-content .btn {
    border: none; }
    .email-card .tab-content .btn i {
      font-size: 18px;
      line-height: 1.4; }
  .email-card .tab-content .table tr {
    transition: all 0.12s ease-in-out; }
    .email-card .tab-content .table tr td,
    .email-card .tab-content .table tr th {
      padding: 0.65rem 0.75rem; }
      .email-card .tab-content .table tr td .checkbox,
      .email-card .tab-content .table tr th .checkbox {
        padding: 12px 0; }
      .email-card .tab-content .table tr td label,
      .email-card .tab-content .table tr th label {
        margin-bottom: 0; }
    .email-card .tab-content .table tr.unread {
      font-weight: 600; }
    .email-card .tab-content .table tr.read {
      background: rgba(240, 242, 248, 0.7); }
    .email-card .tab-content .table tr:hover {
      background: #f0f2f8;
      box-shadow: 0 1px 3px 0 #e2e5e8; }
  .email-card .tab-content .nav-pills {
    margin-left: 0;
    border-top: 1px solid #f1f1f1;
    padding: 15px 0 0; }
    .email-card .tab-content .nav-pills > li .nav-link {
      border-radius: 0;
      border: none;
      padding: 5px 12px 22px;
      position: relative; }
      .email-card .tab-content .nav-pills > li .nav-link::after {
        content: "";
        width: calc(100% - 24px);
        height: 3px;
        position: absolute;
        left: 12px;
        bottom: 0;
        border-radius: 3px 3px 0 0; }
      .email-card .tab-content .nav-pills > li .nav-link.active {
        background: transparent;
        color: #EA4D4D; }
        .email-card .tab-content .nav-pills > li .nav-link.active:after {
          background: #EA4D4D; }

.email-card .nav-pills {
  padding: 0;
  margin-left: -25px; }
  .email-card .nav-pills > li .nav-link {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 0.875rem;
    padding: 8px 25px;
    border-radius: 0 20px 20px 0;
    text-transform: capitalize;
    border: 1px solid transparent;
    border-left: none;
    color: #293240; }
    .email-card .nav-pills > li .nav-link i {
      padding: 0;
      margin-right: 10px;
      font-size: 16px;
      display: inline-block;
      position: relative;
      top: 2px; }
  .email-card .nav-pills > li .nav-link.active,
  .email-card .nav-pills > li .show > .nav-link {
    font-weight: 600;
    background: rgba(234, 77, 77, 0.1);
    color: #EA4D4D;
    border-color: rgba(234, 77, 77, 0.1); }

.email-card .email-more-link {
  font-size: 16px; }
  .email-card .email-more-link i {
    line-height: 1.1; }

.email-card .mail-body-content a {
  color: #293240; }

.email-card .mail-body-content .mail-attach {
  display: inline-block;
  border: 1px solid #f1f1f1;
  border-radius: 50px;
  padding: 5px 15px;
  margin-top: 10px; }

.email-card .email-btn .btn {
  border: none; }
  .email-card .email-btn .btn.dropdown-toggle::after {
    display: none; }

.email-card .email-mob-toggle {
  display: none; }

@media screen and (max-width: 767px) {
  .email-card .email-mob-toggle {
    display: inline-block; }
  .email-card .mail-left {
    position: fixed;
    top: 70px;
    left: -100vw;
    z-index: 1023;
    width: 100%;
    transition: all 0.1s ease-in-out;
    height: calc(100vh - 70px); }
    .email-card .mail-left .mail-l-content {
      width: 200px;
      height: 100%;
      overflow-y: auto;
      padding: 20px 15px 15px;
      box-shadow: 0 2px 6px -1px rgba(0, 0, 0, 0.1);
      background: #fff;
      position: relative;
      z-index: 5;
      left: -200px;
      transition: all 0.2s ease-in-out; }
    .email-card .mail-left #overlay-email-mob {
      position: fixed;
      width: 100%;
      top: 70px;
      left: 0;
      background: rgba(0, 0, 0, 0.3);
      z-index: 1;
      bottom: 0;
      opacity: 0;
      transition: all 0.2s ease-in-out; }
    .email-card .mail-left .nav-pills {
      margin-left: -15px; }
    .email-card .mail-left.mail-menu-active {
      left: 0;
      padding-left: 0; }
      .email-card .mail-left.mail-menu-active .mail-l-content {
        left: 0; }
      .email-card .mail-left.mail-menu-active #overlay-email-mob {
        opacity: 1; }
  .email-card .input-group {
    margin-top: 20px; } }

.email-content {
  border-bottom: 1px solid #f1f1f1;
  padding-bottom: 15px; }

.email-read {
  display: flex;
  align-items: flex-start; }

@media screen and (max-width: 575px) {
  .email-read {
    display: inline-block; }
    .email-read .photo-table {
      margin-bottom: 10px; }
  .email-contant {
    margin: 0 !important;
    padding: 0 !important; } }

/* ==================  email-page  End  =================== */
/* ==============================================================
                     image-cropper  Start
====================================================== */
.docs-buttons .btn {
  margin-bottom: 10px !important; }

.docs-data .input-group {
  background: transparent;
  margin-bottom: 10px; }

.docs-data > .input-group > label {
  min-width: 80px; }

.docs-data .input-group > span {
  min-width: 50px; }

.img-container {
  min-height: 200px;
  max-height: 516px;
  margin-bottom: 20px; }
  .img-container > img {
    max-width: 100%; }
  @media (min-width: 768px) {
    .img-container {
      min-height: 516px; } }

.docs-preview {
  margin-right: -15px; }
  .docs-preview .img-preview {
    float: left;
    margin-right: 10px;
    margin-bottom: 10px;
    overflow: hidden; }
    .docs-preview .img-preview > img {
      max-width: 100%; }
  .docs-preview .preview-lg {
    width: 100% !important; }
  .docs-preview .preview-md {
    width: 139px;
    height: 78px; }
  .docs-preview .preview-sm {
    width: 69px;
    height: 39px; }
  .docs-preview .preview-xs {
    width: 35px;
    height: 20px;
    margin-right: 0; }

.docs-buttons > .btn,
.docs-buttons > .btn-group,
.docs-buttons > .form-control {
  margin-right: 5px; }

.docs-toggles > .btn,
.docs-toggles > .btn-group,
.docs-toggles > .dropdown {
  margin-bottom: 10px; }

.docs-tooltip {
  display: block;
  margin: -6px -12px;
  padding: 6px 12px; }
  .docs-tooltip > .icon {
    margin: 0 -3px;
    vertical-align: top; }

.tooltip-inner {
  white-space: normal; }

.btn-upload .tooltip-inner {
  white-space: nowrap; }

@media (max-width: 400px) {
  .btn-group-crop {
    margin-right: -15px !important; }
    .btn-group-crop > .btn {
      padding-left: 5px;
      padding-right: 5px; }
    .btn-group-crop .docs-tooltip {
      margin-left: -5px;
      margin-right: -5px;
      padding-left: 5px;
      padding-right: 5px; } }

.docs-options .dropdown-menu {
  width: 100%; }
  .docs-options .dropdown-menu > li {
    padding: 3px 20px;
    font-size: 14px; }
    .docs-options .dropdown-menu > li:hover {
      background-color: #f7f7f7; }
    .docs-options .dropdown-menu > li > label {
      display: block; }

.docs-cropped .modal-body {
  text-align: center; }
  .docs-cropped .modal-body > img,
  .docs-cropped .modal-body > canvas {
    max-width: 100%; }

.card-body .docs-options .dropdown-menu {
  top: inherit; }

.hovering-link a.text-muted {
  transition: all 0.3s ease-in-out; }
  .hovering-link a.text-muted:hover {
    color: #7267EF !important; }

/* ==================  image-cropper end  ======================================= */
/**  =====================
      Icon layouts css start
==========================  **/
.i-main .i-block {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 70px;
  height: 70px;
  margin: 5px;
  border: 1px solid #f1f1f1;
  position: relative;
  cursor: pointer; }
  .i-main .i-block i {
    font-size: 30px; }
  .i-main .i-block label {
    margin-bottom: 0;
    display: none; }
  .i-main .i-block span.ic-badge {
    position: absolute;
    bottom: 0;
    right: 0; }
  .i-main .i-block .flag-icon-background {
    width: 40px;
    height: 40px; }

.sample-icon .i-block {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 70px;
  height: 70px;
  margin: 5px;
  border-radius: 50%;
  border: 1px solid #f1f1f1;
  position: relative;
  cursor: pointer; }
  .sample-icon .i-block i {
    font-size: 28px; }
  .sample-icon .i-block:nth-child(1) {
    border-color: #7267EF;
    background: rgba(114, 103, 239, 0.1); }
    .sample-icon .i-block:nth-child(1) i {
      color: #7267EF; }
  .sample-icon .i-block:nth-child(2) {
    border-color: #6610f2;
    background: rgba(102, 16, 242, 0.1); }
    .sample-icon .i-block:nth-child(2) i {
      color: #6610f2; }
  .sample-icon .i-block:nth-child(3) {
    border-color: #6f42c1;
    background: rgba(111, 66, 193, 0.1); }
    .sample-icon .i-block:nth-child(3) i {
      color: #6f42c1; }
  .sample-icon .i-block:nth-child(4) {
    border-color: #e83e8c;
    background: rgba(232, 62, 140, 0.1); }
    .sample-icon .i-block:nth-child(4) i {
      color: #e83e8c; }
  .sample-icon .i-block:nth-child(5) {
    border-color: #EA4D4D;
    background: rgba(234, 77, 77, 0.1); }
    .sample-icon .i-block:nth-child(5) i {
      color: #EA4D4D; }
  .sample-icon .i-block:nth-child(6) {
    border-color: #fd7e14;
    background: rgba(253, 126, 20, 0.1); }
    .sample-icon .i-block:nth-child(6) i {
      color: #fd7e14; }
  .sample-icon .i-block:nth-child(7) {
    border-color: #ffa21d;
    background: rgba(255, 162, 29, 0.1); }
    .sample-icon .i-block:nth-child(7) i {
      color: #ffa21d; }
  .sample-icon .i-block:nth-child(8) {
    border-color: #17C666;
    background: rgba(23, 198, 102, 0.1); }
    .sample-icon .i-block:nth-child(8) i {
      color: #17C666; }
  .sample-icon .i-block:nth-child(9) {
    border-color: #20c997;
    background: rgba(32, 201, 151, 0.1); }
    .sample-icon .i-block:nth-child(9) i {
      color: #20c997; }
  .sample-icon .i-block:nth-child(10) {
    border-color: #3ec9d6;
    background: rgba(62, 201, 214, 0.1); }
    .sample-icon .i-block:nth-child(10) i {
      color: #3ec9d6; }
  .sample-icon .i-block:nth-child(11) {
    border-color: #7267EF;
    background: rgba(114, 103, 239, 0.1); }
    .sample-icon .i-block:nth-child(11) i {
      color: #7267EF; }
  .sample-icon .i-block:nth-child(12) {
    border-color: #6610f2;
    background: rgba(102, 16, 242, 0.1); }
    .sample-icon .i-block:nth-child(12) i {
      color: #6610f2; }
  .sample-icon .i-block:nth-child(13) {
    border-color: #6f42c1;
    background: rgba(111, 66, 193, 0.1); }
    .sample-icon .i-block:nth-child(13) i {
      color: #6f42c1; }
  .sample-icon .i-block:nth-child(14) {
    border-color: #e83e8c;
    background: rgba(232, 62, 140, 0.1); }
    .sample-icon .i-block:nth-child(14) i {
      color: #e83e8c; }
  .sample-icon .i-block:nth-child(15) {
    border-color: #EA4D4D;
    background: rgba(234, 77, 77, 0.1); }
    .sample-icon .i-block:nth-child(15) i {
      color: #EA4D4D; }
  .sample-icon .i-block:nth-child(16) {
    border-color: #fd7e14;
    background: rgba(253, 126, 20, 0.1); }
    .sample-icon .i-block:nth-child(16) i {
      color: #fd7e14; }
  .sample-icon .i-block:nth-child(17) {
    border-color: #ffa21d;
    background: rgba(255, 162, 29, 0.1); }
    .sample-icon .i-block:nth-child(17) i {
      color: #ffa21d; }
  .sample-icon .i-block:nth-child(18) {
    border-color: #17C666;
    background: rgba(23, 198, 102, 0.1); }
    .sample-icon .i-block:nth-child(18) i {
      color: #17C666; }
  .sample-icon .i-block:nth-child(19) {
    border-color: #20c997;
    background: rgba(32, 201, 151, 0.1); }
    .sample-icon .i-block:nth-child(19) i {
      color: #20c997; }
  .sample-icon .i-block:nth-child(20) {
    border-color: #3ec9d6;
    background: rgba(62, 201, 214, 0.1); }
    .sample-icon .i-block:nth-child(20) i {
      color: #3ec9d6; }
  .sample-icon .i-block:nth-child(21) {
    border-color: #7267EF;
    background: rgba(114, 103, 239, 0.1); }
    .sample-icon .i-block:nth-child(21) i {
      color: #7267EF; }
  .sample-icon .i-block:nth-child(22) {
    border-color: #6610f2;
    background: rgba(102, 16, 242, 0.1); }
    .sample-icon .i-block:nth-child(22) i {
      color: #6610f2; }
  .sample-icon .i-block:nth-child(23) {
    border-color: #6f42c1;
    background: rgba(111, 66, 193, 0.1); }
    .sample-icon .i-block:nth-child(23) i {
      color: #6f42c1; }
  .sample-icon .i-block:nth-child(24) {
    border-color: #e83e8c;
    background: rgba(232, 62, 140, 0.1); }
    .sample-icon .i-block:nth-child(24) i {
      color: #e83e8c; }
  .sample-icon .i-block:nth-child(25) {
    border-color: #EA4D4D;
    background: rgba(234, 77, 77, 0.1); }
    .sample-icon .i-block:nth-child(25) i {
      color: #EA4D4D; }
  .sample-icon .i-block:nth-child(26) {
    border-color: #fd7e14;
    background: rgba(253, 126, 20, 0.1); }
    .sample-icon .i-block:nth-child(26) i {
      color: #fd7e14; }
  .sample-icon .i-block:nth-child(27) {
    border-color: #ffa21d;
    background: rgba(255, 162, 29, 0.1); }
    .sample-icon .i-block:nth-child(27) i {
      color: #ffa21d; }
  .sample-icon .i-block:nth-child(28) {
    border-color: #17C666;
    background: rgba(23, 198, 102, 0.1); }
    .sample-icon .i-block:nth-child(28) i {
      color: #17C666; }
  .sample-icon .i-block:nth-child(29) {
    border-color: #20c997;
    background: rgba(32, 201, 151, 0.1); }
    .sample-icon .i-block:nth-child(29) i {
      color: #20c997; }
  .sample-icon .i-block:nth-child(30) {
    border-color: #3ec9d6;
    background: rgba(62, 201, 214, 0.1); }
    .sample-icon .i-block:nth-child(30) i {
      color: #3ec9d6; }
  .sample-icon .i-block:nth-child(31) {
    border-color: #7267EF;
    background: rgba(114, 103, 239, 0.1); }
    .sample-icon .i-block:nth-child(31) i {
      color: #7267EF; }
  .sample-icon .i-block:nth-child(32) {
    border-color: #6610f2;
    background: rgba(102, 16, 242, 0.1); }
    .sample-icon .i-block:nth-child(32) i {
      color: #6610f2; }
  .sample-icon .i-block:nth-child(33) {
    border-color: #6f42c1;
    background: rgba(111, 66, 193, 0.1); }
    .sample-icon .i-block:nth-child(33) i {
      color: #6f42c1; }
  .sample-icon .i-block:nth-child(34) {
    border-color: #e83e8c;
    background: rgba(232, 62, 140, 0.1); }
    .sample-icon .i-block:nth-child(34) i {
      color: #e83e8c; }
  .sample-icon .i-block:nth-child(35) {
    border-color: #EA4D4D;
    background: rgba(234, 77, 77, 0.1); }
    .sample-icon .i-block:nth-child(35) i {
      color: #EA4D4D; }
  .sample-icon .i-block:nth-child(36) {
    border-color: #fd7e14;
    background: rgba(253, 126, 20, 0.1); }
    .sample-icon .i-block:nth-child(36) i {
      color: #fd7e14; }
  .sample-icon .i-block:nth-child(37) {
    border-color: #ffa21d;
    background: rgba(255, 162, 29, 0.1); }
    .sample-icon .i-block:nth-child(37) i {
      color: #ffa21d; }
  .sample-icon .i-block:nth-child(38) {
    border-color: #17C666;
    background: rgba(23, 198, 102, 0.1); }
    .sample-icon .i-block:nth-child(38) i {
      color: #17C666; }
  .sample-icon .i-block:nth-child(39) {
    border-color: #20c997;
    background: rgba(32, 201, 151, 0.1); }
    .sample-icon .i-block:nth-child(39) i {
      color: #20c997; }
  .sample-icon .i-block:nth-child(40) {
    border-color: #3ec9d6;
    background: rgba(62, 201, 214, 0.1); }
    .sample-icon .i-block:nth-child(40) i {
      color: #3ec9d6; }

/**====== Icon layouts css end ======**/
/**  =====================
      Data Tables css start
==========================  **/
.table td,
.table th {
  border-top: 1px solid #f1f1f1;
  white-space: nowrap;
  padding: 0.7rem 0.75rem; }

.table thead th {
  border-bottom: 1px solid #f1f1f1;
  font-size: 13px;
  color: #060606;
  background: #f0f2f8;
  text-transform: uppercase; }

.table tbody + tbody {
  border-top: 2px solid #f1f1f1; }

.table.table-dark thead th {
  background-color: #11171a; }

.table.dataTable[class*="table-"] thead th {
  background: #f0f2f8; }

/* Border versions */
.table-bordered {
  border: 1px solid #f1f1f1; }
  .table-bordered td,
  .table-bordered th {
    border: 1px solid #f1f1f1; }

/* Zebra-striping */
.table-striped tbody tr:nth-of-type(2n+1) {
  background-color: rgba(114, 103, 239, 0.03); }

/* Hover effect */
.table-hover tbody tr:hover {
  background-color: rgba(114, 103, 239, 0.03); }

/* Table backgrounds */
.table .thead-dark th {
  color: #fff;
  background-color: #1c232f;
  border-color: #090b0f; }

.table-dark {
  color: #fff;
  background-color: #1c232f; }
  .table-dark td,
  .table-dark th,
  .table-dark thead th {
    border-color: #090b0f; }
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: #181e29; }
  .table-dark.table-hover tbody tr:hover {
    background-color: #12171f; }
  .table-dark thead th {
    color: #fff; }

/* fixed header position */
table.dataTable.fixedHeader-floating {
  top: 0 !important; }
  @media screen and (max-width: 992px) {
    table.dataTable.fixedHeader-floating {
      display: none !important; } }

@media screen and (max-width: 992px) {
  .fixedHeader-locked {
    display: none !important; } }

/**  =====================
      Foo-table css start
==========================  **/
.footable .pagination > .active > a,
.footable .pagination > .active > span {
  background-color: #7267EF;
  border-color: #7267EF; }
  .footable .pagination > .active > a:focus, .footable .pagination > .active > a:hover,
  .footable .pagination > .active > span:focus,
  .footable .pagination > .active > span:hover {
    background-color: #7267EF;
    border-color: #7267EF; }

.footable .pagination > li > a,
.footable .pagination > li > span {
  color: #222; }

.footable-details.table,
.footable.table {
  margin-bottom: 0; }

table.footable > tfoot > tr.footable-paging > td > span.label {
  margin-bottom: 0; }

table.footable-paging-center > tfoot > tr.footable-paging > td {
  padding-bottom: 0; }

.table-columned > tbody > tr > td {
  border: 0;
  border-left: 1px solid #f1f1f1; }

.table-columned > tbody > tr > th {
  border: 0; }

/**====== Foo-table css end ======**/
/**======= basic table css start =======**/
.table.table-xl td,
.table.table-xl th {
  padding: 1.25rem 0.8rem; }

.table.table-lg td,
.table.table-lg th {
  padding: 0.9rem 0.8rem; }

.table.table-de td,
.table.table-de th {
  padding: 0.75rem 0.8rem; }

.table.table-sm td,
.table.table-sm th {
  padding: 0.6rem 0.8rem; }

.table.table-xs td,
.table.table-xs th {
  padding: 0.4rem 0.8rem; }

/**======= basic table css end ======**/
#row-delete .selected,
#row-select .selected {
  background-color: #7267EF;
  color: #fff; }

table.dataTable tbody > tr.selected,
table.dataTable tbody > tr > .selected {
  background: #7267EF; }
  table.dataTable tbody > tr.selected table.dataTable tbody > tr > .selected td.select-checkbox:after,
  table.dataTable tbody > tr.selected table.dataTable tbody > tr.selected.select-checkbox:after,
  table.dataTable tbody > tr.selected th.select-checkbox:after,
  table.dataTable tbody > tr > .selected table.dataTable tbody > tr > .selected td.select-checkbox:after,
  table.dataTable tbody > tr > .selected table.dataTable tbody > tr.selected.select-checkbox:after,
  table.dataTable tbody > tr > .selected th.select-checkbox:after {
    text-shadow: 1px 1px #e4f2fe, -1px -1px #e4f2fe, 1px -1px #e4f2fe, -1px 1px #e4f2fe; }

table.dataTable tbody .selected td.select-checkbox:before,
table.dataTable tbody .selected th.select-checkbox:before {
  border-color: #fff; }

table.dataTable tbody > tr > .selected td.select-checkbox:after,
table.dataTable tbody > tr > .selected th.select-checkbox:after,
table.dataTable tbody > tr.selected td.select-checkbox:after,
table.dataTable tbody > tr.selected th.select-checkbox:after {
  content: '\2714';
  margin-top: -11px;
  margin-left: -4px;
  text-align: center; }

div.dt-button-info {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 400px;
  margin-top: -100px;
  margin-left: -200px;
  background-color: #f2f1fe;
  color: #7267EF;
  border: 2px solid #7267EF;
  box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.3);
  border-radius: 3px;
  text-align: center;
  z-index: 21; }
  div.dt-button-info h2 {
    padding: 0.5em;
    margin: 0;
    font-weight: normal;
    color: #fff;
    border-bottom: 1px solid #7267EF;
    background-color: #7267EF; }
  div.dt-button-info > div {
    padding: 1em; }

.dt-buttons {
  margin-bottom: 5px; }

table.dataTable tbody td.focus,
table.dataTable tbody th.focus {
  box-shadow: inset 0 0 1px 2px #7267EF; }

div.dtk-focus-alt table.dataTable tbody td.focus,
div.dtk-focus-alt table.dataTable tbody th.focus {
  box-shadow: inset 0 0 1px 2px #ffa21d;
  background: rgba(255, 162, 29, 0.15);
  color: #ffa21d; }

/**====== Data Tables css end ======**/
.fixed-button {
  position: fixed;
  bottom: -50px;
  right: 30px;
  box-shadow: 0 15px 50px rgba(0, 0, 0, 0.35);
  opacity: 0;
  z-index: 9;
  transition: all 0.5s ease; }

.fixed-button.active {
  bottom: 50px;
  opacity: 1; }
