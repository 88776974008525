// ============================
//     Sidebar css start
// ============================

.pc-sidebar {
    background: $sidebar-background;
    box-shadow: $sidebar-shadow;
    width: $sidebar-width;
    position: fixed;
    top: 0;
    bottom: 0;
    z-index: 1026;

    ul {
        list-style: none;
        padding-left: 0;
        margin-bottom: 0;
    }

    .pc-caption {
        color: $sidebar-caption;
        // color: $primary;
        display: block;
        padding: 25px 30px 10px;
        letter-spacing: 0.07em;
        text-transform: uppercase;
        font: {
            size: 11px;
            weight: 600;
        }

        label {
            margin-bottom: 0;
        }

        span:not(.badge) {
            display: block;
            color: #67758A;
            font: {
                size: 10px;
                weight: 500;
            }
            text-transform: capitalize;
        }
    }

    .pc-micon {
        margin-right: 15px;

        i {
            font-size: 18px;
            color: $sidebar-icon-color;
        }

        > svg {
            width: 18px;
            height: 18px;
            color: $sidebar-icon-color;
            fill:rgba(114,103,239,0.2);
        }
    }

    .pc-link {
        display: block;
        padding: 14px 30px;
        color: $sidebar-color;
        font: {
            size: 13px;
            weight: 500;
        }

        &:active,
        &:focus,
        &:hover {
            text-decoration: none;
            color: $sidebar-active-color;

            .pc-micon {
                i,
                svg {
                    color: $sidebar-active-color;
                }
            }
        }
    }

    .pc-arrow {
        float: right;
        display: inline-block;
        transition: all 0.2s ease-in-out;

        > svg {
            width: 14px;
            height: 14px;
        }
    }

    .pc-badge {
        margin-left: 8px;
    }

    .m-header {
        height: $header-height;
        display: flex;
        align-items: center;
        padding: 15px 30px;
        background: $brand-color;

        .logo-sm {
            display: none;
        }
    }

    .navbar-content {
        position: relative;
        height: calc(100vh - #{$header-height});
    }

    .pc-submenu {
        display: none;

        .pc-link {
            padding: 10px 30px 10px 65px;
        }

        .pc-submenu {
            .pc-link {
                padding: 10px 30px 10px 85px;
            }
        }
    }

    .pc-item {
        &.active,
        &:focus,
        &:hover {
            > .pc-link {
                color: $sidebar-active-color;

                .pc-micon {
                    i,
                    svg {
                        color: $sidebar-active-color;
                    }
                }
            }
        }
    }

    .pc-navbar {
        >.pc-item {
            &.active,
            &:focus,
            &:hover {
                > .pc-link {
                    background: $brand-color;
                }
            }
        }
    }

    .pc-hasmenu {
        &.pc-trigger {
            > .pc-submenu {
                display: block;
            }

            > .pc-link {
                > .pc-arrow {
                    transform: rotate(90deg);
                }
            }
        }
    }
}

body:not(.minimenu) {
    .pc-sidebar {
        .pc-submenu {
            position: relative;

            &:after {
                // content: "";
                // width: 1px;
                // height: calc(100% - 18px);
                // background: #67758A;
                // position: absolute;
                // left: 38px;
                // top: 0;
                // opacity: 0.7;
            }

            .pc-item {
                position: relative;

                &:before {
                    content: "";
                    width: 6px;
                    height: 6px;
                    position: absolute;
                    left: 50px;
                    top: 16px;
                    border-radius: 50%;
                    z-index: 1;
                    opacity: 0.7;
                    // transition: all .2s ease-in-out;
                }
                &.active {
                    &:before {
                        background: $primary;
                    }
                }
                &:hover {
                    &:before {
                        background: $sidebar-color;
                    }
                }

                &:after {
                    // content: "";
                    // width: 18px;
                    // height: 1px;
                    // background: #67758A;
                    // position: absolute;
                    // left: 38px;
                    // top: 21px;
                    // opacity: 0.7;
                }
            }

            .pc-submenu {
                &:after {
                    // left: 65px;
                }

                > .pc-item {
                    &:before {
                        left: 70px;
                    }
                    &:after {
                        // left: 65px;
                    }
                }
            }
        }
    }
}

.pc-menu-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 1;
    background: rgba(0,0,0,0.2);
}

.minimenu {
    .pc-sidebar {
        width: $sidebar-collapsed-width;

        .m-header {
            padding: 15px 17px;

            .logo-sm {
                display: block;
            }

            .logo-lg {
                display: none;
            }
        }

        .pc-badge,
        .pc-caption,
        .pc-mtext,
        .pc-navbar > li > a > .pc-arrow {
            display: none;
        }

        .ps__rail-X,
        .ps__rail-y {
            display: none;
        }

        .ps {
            overflow: visible !important;
        }

        .pc-link {
            padding: 20px 25px;
        }

        .pc-submenu {
            background: $sidebar-background;
            position: absolute;
            left: 100%;
            padding: 8px 0;

            .pc-link {
                padding: 8px 30px;
            }
        }

        .pc-item {
            position: relative;

            &:hover {
                > .pc-link {
                    width: calc(200px + #{$sidebar-collapsed-width});
                    background: $brand-color;
                    color: $sidebar-active-color;

                    .pc-micon {
                        i {
                            color: #fff;
                        }
                        margin-right: 40px;
                    }

                    .pc-mtext {
                        display: inline-block;
                    }
                }

                > .pc-submenu {
                    display: block;
                    width: 200px;
                }
            }

            .pc-item {
                &:hover {
                    > .pc-link {
                        width: 200px;
                        background: $sidebar-background;
                    }

                    > .pc-submenu {
                        top: 0;
                    }
                }
            }
        }
    }
}
@media (min-width: 1025px) {
    .navbar-overlay {
        .pc-sidebar {
            &.pc-over-menu-active {
                .navbar-wrapper {
                    position: relative;
                    z-index: 5;
                }
            }

            &:not(.pc-over-menu-active) {
                left: -#{$sidebar-width};
                box-shadow: none;
            }
        }
    }
}
@media (max-width: 1024px) {
    .pc-sidebar {
        left: -#{$sidebar-width};
        box-shadow: none;
        transition: all 0.15s ease-in-out;

        &.mob-sidebar-active {
            left: 0;
            box-shadow: $sidebar-shadow;

            .navbar-wrapper {
                position: relative;
                z-index: 5;
                background: inherit;
            }
        }
    }

    .minimenu {
        .pc-sidebar.mob-sidebar-active {}

        .pc-container {
            margin-left: 0;
        }
    }
}
// Sidebar css end
