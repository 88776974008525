/**  =====================
      Generic-class css start
========================== **/
/*====== Padding , Margin css starts ======*/
$i: 0;
@while $i<=50 {
    .p {
        &-#{$i} {
            padding: #{$i}px;
        }

        &-t-#{$i} {
            padding-top: #{$i}px;
        }

        &-b-#{$i} {
            padding-bottom: #{$i}px;
        }

        &-l-#{$i}{
            padding-left: #{$i}px;
        }

        &-r-#{$i} {
            padding-right: #{$i}px;
        }
    }
    .m {
        &-#{$i} {
            margin: #{$i}px;
        }

        &-t-#{$i} {
            margin-top: #{$i}px;
        }

        &-b-#{$i} {
            margin-bottom: #{$i}px;
        }

        &-l-#{$i} {
            margin-left: #{$i}px;
        }

        &-r-#{$i} {
            margin-right: #{$i}px;
        }
    }
    $i: $i+5;
}
/*====== Padding , Margin css ends ======*/
/*====== Font-size css starts ======*/
$i: 10;
@while $i<=80 {
    .f-#{$i} {
        font-size: #{$i}px;
    }
    $i: $i+2;
}
/*====== Font-size css ends ======*/
/*====== Font-weight css starts ======*/
$i: 100;
@while $i<=900 {
    .f-w-#{$i} {
        font-weight: #{$i};
    }
    $i: $i+100;
}
/*====== Font-weight css ends ======*/
/*====== width, Height css starts ======*/
$i: 10;
@while $i<=150 {
    .wid-#{$i} {
        width: #{$i}px;
    }
    .hei-#{$i} {
        height: #{$i}px;
    }
    $i: $i+5;
}
/*====== width, Height css ends ======*/
/*====== border-width css starts ======*/
$i: 1;
@while $i<=8 {
    .b-wid-#{$i} {
        border-width:#{$i}px;
    }
    $i: $i+1;
}
/*====== border-width css ends ======*/
/*====== background starts ======*/
.bg-body{
    background: $body-bg;
}
@each $color, $value in $theme-colors {
    .bg-light-#{$color} {
        background: theme-color-level($color, $soft-bg-level);
    }
    .icon-svg-#{$color} {
        fill: theme-color-level($color, $soft-bg-level);
        stroke:$value;
    }
}

/*====== background ends ======*/
/*====== border color css starts ======*/
@each $color, $value in $theme-colors {
    .b-#{$color} {
        border: 1px solid $value;
    }
    .border-bottom-#{$color} td{
        border-bottom: 1px solid $value;
    }
    .border-bottom-#{$color} th{
        border-bottom: 1px solid $value !important;
    }
    .fill-#{$color}{
        fill:$value;
    }
}
/*====== border color css ends ======*/
/*====== text-color, background color css starts ======*/
// social color variables
$social-name: facebook,twitter,dribbble,pinterest,youtube,googleplus,instagram,viber,behance,dropbox,linkedin,amazon;
$social-color: #3C5A99,#42C0FB,#EC4A89,#BF2131,#E0291D,#C73E2E,#AA7C62,#7B519D,#0057ff,#3380FF,#0077B5,#000;
@each $value in $social-name {
    $i: index($social-name, $value);
    .bg-#{$value} {
        background: nth($social-color,$i);
    }
    .text-#{$value} {
        color: nth($social-color,$i);
    }
}
/*====== text-color css ends ======*/
.font-hairline {
    font-weight: 100 !important;
}
.font-thin {
    font-weight: 200 !important;
}
.font-light {
    font-weight: 300 !important;
}
.font-normal {
    font-weight: 400 !important;
}
.font-medium {
    font-weight: 500 !important;
}
.font-semibold {
    font-weight: 600 !important;
}
.font-bold {
    font-weight: 700 !important;
}
.font-extrabold {
    font-weight: 800 !important;
}
.font-black {
    font-weight: 900 !important;
}
.text-xs {
    font-size: 0.625rem !important;
}
.text-sm {
    font-size: 0.75rem !important;
}
.text-md {
    font-size: 0.8125rem !important;
}
.text-base {
    font-size: 0.875rem !important;
}
.text-lg {
    font-size: 1rem !important;
}
.text-xl {
    font-size: 1.125rem !important;
}
.text-2xl {
    font-size: 1.25rem !important;
}
.text-3xl {
    font-size: 1.5rem !important;
}
.text-4xl {
    font-size: 2rem !important;
}
.text-5xl {
    font-size: 2.25rem !important;
}
.text-6xl {
    font-size: 2.5rem !important;
}
.text-7xl {
    font-size: 3rem !important;
}
.text-8xl {
    font-size: 4rem !important;
}
.text-9xl {
    font-size: 6rem !important;
}
.text-10xl {
    font-size: 8rem !important;
}
.uppercase {
    text-transform: uppercase !important;
}
.lowercase {
    text-transform: lowercase !important;
}
.capitalize {
    text-transform: capitalize !important;
}
.normal-case {
    text-transform: none !important;
}
